import {Customer} from "../../api";
import {ADVERTISING_CAMPAIGN_FETCH_FAILURE, ADVERTISING_CAMPAIGN_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/advertising_campaign";

const SuccessAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_FETCH_SUCCESS,
  payload: normalize([payload], [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_FETCH_FAILURE,
  payload
});

export default (owner, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Customer.AdFetch(owner, id)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};