import {requestJSON} from "../utils/request";

const api_host = window._env_.API_HOST;

class Vpn {

  static ServerCreate(data) {
    let uri = [api_host, "vpn"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static ServerFetch(vpn) {
    let uri = [api_host, "vpn", vpn].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ServerList() {
    let uri = [api_host, "vpn"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ServerUpdate(vpn, data) {
    let uri = [api_host, "vpn", vpn].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static ServerRemove(vpn) {
    let uri = [api_host, "vpn", vpn].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

}

export default Vpn
