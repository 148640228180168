import {Partner} from "../../api";
import {MEDIA_FILE_UPDATE_FAILURE, MEDIA_FILE_UPDATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import mediaFilesSchema from "../../schemas/media";

const SuccessAction = (payload) => ({
  type: MEDIA_FILE_UPDATE_SUCCESS,
  payload: normalize([payload], [mediaFilesSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_FILE_UPDATE_FAILURE,
  payload
});


export default (owner, media, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.MediaUpdate(owner, media, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};