import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Device {

  static FileList(opts) {
    let uri = [api_host, "media", "file"].join("/");
    opts = opts || {};
    let qs = serialize(opts);
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static FileRemove(file) {
    let uri = [api_host, "media", "file", file].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static FileContentUpload() {
    return [api_host, "media", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

  static FileDefaultContentUploadURI() {
    return [api_host, "media", "default", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

  static FileDefaultPreloaderContentUploadURI() {
    return [api_host, "media", "preloader", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

  static FileDefaultRemove() {
    let uri = [api_host, "media", "default"].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static FilePreloaderRemove() {
    let uri = [api_host, "media", "preloader"].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static DocumentFileUploadURI() {
    return [api_host, "media", "document", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

}

export default Device
