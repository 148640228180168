import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getMediaFiles} from "../selectors";
import {Button} from "../containers";

import "antd/dist/antd.min.css";

class DeviceModemInfo extends React.Component {

  constructor(props) {
    super(props);
    const data = props.device || {};
    this.state = {
      data: {
        modems: data.spec.modems || [
          {provider: "", phone: "", sim_card_number: ""},
          {provider: "", phone: "", sim_card_number: ""},
        ],
      },
      error: {},
      pending: false,
    };
  }

  handleChange = (e, index) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "provider":
      case "phone":
      case "sim_card_number":
        let modems = this.state.data.modems;
        modems[index][name] = value
        patch = {data: {...this.state.data, modems: modems}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad device_update_interval parameter") e.name = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        {
          this.state.data.modems.map((item, index) => {
            return (
              <div key={index} className="form-group">
                <label
                  htmlFor="application_version">{I18n.t('key_DeviceModemInfo_ModemLabel', {text: index + 1})}</label>
                <div className="input-group input-group-sm">
                  <select className="form-control form-control-sm" name="provider"
                          style={{maxWidth: "100px"}}
                          value={item.provider}
                          onChange={e => this.handleChange(e, index)}>
                    <optgroup label={I18n.t('key_DeviceModemInfo_MobileProviderOptionGroup')}>
                      <option value="" disabled={true}>Provider</option>
                      <option value="megafon">{I18n.t('key_DeviceModemInfo_MegafonMobileProviderOption')}</option>
                      <option value="mts">{I18n.t('key_DeviceModemInfo_MtsSimSerialPlaceholder')}</option>
                      <option value="beeline">{I18n.t('key_DeviceModemInfo_BeelineSimSerialPlaceholder')}</option>
                      <option value="tele2">{I18n.t('key_DeviceModemInfo_Tele2SimSerialPlaceholder')}</option>
                    </optgroup>
                  </select>
                  <input type="text" className="form-control" name="phone"
                         placeholder={I18n.t('key_DeviceModemInfo_PhonePlaceholder')}
                         style={{maxWidth: "150px"}}
                         value={item.phone}
                         onChange={e => this.handleChange(e, index)}/>
                  <input type="text" className="form-control" name="sim_card_number"
                         placeholder={I18n.t('key_DeviceModemInfo_SimSerialPlaceholder')}
                         value={item.sim_card_number}
                         onChange={e => this.handleChange(e, index)}/>
                </div>

              </div>
            )
          })
        }

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Update')}
          />
        </div>
      </div>
    )
  }
}

DeviceModemInfo.propTypes = {
  name: PropTypes.string,
  device: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  files: getMediaFiles(state),
});

export default withRouter(connect(mapStateToProps)(DeviceModemInfo));
