import {Partner} from "../../api";
import {MEDIA_PLAN_REMOVE_FAILURE, MEDIA_PLAN_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: MEDIA_PLAN_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: MEDIA_PLAN_REMOVE_FAILURE,
  payload
});

export default (owner, item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.MpRemove(owner, item.id)
      .then(() => {
        dispatch(SuccessAction(item));
        resolve(item)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};