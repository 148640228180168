import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";

const DeviceCardList = ({devices, onSelect}) => {

  window.$(() => window.$('[data-toggle="tooltip"]').tooltip());

  return (
    <table className="table table-hover">
      <thead>
      <tr>
        <th scope="col" style={{width: "50px"}}>{I18n.t('key_DeviceCardList_TableHeaderStatus')}</th>
        <th scope="col" style={{width: "200px"}}>{I18n.t('key_DeviceCardList_TableHeaderName')}</th>
        <th scope="col" style={{minWidth: "200px"}}>{I18n.t('key_DeviceCardList_TableHeaderPartnerAndAddress')}</th>
        <th scope="col" style={{width: "150px"}}>{I18n.t('key_DeviceCardList_TableHeaderSystemStatus')}</th>
        <th scope="col" style={{width: "90px"}}>{I18n.t('key_DeviceCardList_TableHeaderVersion')}</th>
        <th scope="col" style={{width: "50px"}}>{I18n.t('key_DeviceCardList_TableHeaderCpuTemperature')}</th>
        <th scope="col" style={{width: "100px"}}
            className="text-center">{I18n.t('key_DeviceCardList_TableHeaderVpnIP')}</th>
      </tr>
      </thead>
      <tbody>

      {
        devices.sortDateDesc().map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index} onClick={() => onSelect(item)}>
              <td style={{verticalAlign: "middle"}}>
                  <span className={`badge text-uppercase ${!!item.status.online ? "badge-success" : "badge-danger"}`}>
                    {item.status.online ? I18n.t('key_Online') : I18n.t('key_Offline')}
                  </span>
              </td>
              <td className="text-truncate align-middle">
                <div>{item.meta.name}</div>
                <div>
                  <small><span className="text-muted">serial:</span> {item.meta.serial}</small>
                </div>
              </td>
              <td className="align-middle" style={{city: "middle"}}>
                <div>{item.meta.owner}</div>
                <div><small>{item.spec.address}</small></div>
              </td>
              <td className="align-middle text-center" style={{verticalAlign: "middle"}}>
                {
                  (item.status.online)
                    ? (
                      <div className="d-flex">
                        <div className="p-1" data-toggle="tooltip" data-placement="right" title="Chromium">
                          <i className={`fab fa-chrome ${item.status.browser_active ? "text-success" : "text-muted"}`}/>
                        </div>
                        <div className="p-1" data-toggle="tooltip" data-placement="right" title="Websocket">
                          <i
                            className={`fas fa-retweet ${item.status.websocket_active ? "text-success" : "text-muted"}`}/>
                        </div>
                        <div className="p-1" data-toggle="tooltip" data-placement="right" title="TV online">
                          <i className={`fas fa-plug ${item.status.tv_online ? "text-success" : "text-muted"}`}/>
                        </div>
                        <div className="p-1" data-toggle="tooltip" data-placement="right" title="Screen on">
                          <i className={`fas fa-tv ${item.status.tv_screen_on ? "text-success" : "text-muted"}`}/>
                        </div>
                        <div className="p-1" data-toggle="tooltip" data-placement="right" title="HDMI active">
                          <i className={`far fa-dot-circle ${item.status.tv_hdmi_active ? "text-success" : "text-muted"}`}/>
                        </div>
                      </div>
                    )
                    : (
                      <small><i className="fas fa-minus"/></small>
                    )
                }
              </td>
              <td className="align-middle" style={{verticalAlign: "middle"}}>
                <div className="d-flex">
                  <div className="mr-1" style={{minWidth: "10px"}}>a:</div>
                  <div>
                    {
                      (!!item.status.application_version)
                        ? <small className="badge badge-dark">{item.status.application_version}</small>
                        : <small><i className="fas fa-minus"/></small>
                    }
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mr-1" style={{minWidth: "10px"}}>p:</div>
                  <div>
                    {
                      (!!item.status.player_version)
                        ? <small className="badge badge-dark">{item.status.player_version}</small>
                        : <small><i className="fas fa-minus"/></small>
                    }
                  </div>
                </div>
              </td>
              <td className="align-middle text-center" style={{verticalAlign: "middle"}}>
                {
                  (!!item.status.temperature)
                    ? <div>{item.status.temperature}</div>
                    : <small><i className="fas fa-minus"/></small>
                }
              </td>
              <td className="align-middle text-center" style={{verticalAlign: "middle"}}>
                {
                  (!!item.status.vpn_ip)
                    ? <small className="badge badge-dark">{item.status.vpn_ip}</small>
                    : <small><i className="fas fa-minus"/></small>
                }
              </td>
            </tr>
          )
        })
      }

      </tbody>
    </table>
  )
}

DeviceCardList.defaultProps = {
  devices: {},
};

DeviceCardList.propTypes = {
  devices: PropTypes.object,
  onSelect: PropTypes.func,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(DeviceCardList);
