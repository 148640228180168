import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class SystemTrigger {

  static List(opts) {
    let uri = [api_host, "trigger"].join("/");
    opts = opts || {};
    let qs = serialize(opts);
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Update(data) {
    let uri = [api_host, "trigger"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

}

export default SystemTrigger
