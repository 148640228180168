import {download, requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Device {

  static Create(data) {
    let uri = [api_host, "device"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static Fetch(device) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static List(opts) {
    let uri = [api_host, "device"].join("/");
    opts = opts || {};
    let qs = serialize({
      filter: opts.filter || "",
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static UnregisteredList() {
    let uri = [api_host, "device", "unregistered"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static Update(device, data) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static Remove(device) {
    let uri = [api_host, "device", device].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static ScheduleFetch(device) {
    let uri = [api_host, "device", device, "schedule"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ScheduleUpdate(device, data) {
    let uri = [api_host, "device", device, "schedule"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static EventList(device, opts) {
    let uri = [api_host, "device", device, "events"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
      type: opts.type || "",
      start_date: opts.start_date || 0,
      end_date: opts.end_date || 0,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static EventsExcelExport(device, opts) {
    let uri = [api_host, "device", device, "events"].join("/");
    opts = opts || {};
    let qs = serialize({
      all: opts.all || false,
      type: opts.type || "",
      start_date: opts.start_date || 0,
      end_date: opts.end_date || 0,
    });
    uri += !!qs.length ? "?" + qs : "";

    let headers = {}
    headers["Content-Type"] = "application/vnd.ms-excel";

    return download(uri, device + ".xlsx", true, headers);
  }

  static FileList(device, opts) {
    let uri = [api_host, "device", device, "media"].join("/");
    opts = opts || {
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 5,
      all: opts.all || false,
    };
    let qs = serialize(opts);
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static FileRemove(device, file) {
    let uri = [api_host, "device", device, "media", file].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static FileContentUpload(device) {
    return [api_host, "device", device, "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token");
  }

}

export default Device
