import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getDeviceByID} from "../../selectors";
import {
  DeviceFormRemove,
  DeviceFormTvControlUpdate,
  DeviceGeneralSettings,
  DeviceMediaSettings,
  DeviceModemInfo,
} from "../../containers";
import {Device} from "../../actions";
import {I18n} from "../../utils/i18n";

class DevicePartialSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleTvControlUpdate = (data) => {
    const {device, dispatch} = this.props;
    return dispatch(Device.Update(device, {tv: data}))
  }

  handleSettingsUpdate = (data) => {
    const {device, dispatch} = this.props;
    return dispatch(Device.Update(device, data))
  }

  handleDeviceRemove = () => {
    const {device, dispatch} = this.props;
    return dispatch(Device.Remove(device))
      .then(() => this.props.history.push(`/d`))
  }

  render() {
    const {device} = this.props;

    return (
      <div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_GeneralSettingsHeader')}</h4>
                <div className="mt-4">
                  <DeviceGeneralSettings device={device} submit={this.handleSettingsUpdate}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_ModemInfoHeader')}</h4>
                <div className="mt-4">
                  <DeviceModemInfo device={device} submit={this.handleSettingsUpdate}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_MediaSettingsHeader')}</h4>
                <div className="mt-4">
                  <DeviceMediaSettings device={device}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_TvControlLabel')}</h4>
                <div className="mt-4">
                  <DeviceFormTvControlUpdate device={device} submit={this.handleTvControlUpdate}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="text-success">{I18n.t('key_DevicePartialSettings_DeleteDevice')}</h4>
                <span
                  className="text-warning">{I18n.t('key_DevicePartialSettings_ThisActionCannotBeUndone', {name: device.meta.serial})}</span>
                <div className="mt-4">
                  <DeviceFormRemove device={device} submit={this.handleDeviceRemove}/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.match.params.device),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialSettings));

