import {Account} from '../../../api';
import {ACCOUNT_PASSWORD_UPDATE_FAILURE, ACCOUNT_PASSWORD_UPDATE_SUCCESS} from '../../../constants';

const SuccessAction = ({
  type: ACCOUNT_PASSWORD_UPDATE_SUCCESS,
});

const FailureAction = (payload) => ({
  type: ACCOUNT_PASSWORD_UPDATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.PasswordUpdate({validate: data.current, password: data.password})
      .then(response => {
        dispatch(SuccessAction);
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
