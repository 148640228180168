import React from "react"
import {connect} from "react-redux"
import PropTypes from 'prop-types'

class Button extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: false
    }
  }

  handleDisable = () => {
    return this.state.pending || this.props.disabled
  };

  handleSubmit = (e) => {
    this.setState({pending: true});
    return Promise.resolve(this.props.submit(e))
      .then(() => this.setState({pending: false}))
      .catch(() => this.setState({pending: false}))
  };

  render() {
    return (
      <button className={this.props.className}
              onClick={this.props.submit && this.handleSubmit}
              disabled={this.handleDisable()}
              style={this.props.style}
              type={this.props.type}>

        {
          <div className='position-relative'>
            <span style={{opacity: this.state.pending ? 0 : 1}}>{this.props.main}</span>
            <i className='fas fa-spinner fa-spin position-absolute'
               style={{top: '30%', left: '45%', opacity: this.state.pending ? 1 : 0}}
            />
          </div>
        }

      </button>
    );
  }
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  submit: PropTypes.func,
  style: PropTypes.object,
  main: PropTypes.node.isRequired,
  disabled: PropTypes.bool
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(Button);
