import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import querystring from "query-string";
import isRetina from "is-retina";


const Gravatar = ({hash, email, size, alt, className, imageClassName, rating, text}) => {

  const base = '//www.gravatar.com/avatar/';
  const query = querystring.stringify({
    s: size || 50,
    r: rating || "g",
    d: "retro",
  });
  const retinaQuery = querystring.stringify({
    s: size * 2,
    r: rating || "g",
    d: "retro",
  });

  if (!hash) {
    const formattedEmail = ('' + email).trim().toLowerCase();
    hash = md5(formattedEmail);
  }

  const src = `${base}${hash}?${query}`;
  const retinaSrc = `${base}${hash}?${retinaQuery}`;

  return (
    <span className={className}>
      <img alt={alt} className={imageClassName || "avatar"} width={size} height={size}
           src={(isRetina) ? src : retinaSrc}/>
    </span>
  );
};

Gravatar.propTypes = {
  hash: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  email: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  rating: PropTypes.string
};

export default Gravatar
