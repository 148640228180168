import React from "react";
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";

class DeviceFormTimeZoneUpdate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        timezone: ""
      },
      error: {},
      pending: false,
      success: false
    }
  }

  handleTypeChange = (e) => {
    const target = e.target;
    this.setState(state => {
      state.timezone = target.value;
      return state
    })
  }

  //
  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   return Promise.resolve(this.props.submit(this.props.device))
  //     .then(this.handleSuccess, this.handleError);
  // };
  //
  // handleSuccess = () => {
  // };
  //
  // handleError = (errors) => {
  //   let e = this.state.error;
  //   e.form = errors.message;
  //   this.setState({error: e, pending: false});
  // };
  //
  // handleDisabled = () => {
  //   return this.state.pending || (this.state.data.name !== this.props.device.meta.serial);
  // };

  render() {
    return (
      <form>
        <div className="input-group">
          <select className="custom-select"
                  style={{maxWidth: "250px"}}
                  id="timezone"
                  name="timezone"
                  disabled={true}
                  onChange={this.handleTypeChange}
                  value={this.state.timezone}>
            <option value="">Europe/Moscow</option>
          </select>
          <div className="input-group-append">
            <button type="button"
                    disabled
                    className="btn btn-primary"
                    onClick={this.handleFilterApply}>{I18n.t('key_Apply')}</button>
          </div>
        </div>
      </form>
    )
  }
}

DeviceFormTimeZoneUpdate.propTypes = {
  error: PropTypes.object,
  device: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default DeviceFormTimeZoneUpdate;
