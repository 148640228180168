import React from "react";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {I18n} from "../utils/i18n";

class TagSelectableCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSuccess = () => {
    this.setState({data: {...this.state.data, name: ""}, pending: false})
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.message === "Bad name parameter") e.name = I18n.t('key_errorMsg_BadNameParameter');
    if (error.message === "Name is already in use") e.name = I18n.t('key_errorMsg_NotUniqueNameParameter');
    this.setState({error: Object.assign({}, this.state.error, e), pending: false});
  };

  handleChange = (e, index) => {
    e.stopPropagation();
    const item = this.props.list[index];
    item.selected = !item.selected;
    this.props.onSelect(item)
  };

  render() {
    const {list} = this.props;

    return (
      <div className="tags-list__table">

        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col" style={{minWidth: "10px"}}/>
            <th scope="col" style={{width: "100%"}}>{I18n.t('key_TagSelectableCardList_TableHeaderName')}</th>
            <th scope="col"
                style={{minWidth: "125px"}}>{I18n.t('key_TagSelectableCardList_TableHeaderCreated')}</th>
          </tr>
          </thead>
          <tbody>
          {
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{verticalAlign: "middle"}}>
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id={`item-${item.parentId}-${index}`}
                             checked={item.selected}
                             onChange={e => this.handleChange(e, index)}/>
                      <label className="custom-control-label" htmlFor={`item-${item.parentId}-${index}`}/>
                    </div>
                  </td>
                  <td style={{verticalAlign: "middle"}}>{item.name}</td>
                  <td style={{verticalAlign: "middle"}}>
                    {new Date(item.created).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })}
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
}

TagSelectableCardList.defaultProps = {
  list: [],
};

TagSelectableCardList.propTypes = {
  list: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(TagSelectableCardList));
