// ==============================================================================================
// SESSION ======================================================================================
// ==============================================================================================
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_FAILURE = 'SESSION_CREATE_FAILURE';

export const SESSION_SET_SUCCESS = 'SESSION_SET_SUCCESS';

export const SESSION_REMOVE_SUCCESS = 'SESSION_REMOVE_SUCCESS';

// ==============================================================================================
// ACCOUNT ======================================================================================
// ==============================================================================================

export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';

export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_FAILURE = 'ACCOUNT_FETCH_FAILURE';

export const ACCOUNT_LIST_FETCH_SUCCESS = 'ACCOUNT_LIST_FETCH_SUCCESS';
export const ACCOUNT_LIST_FETCH_FAILURE = 'ACCOUNT_LIST_FETCH_FAILURE';

export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export const ACCOUNT_CURRENT_SET_SUCCESS = 'ACCOUNT_CURRENT_SET_SUCCESS';

export const ACCOUNT_PROFILE_FETCH_SUCCESS = 'ACCOUNT_PROFILE_FETCH_SUCCESS';
export const ACCOUNT_PROFILE_FETCH_FAILURE = 'ACCOUNT_PROFILE_FETCH_FAILURE';

export const ACCOUNT_PROFILE_UPDATE_SUCCESS = 'ACCOUNT_PROFILE_UPDATE_SUCCESS';
export const ACCOUNT_PROFILE_UPDATE_FAILURE = 'ACCOUNT_PROFILE_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_UPDATE_SUCCESS = 'ACCOUNT_PASSWORD_UPDATE_SUCCESS';
export const ACCOUNT_PASSWORD_UPDATE_FAILURE = 'ACCOUNT_PASSWORD_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_RECOVERY_SUCCESS = 'ACCOUNT_PASSWORD_RECOVERY_SUCCESS';
export const ACCOUNT_PASSWORD_RECOVERY_FAILURE = 'ACCOUNT_PASSWORD_RECOVERY_FAILURE';

export const ACCOUNT_PASSWORD_CHANGE_SUCCESS = 'ACCOUNT_PASSWORD_CHANGE_SUCCESS';
export const ACCOUNT_PASSWORD_CHANGE_FAILURE = 'ACCOUNT_PASSWORD_CHANGE_FAILURE';

// ==============================================================================================
// TAGS =========================================================================================
// ==============================================================================================

export const TAG_LIST_FETCH_SUCCESS = 'TAG_LIST_FETCH_SUCCESS';
export const TAG_LIST_FETCH_FAILURE = 'TAG_LIST_FETCH_FAILURE';

export const TAG_FETCH_SUCCESS = 'TAG_FETCH_SUCCESS';
export const TAG_FETCH_FAILURE = 'TAG_FETCH_FAILURE';
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS';
export const TAG_UPDATE_FAILURE = 'TAG_UPDATE_FAILURE';
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS';
export const TAG_CREATE_FAILURE = 'TAG_CREATE_FAILURE';
export const TAG_REMOVE_SUCCESS = 'TAG_REMOVE_SUCCESS';
export const TAG_REMOVE_FAILURE = 'TAG_REMOVE_FAILURE';

// ==============================================================================================
// VPN ==========================================================================================
// ==============================================================================================

export const VPN_SERVER_LIST_FETCH_SUCCESS = 'VPN_SERVER_LIST_FETCH_SUCCESS';
export const VPN_SERVER_LIST_FETCH_FAILURE = 'VPN_SERVER_LIST_FETCH_FAILURE';

export const VPN_SERVER_FETCH_SUCCESS = 'VPN_SERVER_FETCH_SUCCESS';
export const VPN_SERVER_FETCH_FAILURE = 'VPN_SERVER_FETCH_FAILURE';
export const VPN_SERVER_UPDATE_SUCCESS = 'VPN_SERVER_UPDATE_SUCCESS';
export const VPN_SERVER_UPDATE_FAILURE = 'VPN_SERVER_UPDATE_FAILURE';
export const VPN_SERVER_CREATE_SUCCESS = 'VPN_SERVER_CREATE_SUCCESS';
export const VPN_SERVER_CREATE_FAILURE = 'VPN_SERVER_CREATE_FAILURE';
export const VPN_SERVER_REMOVE_SUCCESS = 'VPN_SERVER_REMOVE_SUCCESS';
export const VPN_SERVER_REMOVE_FAILURE = 'VPN_SERVER_REMOVE_FAILURE';

// ==============================================================================================
// FFMPEG_COMMAND ==========================================================================================
// ==============================================================================================

export const FFMPEG_COMMAND_LIST_FETCH_SUCCESS = 'FFMPEG_COMMAND_LIST_FETCH_SUCCESS';
export const FFMPEG_COMMAND_LIST_FETCH_FAILURE = 'FFMPEG_COMMAND_LIST_FETCH_FAILURE';

export const FFMPEG_COMMAND_FETCH_SUCCESS = 'FFMPEG_COMMAND_FETCH_SUCCESS';
export const FFMPEG_COMMAND_FETCH_FAILURE = 'FFMPEG_COMMAND_FETCH_FAILURE';
export const FFMPEG_COMMAND_UPDATE_SUCCESS = 'FFMPEG_COMMAND_UPDATE_SUCCESS';
export const FFMPEG_COMMAND_UPDATE_FAILURE = 'FFMPEG_COMMAND_UPDATE_FAILURE';
export const FFMPEG_COMMAND_CREATE_SUCCESS = 'FFMPEG_COMMAND_CREATE_SUCCESS';
export const FFMPEG_COMMAND_CREATE_FAILURE = 'FFMPEG_COMMAND_CREATE_FAILURE';
export const FFMPEG_COMMAND_REMOVE_SUCCESS = 'FFMPEG_COMMAND_REMOVE_SUCCESS';
export const FFMPEG_COMMAND_REMOVE_FAILURE = 'FFMPEG_COMMAND_REMOVE_FAILURE';

// ==============================================================================================
// MEDIA FILE ===================================================================================
// ==============================================================================================
export const MEDIA_FILE_LIST_FETCH_SUCCESS = 'MEDIA_FILE_LIST_FETCH_SUCCESS';
export const MEDIA_FILE_LIST_FETCH_FAILURE = 'MEDIA_FILE_LIST_FETCH_FAILURE';

export const MEDIA_FILE_CREATE_SUCCESS = 'MEDIA_FILE_CREATE_SUCCESS';
export const MEDIA_FILE_CREATE_FAILURE = 'MEDIA_FILE_CREATE_FAILURE';
export const MEDIA_FILE_FETCH_SUCCESS = 'MEDIA_FILE_FETCH_SUCCESS';
export const MEDIA_FILE_FETCH_FAILURE = 'MEDIA_FILE_FETCH_FAILURE';
export const MEDIA_FILE_UPDATE_SUCCESS = 'MEDIA_FILE_UPDATE_SUCCESS';
export const MEDIA_FILE_UPDATE_FAILURE = 'MEDIA_FILE_UPDATE_FAILURE';
export const MEDIA_FILE_REMOVE_SUCCESS = 'MEDIA_FILE_REMOVE_SUCCESS';
export const MEDIA_FILE_REMOVE_FAILURE = 'MEDIA_FILE_REMOVE_FAILURE';

// ==============================================================================================
// DEVICE =======================================================================================
// ==============================================================================================
export const DEVICE_LIST_FETCH_SUCCESS = 'DEVICE_LIST_FETCH_SUCCESS';
export const DEVICE_LIST_FETCH_FAILURE = 'DEVICE_LIST_FETCH_FAILURE';

export const DEVICE_FETCH_SUCCESS = 'DEVICE_FETCH_SUCCESS';
export const DEVICE_FETCH_FAILURE = 'DEVICE_FETCH_FAILURE';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const DEVICE_UPDATE_FAILURE = 'DEVICE_UPDATE_FAILURE';
export const DEVICE_CREATE_SUCCESS = 'DEVICE_CREATE_SUCCESS';
export const DEVICE_CREATE_FAILURE = 'DEVICE_CREATE_FAILURE';
export const DEVICE_REMOVE_SUCCESS = 'DEVICE_REMOVE_SUCCESS';
export const DEVICE_REMOVE_FAILURE = 'DEVICE_REMOVE_FAILURE';

// ==============================================================================================
// CUSTOMER =======================================================================================
// ==============================================================================================
export const CUSTOMER_LIST_FETCH_SUCCESS = 'CUSTOMER_LIST_FETCH_SUCCESS';
export const CUSTOMER_LIST_FETCH_FAILURE = 'CUSTOMER_LIST_FETCH_FAILURE';

export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_FAILURE = 'CUSTOMER_FETCH_FAILURE';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE';
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';
export const CUSTOMER_CREATE_FAILURE = 'CUSTOMER_CREATE_FAILURE';
export const CUSTOMER_REMOVE_SUCCESS = 'CUSTOMER_REMOVE_SUCCESS';
export const CUSTOMER_REMOVE_FAILURE = 'CUSTOMER_REMOVE_FAILURE';
export const CUSTOMER_PASSWORD_CHANGE_SUCCESS = 'CUSTOMER_PASSWORD_CHANGE_SUCCESS';
export const CUSTOMER_PASSWORD_CHANGE_FAILURE = 'CUSTOMER_PASSWORD_CHANGE_FAILURE';


// ==============================================================================================
// PARTNER =======================================================================================
// ==============================================================================================
export const PARTNER_LIST_FETCH_SUCCESS = 'PARTNER_LIST_FETCH_SUCCESS';
export const PARTNER_LIST_FETCH_FAILURE = 'PARTNER_LIST_FETCH_FAILURE';

export const PARTNER_FETCH_SUCCESS = 'PARTNER_FETCH_SUCCESS';
export const PARTNER_FETCH_FAILURE = 'PARTNER_FETCH_FAILURE';
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';
export const PARTNER_UPDATE_FAILURE = 'PARTNER_UPDATE_FAILURE';
export const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS';
export const PARTNER_CREATE_FAILURE = 'PARTNER_CREATE_FAILURE';
export const PARTNER_REMOVE_SUCCESS = 'PARTNER_REMOVE_SUCCESS';
export const PARTNER_REMOVE_FAILURE = 'PARTNER_REMOVE_FAILURE';
export const PARTNER_PASSWORD_CHANGE_SUCCESS = 'PARTNER_PASSWORD_CHANGE_SUCCESS';
export const PARTNER_PASSWORD_CHANGE_FAILURE = 'PARTNER_PASSWORD_CHANGE_FAILURE';

// ==============================================================================================
// ADVERTISING CAMPAIGN =========================================================================
// ==============================================================================================

export const ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS = 'ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS';
export const ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE = 'ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE';

export const ADVERTISING_CAMPAIGN_FETCH_SUCCESS = 'ADVERTISING_CAMPAIGN_FETCH_SUCCESS';
export const ADVERTISING_CAMPAIGN_FETCH_FAILURE = 'ADVERTISING_CAMPAIGN_FETCH_FAILURE';
export const ADVERTISING_CAMPAIGN_UPDATE_SUCCESS = 'ADVERTISING_CAMPAIGN_UPDATE_SUCCESS';
export const ADVERTISING_CAMPAIGN_UPDATE_FAILURE = 'ADVERTISING_CAMPAIGN_UPDATE_FAILURE';
export const ADVERTISING_CAMPAIGN_CREATE_SUCCESS = 'ADVERTISING_CAMPAIGN_CREATE_SUCCESS';
export const ADVERTISING_CAMPAIGN_CREATE_FAILURE = 'ADVERTISING_CAMPAIGN_CREATE_FAILURE';
export const ADVERTISING_CAMPAIGN_REMOVE_SUCCESS = 'ADVERTISING_CAMPAIGN_REMOVE_SUCCESS';
export const ADVERTISING_CAMPAIGN_REMOVE_FAILURE = 'ADVERTISING_CAMPAIGN_REMOVE_FAILURE';

// ==============================================================================================
// MEDIA PLAN ===================================================================================
// ==============================================================================================
export const MEDIA_PLAN_LIST_FETCH_SUCCESS = 'MEDIA_PLAN_LIST_FETCH_SUCCESS';
export const MEDIA_PLAN_LIST_FETCH_FAILURE = 'MEDIA_PLAN_LIST_FETCH_FAILURE';

export const MEDIA_PLAN_FETCH_SUCCESS = 'MEDIA_PLAN_FETCH_SUCCESS';
export const MEDIA_PLAN_FETCH_FAILURE = 'MEDIA_PLAN_FETCH_FAILURE';
export const MEDIA_PLAN_UPDATE_SUCCESS = 'MEDIA_PLAN_UPDATE_SUCCESS';
export const MEDIA_PLAN_UPDATE_FAILURE = 'MEDIA_PLAN_UPDATE_FAILURE';
export const MEDIA_PLAN_CREATE_SUCCESS = 'MEDIA_PLAN_CREATE_SUCCESS';
export const MEDIA_PLAN_CREATE_FAILURE = 'MEDIA_PLAN_CREATE_FAILURE';
export const MEDIA_PLAN_REMOVE_SUCCESS = 'MEDIA_PLAN_REMOVE_SUCCESS';
export const MEDIA_PLAN_REMOVE_FAILURE = 'MEDIA_PLAN_REMOVE_FAILURE';

// ==============================================================================================
// SYSTEM_TRIGGERS ==============================================================================
// ==============================================================================================

export const SYSTEM_TRIGGER_LIST_FETCH_SUCCESS = 'SYSTEM_TRIGGER_LIST_FETCH_SUCCESS';
export const SYSTEM_TRIGGER_LIST_FETCH_FAILURE = 'SYSTEM_TRIGGER_LIST_FETCH_FAILURE';

export const SYSTEM_TRIGGER_UPDATE_SUCCESS = 'SYSTEM_TRIGGER_UPDATE_SUCCESS';
export const SYSTEM_TRIGGER_UPDATE_FAILURE = 'SYSTEM_TRIGGER_UPDATE_FAILURE';
