import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from "./reducer";

export function configureStore() {
  const devTools = (typeof window === 'object' && typeof window.devToolsExtension !== 'undefined') ?
    window.devToolsExtension() : f => f;

  const middleware = [
    thunk,
    ((window._env_.NODE_ENV || "").toLowerCase() === 'development') && logger
  ].filter(Boolean);

  const enhancer = compose(
    applyMiddleware(...middleware),
    devTools
  );

  return createStore(
    rootReducer,
    enhancer
  );
}