import {Partner} from "../../api";
import {MEDIA_PLAN_UPDATE_FAILURE, MEDIA_PLAN_UPDATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/mediaplan";

const SuccessAction = (payload) => ({
  type: MEDIA_PLAN_UPDATE_SUCCESS,
  payload: normalize([payload], [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_PLAN_UPDATE_FAILURE,
  payload
});

export default (owner, id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.MpUpdate(owner, id, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};