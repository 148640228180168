import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAccount} from "../selectors";
import {Button} from '../containers'
import Validator from "../utils/validator";
import {I18n} from "../utils/i18n";

class AccountFormSmtpServer extends React.Component {

  constructor(props) {
    super(props);
    const {data} = this.props;
    this.state = {
      error: Object.assign({}, props.error || {}),
      data: {
        host: data.host || "",
        login: data.login || "",
        password: '',
        sender_name: data.sender_name || '',
        sender_email: data.sender_email || '',
      },
      passwordPlaceholder: data.password ? "●●●●●●" : "",
      pending: false,
      success: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      error: Object.assign(this.state.error, {[name]: ""}),
      data: Object.assign(this.state.data, {[name]: value}),
      pending: false,
      success: false
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = this.state.data;

    let error = {};
    error.sender_email = Validator.EmailField(data.sender_email);
    error.sender_name = Validator.NotEmptyField(data.sender_name);
    error.login = Validator.NotEmptyField(data.login);
    error.password = Validator.NotEmptyField(data.password);
    error.host = Validator.NotEmptyField(data.host);

    for (let key in error) {
      if (error[key] !== null && error[key] !== "") {
        this.setState({error: Object.assign(this.state.error, error)});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true});
    setTimeout(() => this.setState({success: false}), 2000);
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad host parameter") e.host = I18n.t('key_errorMsg_BadHostParameter');
    if (errors.message === "Bad login parameter") e.login = I18n.t('key_errorMsg_BadLoginParameter');
    if (errors.message === "Bad password parameter") e.password = I18n.t('key_errorMsg_BadPasswordParameter');
    if (errors.message === "Bad sender_name parameter") e.sender_name = I18n.t('key_errorMsg_BadSenderNameParameter');
    if (errors.message === "Bad sender_email parameter") e.sender_email = I18n.t('key_errorMsg_BadSenderEmailParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (this.state.pending
      || this.state.data.host === ""
      || this.state.data.login === ""
      || this.state.data.password === ""
      || this.state.data.sender_name === ""
      || this.state.data.sender_email === ""
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h5 className="text-center text-danger">{this.state.error.form}</h5>
        </div>

        <div className="form-group">
          <label htmlFor="hostInput">{I18n.t('key_AccountFormSmtpServer_LabelHost')}</label>
          <input type='text'
                 id="hostInput"
                 name="host"
                 className={`form-control form-control-sm ${this.state.error.host ? 'is-invalid' : ''}`}
                 value={this.state.data.host}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.host}</div>
        </div>

        <div className="form-group">
          <label htmlFor="loginInput">{I18n.t('key_AccountFormSmtpServer_LabelLogin')}</label>
          <input type="text"
                 id="loginInput"
                 name="login"
                 className={`form-control form-control-sm ${this.state.error.login ? 'is-invalid' : ''}`}
                 value={this.state.data.login}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.login}</div>
        </div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormSmtpServer_LabelPassword')}</label>
          <input type="password"
                 id="passwordInput"
                 name="password"
                 className={`form-control form-control-sm ${this.state.error.password ? 'is-invalid' : ''}`}
                 value={this.state.data.password}
                 autoComplete="password"
                 placeholder={this.state.passwordPlaceholder}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.password}</div>
        </div>

        <div className="form-group">
          <label htmlFor="senderNameInput">{I18n.t('key_AccountFormSmtpServer_LabelSenderName')}</label>
          <input type="text"
                 id="senderNameInput"
                 name="sender_name"
                 className={`form-control form-control-sm ${this.state.error.sender_name ? 'is-invalid' : ''}`}
                 value={this.state.data.sender_name}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.sender_name}</div>
        </div>

        <div className="form-group">
          <label htmlFor="senderEmailInput">{I18n.t('key_AccountFormSmtpServer_LabelSenderEmail')}</label>
          <input type="email"
                 id="senderEmailInput"
                 name="sender_email"
                 className={`form-control form-control-sm ${this.state.error.sender_email ? 'is-invalid' : ''}`}
                 value={this.state.data.sender_email}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.sender_email}</div>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  disabled={this.handleDisabled()}
                  submit={this.handleSubmit}
                  main={I18n.t('key_Apply')}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  account: getAccount(state),
});

AccountFormSmtpServer.propTypes = {
  error: PropTypes.object,
  data: PropTypes.object,
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountFormSmtpServer);
