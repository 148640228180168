import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {Button, FileDataUploader} from "../containers";
import Validator from "../utils/validator";

class VpnServerFormCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        domain: "",
        hostname: "",
        public_ip: "",
        ssl: {
          ca_cert: "",
          ca_key: "",
          server_cert: "",
          server_key: "",
          ta_key: "",
        }
      },
      error: {
        domain: "",
        hostname: "",
        public_ip: "",
        ca_cert: "",
        ca_key: "",
        server_cert: "",
        server_key: "",
        ta_key: "",
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "domain":
      case "hostname":
      case "public_ip":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      case "ca_cert":
      case "ca_key":
      case "server_cert":
      case "server_key":
      case "ta_key":
        patch = {
          data: {...this.state.data, ssl: {...this.state.data.ssl, [name]: value}},
          error: {...this.state.error, [name]: ''}
        };
        break;
      default:
    }

    this.setState(patch)
  }

  handleBack = () => {
    this.props.cancel()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    let errors = {};
    errors.domain = Validator.NotEmptyField(data.domain);
    errors.hostname = Validator.NotEmptyField(data.hostname);
    errors.public_ip = Validator.NotEmptyField(data.public_ip);
    errors.ca_cert = Validator.NotEmptyField(data.ssl.ca_cert);
    errors.ca_key = Validator.NotEmptyField(data.ssl.ca_key);
    errors.server_cert = Validator.NotEmptyField(data.ssl.server_cert);
    errors.server_key = Validator.NotEmptyField(data.ssl.server_key);
    errors.ta_key = Validator.NotEmptyField(data.ssl.ta_key);

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = () => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad domain parameter") e.domain = I18n.t('key_errorMsg_BadDomainParameter');
    if (errors.message === "Bad hostname parameter") e.hostname = I18n.t('key_errorMsg_BadHostnameParameter');
    if (errors.message === "Bad public_ip parameter") e.public_ip = I18n.t('key_errorMsg_BadPublicIpParameter');
    if (errors.message === "Bad ca_cert parameter") e.public_ip = I18n.t('key_errorMsg_BadCaCertParameter');
    if (errors.message === "Bad ca_key parameter") e.public_ip = I18n.t('key_errorMsg_BadCaKeyParameter');
    if (errors.message === "Bad server_cert parameter") e.public_ip = I18n.t('key_errorMsg_BadServerCertParameter');
    if (errors.message === "Bad server_key parameter") e.public_ip = I18n.t('key_errorMsg_BadServerKeyParameter');
    if (errors.message === "Bad ta_key parameter") e.public_ip = I18n.t('key_errorMsg_BadTaKeyParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        <div className="form-group">
          <label htmlFor="hostname">{I18n.t('key_VpnServerFormCreate_HostnameLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.hostname ? 'is-invalid' : ''}`}
                 type="text" id="hostname" name="hostname"
                 onChange={this.handleChange}
                 value={this.state.data.hostname}/>
          <div className="invalid-feedback">{this.state.error.hostname}</div>
        </div>

        <div className="form-group">
          <label htmlFor="domain">{I18n.t('key_VpnServerFormCreate_DomainLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.domain ? 'is-invalid' : ''}`}
                 type="text" id="domain" name="domain"
                 onChange={this.handleChange}
                 value={this.state.data.domain}/>
          <div className="invalid-feedback">{this.state.error.domain}</div>
        </div>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_PublicIpLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.public_ip ? 'is-invalid' : ''}`}
                 type="text" id="public_ip" name="public_ip"
                 onChange={this.handleChange}
                 value={this.state.data.public_ip}/>
          <div className="invalid-feedback">{this.state.error.public_ip}</div>
        </div>


        <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_SSLLabel')}</label>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_CaCertLabel')}</label>
          <div className={`input-group ${this.state.error.ca_cert ? 'is-invalid' : ''}`}>
            <FileDataUploader name="ca_cert"
                              id="ca_cert"
                              accept=".crt"
                              onChange={this.handleChange}/>
          </div>
          <div className="invalid-feedback">{this.state.error.ca_cert}</div>
        </div>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_CaKeyLabel')}</label>
          <div className={`input-group ${this.state.error.ca_key ? 'is-invalid' : ''}`}>
            <FileDataUploader name="ca_key"
                              id="ca_key"
                              accept=".key"
                              onChange={this.handleChange}/>
          </div>
          <div className="invalid-feedback">{this.state.error.ca_key}</div>
        </div>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_ServerCertLabel')}</label>
          <div className={`input-group ${this.state.error.server_cert ? 'is-invalid' : ''}`}>
            <FileDataUploader name="server_cert"
                              id="server_cert"
                              accept=".crt"
                              onChange={this.handleChange}/>
          </div>
          <div className="invalid-feedback">{this.state.error.server_cert}</div>
        </div>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_ServerKeyLabel')}</label>
          <div className={`input-group ${this.state.error.server_key ? 'is-invalid' : ''}`}>
            <FileDataUploader name="server_key"
                              id="server_key"
                              accept=".key"
                              onChange={this.handleChange}/>
          </div>
          {!!this.props.error && <div className="invalid-feedback">{this.props.error.server_key}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="public_ip">{I18n.t('key_VpnServerFormCreate_TaKeyLabel')}</label>
          <div className={`input-group ${this.state.error.ta_key ? 'is-invalid' : ''}`}>
            <FileDataUploader name="ta_key"
                              id="ta_key"
                              accept=".key"
                              onChange={this.handleChange}/>
          </div>
          <div className="invalid-feedback">{this.state.error.ta_key}</div>
        </div>

        <div className="clearfix mt-5">
          <div className="float-left">
            <button type="button"
                    className='btn btn-secondary'
                    onClick={this.handleBack}>
              {I18n.t('key_Back')}
            </button>

          </div>
          <div className="float-right">
            <div className="form-group text-center">
              <Button className='btn btn-primary'
                      type="submit"
                      disabled={this.handleDisabled()}
                      submit={this.handleSubmit}
                      main={I18n.t('key_Send')}
              />
            </div>
          </div>
        </div>

      </div>
    )
  }
}

VpnServerFormCreate.propTypes = {
  name: PropTypes.string,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default VpnServerFormCreate;
