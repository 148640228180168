import {requestJSON} from "../utils/request";

const api_host = window._env_.API_HOST;

class Tag {

  static Fetch() {
    let uri = [api_host, "system"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static Update(data) {
    let uri = [api_host, "system"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

}

export default Tag