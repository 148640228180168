import {FFmpeg} from '../../api';
import {FFMPEG_COMMAND_REMOVE_FAILURE, FFMPEG_COMMAND_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: FFMPEG_COMMAND_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: FFMPEG_COMMAND_REMOVE_FAILURE,
  payload
});

export default (vpn) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FFmpeg.CommandRemove(vpn.id)
      .then(() => {
        dispatch(SuccessAction(vpn));
        resolve(vpn)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};
