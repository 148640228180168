import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

const PartnerCardList = ({partners}) => {
  return (
    <table className="table table-hover">
      <thead>
      <tr>
        <th scope="col" style={{width: "200px"}}>{I18n.t('key_PartnerCardList_TableHeaderName')}</th>
        <th scope="col"
            style={{textAlign: "center", minWidth: "50px"}}>{I18n.t('key_PartnerCardList_TableHeaderMediaCounts')}</th>
        <th scope="col"
            style={{textAlign: "center", minWidth: "50px"}}>{I18n.t('key_PartnerCardList_TableHeaderMPCounts')}</th>
      </tr>
      </thead>
      <tbody>

      {
        partners.sortDateDesc().map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="text-truncate align-middle">
                <NavLink to={`/p/${item.id}`}>{item.email}</NavLink>
              </td>
              <td align="center" className="align-middle">
                <NavLink to={`/p/${item.id}/media`}>{item.stats.medias_count || 0}</NavLink>
              </td>
              <td align="center" className="align-middle">
                <NavLink to={`/p/${item.id}/mp`}>{item.stats.media_plans_count || 0}</NavLink>
              </td>
            </tr>
          )
        })
      }

      </tbody>
    </table>
  )
}

PartnerCardList.defaultProps = {
  partners: {},
};

PartnerCardList.propTypes = {
  partners: PropTypes.object,
  onSelect: PropTypes.func,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(PartnerCardList);
