import React from "react";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {CustomerFormCreate} from "../containers";
import {Customer} from "../actions";
import {connect} from "react-redux";

class PageCustomerCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOrganizationCreate = (data) => {
    return this.props.dispatch(Customer.Create(data))
      .then((res) => this.props.history.push(`/c/${res.username}`))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  render() {
    const {customer} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_Customers')}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-md-8">
            <CustomerFormCreate account={customer} submit={this.handleOrganizationCreate}/>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => props

export default connect(mapStateToProps)(PageCustomerCreate);

