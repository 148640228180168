import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Preloader} from "../../components";
import {Vpn} from "../../actions";
import {getVpnServers} from "../../selectors";
import {VpnServerCardList} from "../../containers";
import {I18n} from "../../utils/i18n";
import VpnServerFormCreate from "../../containers/VpnServerFormCreate";


class SettingsPartialVpnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateForm: false,
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Vpn.ServerList())
      .then((res) => this.setState({pending: false}))
      .catch(() => this.setState({pending: false}))
  }

  handleCreate = (data) => {
    return this.props.dispatch(Vpn.ServerCreate(data))
      .then(() => this.setState({pending: false, showCreateForm: false}))
      .catch(() => this.setState({pending: false}))
  };

  handleRemove = (vpn) => {
    this.props.dispatch(Vpn.ServerRemove(vpn))
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      data: {...this.state.data, [name]: value}
    })
  };

  showCreateForm = () => {
    this.setState({showCreateForm: true})
  }

  handleCancel = () => {
    this.setState({showCreateForm: false})
  }

  render() {

    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    if (this.state.showCreateForm) {
      return (
        <div>
          <div className="row mb-4">
            <div className="col-md-6">
              <h4>{I18n.t('key_SettingsPartialVpnList_VpnServerCreate')}</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <VpnServerFormCreate submit={this.handleCreate} cancel={this.handleCancel}/>
            </div>
          </div>
        </div>
      )
    }


    const {servers} = this.props;

    return (
      <div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h4>{I18n.t('key_SettingsPartialVpnList_VpnServerList')}</h4>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={this.showCreateForm}>
                {I18n.t('key_Add')}
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <VpnServerCardList items={servers} onRemove={this.handleRemove}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    servers: getVpnServers(state),
  }
};

export default withRouter(connect(mapStateToProps)(SettingsPartialVpnList));

