import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class Tag {

  static Create(data) {
    let uri = [api_host, "tag"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static Fetch(id) {
    let uri = [api_host, "tag", id].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static List(opts) {
    let uri = [api_host, "tag"].join("/");
    opts = opts || {};
    let qs = serialize(opts);
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Update(id, data) {
    let uri = [api_host, "tag", id].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static Remove(id) {
    let uri = [api_host, "tag", id].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static MediaPlanListByTag(id) {
    let uri = [api_host, "tag", id, "usage"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ListByCustomer(customer) {
    let uri = [api_host, "a", "customer", customer, "tag"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static UpdateByCustomer(customer, data) {
    let uri = [api_host, "a", "customer", customer, "tag"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

}

export default Tag
