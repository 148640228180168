import React from "react";
import {connect} from "react-redux";
import {I18n} from "../utils/i18n";
import {Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {Device} from '../api';
import {DeviceFormAdd} from '../containers';

class PageDeviceAddNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      items: [],
      pending: true,
    };
  }

  componentDidMount() {
    Device.UnregisteredList()
      .then((res) => {
        this.setState({total: res.total, items: res.items, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleCancel = (e) => {
    e.preventDefault();
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/devices"}>{I18n.t('key_Devices')}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_PageDeviceCreate_NewDeviceTitle')}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <DeviceFormAdd items={this.state.items}
                           onCancel={this.handleCancel}
                           onOk={this.handleSubmit}/>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(PageDeviceAddNew);

