import {Vpn} from '../../api';
import {VPN_SERVER_CREATE_FAILURE, VPN_SERVER_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import vpnServerSchema from "../../schemas/vpn_server";


const SuccessAction = (payload) => ({
  type: VPN_SERVER_CREATE_SUCCESS,
  payload: normalize([payload], [vpnServerSchema])
});

const FailureAction = (payload) => ({
  type: VPN_SERVER_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Vpn.ServerCreate(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};
