import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {Button} from "../containers";
import Validator from "../utils/validator";

class TagGroupFormCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
      },
      error: {
        name: "",
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "name":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleBack = () => {
    this.props.cancel()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    let errors = {};
    errors.name = Validator.NotEmptyField(data.name);
    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = () => {
    this.setState({pending: false, data: {name: ""}});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad name parameter") e.domain = I18n.t('key_errorMsg_BadNameParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        <div className="form-group">
          <label htmlFor="name">{I18n.t('key_TagGroupFormCreate_NameLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.name ? 'is-invalid' : ''}`}
                 type="text" id="name" name="name"
                 onChange={this.handleChange}
                 value={this.state.data.name}/>
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>

        <div className="clearfix mt-5">
          <div className="float-left">
            <button type="button"
                    className='btn btn-secondary'
                    onClick={this.handleBack}>
              {I18n.t('key_Back')}
            </button>

          </div>
          <div className="float-right">
            <div className="form-group text-center">
              <Button className='btn btn-primary'
                      type="submit"
                      disabled={this.handleDisabled()}
                      submit={this.handleSubmit}
                      main={I18n.t('key_Send')}
              />
            </div>
          </div>
        </div>

      </div>
    )
  }
}

TagGroupFormCreate.propTypes = {
  name: PropTypes.string,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default TagGroupFormCreate;
