import {
  CUSTOMER_CREATE_FAILURE,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_FETCH_FAILURE,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_LIST_FETCH_FAILURE,
  CUSTOMER_LIST_FETCH_SUCCESS,
  CUSTOMER_UPDATE_FAILURE,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_REMOVE_FAILURE,
  CUSTOMER_REMOVE_SUCCESS,
  CUSTOMER_PASSWORD_CHANGE_FAILURE,
  CUSTOMER_PASSWORD_CHANGE_SUCCESS,
} from "../constants";


export const customerReducer = {
  [CUSTOMER_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [CUSTOMER_CREATE_FAILURE]: (state) => {
    return state;
  },
  [CUSTOMER_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [CUSTOMER_FETCH_FAILURE]: (state) => {
    return state;
  },
  [CUSTOMER_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "customers"]);
    return newState.mergeDeep(payload)
  },
  [CUSTOMER_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [CUSTOMER_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [CUSTOMER_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [CUSTOMER_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "customers", payload.id]);
  },
  [CUSTOMER_REMOVE_FAILURE]: (state) => {
    return state;
  },
  [CUSTOMER_PASSWORD_CHANGE_SUCCESS]: (state) => {
    return state;
  },
  [CUSTOMER_PASSWORD_CHANGE_FAILURE]: (state) => {
    return state;
  },

};
