import React from "react"
import {AccountFormSignIn} from "../../containers"
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

export const AccountPartialSignIn = ({submit}) => {
  return (
    <div>

      <div className="row justify-content-center text-center">
        <div className="col-12">
          <img className="lb-auth-page__header_logo" height="100" src="/images/logo/logo.svg"
               alt="Mosfilm"/>
          <h2 className="py-4 text-center">{I18n.t('key_AccountPartialSignIn_Description')}</h2>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <AccountFormSignIn submit={submit}/>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

AccountPartialSignIn.propTypes = {
  submit: PropTypes.func.isRequired
};

export default AccountPartialSignIn
