import React, {Component} from "react";
import PropTypes from "prop-types";

import "./style.css";

class Dropzone extends Component {

  constructor(props) {
    super(props);
    this.state = {hightlight: false};
    this.fileInputRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  };

  onFilesAdded = (e) => {
    if (this.props.disabled) return;
    if (!!this.props.limit && e.target.files.length > this.props.limit) {
      alert("Please select max " + this.props.limit +  " files.");
      return
    }
    const files = e.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  };

  onDragOver = (event) => {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({hightlight: true});
  };

  onDragLeave = (event) => {
    this.setState({hightlight: false});
  };

  onDrop = (event) => {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({hightlight: false});
  };

  fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  render() {
    return (
      <div className={`dropzone ${this.state.hightlight ? "dropzone-highlight" : ""}`}
           onDragOver={this.onDragOver}
           onDragLeave={this.onDragLeave}
           onDrop={this.onDrop}
           onClick={this.openFileDialog}
           style={{cursor: this.props.disabled ? "not-allowed" : "pointer"}}>

        <input ref={this.fileInputRef}
               className="dropzone-file-input"
               accept={this.props.accept}
               type="file"
               multiple
               onChange={this.onFilesAdded}/>

        <i className="fas fa-upload fa-2x dropzone-icon"/>

        <span>Upload Files</span>

      </div>
    );
  }
}

Dropzone.propTypes = {
  limit: PropTypes.number,
  onFilesAdded: PropTypes.func,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropzone;