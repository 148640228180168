import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter  } from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './app';
import {configureStore} from './utils';
import {LocalStorage} from './utils/storage';
import dictionary from "./data/dictionary"
import {loadTranslations, setLocale, syncTranslationWithStore} from './utils/i18n';

const store = configureStore({});
syncTranslationWithStore(store);

store.dispatch(loadTranslations(dictionary));
store.dispatch(setLocale(LocalStorage().get('lang') || "ru-RU"));

render((
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
