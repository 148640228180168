import {LocalStorage} from "./storage";

export function requestJSON(method, url, body, auth, headers) {

  headers = headers || {};
  headers["Content-Type"] = "application/json";
  headers["X-Tools-Name"] = "web";

  if (auth) {
    headers["Authorization"] = ["Bearer", LocalStorage().get("token")].join(" ");
  }

  let opts = {};
  opts.method = method;
  opts.headers = headers;
  opts.credentials = 'include';

  if (!!body) {
    opts.body = JSON.stringify(body);
  }

  return fetch(url, opts)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json().then((res) => {
          return res;
        }).catch(() => {
          return response;
        });
      }

      return response.json().then((e) => {
        throw e;
      });
    });
}

export function request(method, url, body, auth, headers) {

  headers = headers || {};
  headers["X-Tools-Name"] = "web";

  if (auth) {
    headers["Authorization"] = ["Bearer", LocalStorage().get("token")].join(" ");
  }

  let opts = {};
  opts.method = method;
  opts.headers = headers || {};
  opts.credentials = 'include';

  if (!!body) {
    opts.body = JSON.stringify(body);
  }

  return fetch(url, opts)
    .then(response => {
      return (response.status >= 200 && response.status < 300)
        ? response
        : response.then((e) => {
          throw e
        });
    });
}

export function download(url, name, auth, headers) {

  headers = headers || {};
  headers["X-Tools-Name"] = "web";

  if (auth) {
    headers["Authorization"] = ["Bearer", LocalStorage().get("token")].join(" ");
  }

  let opts = {};
  opts.method = "GET";
  opts.headers = headers;
  opts.credentials = 'include';

  return fetch(url, opts)
    .then(function (t) {
      return t.blob().then((b) => {
          let a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", name);
          a.click();
        }
      );
    });
}
