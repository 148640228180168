import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import moment from "moment";
import {NavLink, withRouter} from "react-router-dom";
import * as api from "../api";
import {MultiFileUploader, Pagination, TagTreeCard} from '../containers'
import {AdFormTagsStatisticsInfo} from '../components'
import {parseTime, Resource, serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";
import qs from "query-string";
import {connect} from "react-redux";

import {DatePicker, TimePicker} from 'antd';

import 'antd/dist/antd.min.css';

const dateFullFormat = 'DD-MM-YYYY HH:mm';
const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:mm';

const list_to_tree = (list) => {
  let map = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      title: item.name,
      key: item.id,
      parentId: item.parent_id,
    }
  });

  for (i = 0; i < items.length; i++) {
    map[items[i].key] = i; // initialize the map
    items[i].children = []; // initialize the children
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      node.checkable = true
      node.selectable = true
      items[map[node.parentId]].children.push(node);
    } else {
      roots.push(node)
    }
  }

  for (i = 0; i < roots.length; i++) {
    roots[i].checkable = !roots[i].children;
    roots[i].selectable = !roots[i].children;
  }

  return roots;
}
const defaultUpdate = {
  file: null,
  range: null,
  tags: null,
};

class CustomerAdCardInfo extends React.Component {

  constructor(props) {
    super(props);

    const data = props.item || {meta: {}, spec: {}};
    const file = (!!data.spec && !!data.spec.file) ? {id: data.spec.file.id, name: data.spec.file.name} : null;

    let tags = [];
    (data.spec.tags || []).forEach(item => {
      return tags.push(item.id)
    });

    this.state = {
      media: {
        items: [],
        total: 0,
        page: 1,
        limit: 10,
        pages: 1,
        maxPages: 5,
      },
      update: defaultUpdate,
      data: {
        status: props.item.status.status || "",
        file: file || null,
        tags: tags || [],
        start: moment(data.spec.start, "YYYY-MM-DD").toDate(),
        end: moment(data.spec.end, "YYYY-MM-DD").toDate(),
        start_time: parseTime(data.spec.start_time || "00:00:59"),
        end_time: parseTime(data.spec.end_time || "23:59:59"),
        allDay: !data.spec.use_time,
      },
      tags_statistics_list: [],
      error: {
        status: ""
      },
      showTags: [],
      treeTags: [],
      pending: false,
      editType: null
    };
  }

  componentDidMount() {
    api.Tag.List()
      .then((res) => {
        let tmpShowTags = {};
        res.forEach(item => {
          tmpShowTags[item.id] = item.name;
        })

        let showTags = [];

        (this.props.item.spec.tags || []).forEach(item => {
          if (!!tmpShowTags[item.id]) showTags.push(tmpShowTags[item.id])
        })

        let tmp = res.map(item => item.id)
        let treeTags = this.state.data.tags.filter(item => tmp.indexOf(item) > -1);
        this.setState({
          waiting: false,
          data: {...this.state.data, tags: treeTags},
          treeTags: list_to_tree(res),
          showTags: showTags
        })
      });

    api.Customer.AdTagsStatisticsList(this.props.customer.username, this.props.match.params.ad)
      .then((res) => {
        this.setState({...this.state, tags_statistics_list: res})
      });

    api.Customer.MediaList(this.props.customer.username, {
      page: this.state.media.page,
      limit: this.state.media.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: res.items || [],
          }
        })
      })
      .catch(() => {
      });

  }

  componentWillUpdate(nextProps) {
    if (JSON.stringify(nextProps.item.spec) !== JSON.stringify(this.props.item.spec)) {
      api.Tag.List()
        .then((res) => {
          let tmpShowTags = {};
          res.forEach(item => {
            tmpShowTags[item.id] = item.name;
          })

          let showTags = [];
          this.props.item.spec.tags.forEach(item => {
            if (!!tmpShowTags[item.id]) showTags.push(tmpShowTags[item.id])
          })

          let tmp = res.map(item => item.id)
          let treeTags = this.state.data.tags.filter(item => tmp.indexOf(item) > -1);
          this.setState({
            waiting: false,
            data: {...this.state.data, tags: treeTags},
            treeTags: list_to_tree(res),
            showTags: showTags
          })
        });
    }
  }

  handleExportToExcel = (item) => {
    console.log(item)
    if (item.status.status !== "done") return;
    api.Customer.AdTagsStatisticsExcelExport(this.props.customer.username, item.meta.id, item.meta.name)
  }

  handleSelectFile = (e, item) => {
    this.setState({update: {...this.state.update, file: {id: item.meta.id, name: item.meta.name}}})
  };

  handleMediaFilePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    api.Customer.MediaList(this.props.customer.username, {
      page: page,
      limit: this.state.media.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: res.items || [],
          }
        })
      })
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "tags":
        patch = {update: {...this.state.update, [name]: value}};
        break;
      case "status":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        if (value !== "created") {
          patch.update = defaultUpdate;
        }
        break;
      default:
    }

    this.setState(patch, () => {
      this.handleSubmit()
    })
  }

  handleEdit = (type) => {
    this.setState({editType: type})
  }

  handleUseTime = (e) => {
    const checked = e.target.checked
    this.setState(state => {
      state.data.allDay = checked;
      if (checked) {
        state.data.start_time = parseTime("00:00:00");
        state.data.end_time = parseTime("23:59:59");
      }
      return state;
    })
  }

  handleDateChange = (dates) => {
    this.setState(state => {
      const start = dates[0].toDate();
      const end = dates[1].toDate();
      state.data.start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
      state.data.end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0);
      return state
    })
  }

  handleTimeChange = (name, value) => {
    let date = !!value ? new Date(value.utc().toDate()) : null;
    this.setState(state => {
      state.data[name] = date
      return state
    })
  };

  handleApply = () => {
    if (this.state.data.status !== "created") return;
    let patch = {};
    switch (this.state.editType) {
      case "media":
        patch = {data: {...this.state.data, file: this.state.update.file}};
        break;
      case "date":
        patch = {data: {...this.state.data, range: this.state.update.range}};
        break;
      case "tags":
        patch = {data: {...this.state.data, tags: this.state.update.tags}};
        break;
      default:
    }

    patch.editType = null;
    patch.update = defaultUpdate;

    this.setState(patch, () => this.handleSubmit())
  }

  handleApplyDisabled = () => {
    return (this.state.editType === "media" && this.state.update.file === null)
      || (this.state.editType === "tags" && this.state.update.tags === null)
      || (this.state.editType === "date" && this.state.update.start === null)
      || (this.state.editType === "date" && this.state.update.end === null)
  }

  handleEvent = (e) => {
    setTimeout(() => {
      this.handleMediaFilePageChange(this.state.page)
    }, 1000)
  };

  handleSubmit = () => {
    let data = {...this.state.data};
    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad state parameter") e.first_name = I18n.t('key_errorMsg_BadStateParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {

    const {item, customer} = this.props;

    window.$(() => window.$('[data-toggle="tooltip"]').tooltip());

    return (
      <div className="row">
        {/* ================================================================================= */}
        {/* INFO FORMS ====================================================================== */}
        {/* ================================================================================= */}
        <div className="col-md-4 col-sm-12">
          <ul className="list-group list-group-flush bg-transparent">
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_NameTitle')}</div>
              <div>{item.meta.name}</div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_CreatedTitle')}</div>
              <div>{moment(item.created).format(dateFullFormat)}</div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_CustomerTitle')}</div>
              <div><NavLink to={`/c/${customer.username}`}>{customer.organization.name}</NavLink></div>
            </li>
            <li className="list-group-item bg-transparent">
              <div className="text-uppercase font-weight-bold d-flex">
                <div className="w-100">
                  {I18n.t('key_CustomerAdCardInfo_MediaTitle')}
                </div>
                <div className="flex-shrink-1">
                  {this.state.data.status === "created" && (
                    <i className="fa fa-edit" onClick={() => this.handleEdit("media")}/>
                  )}
                </div>
              </div>
              <div className="pt-2">
                {
                  (item.spec.file.state === "deleted")
                    ? <span className="text-danger">{item.spec.file.name}&nbsp;&nbsp;<i className="fa fa-info-circle"
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="right"
                                                                                        title="Файл был удален"/></span>
                    : <NavLink to={`/c/${customer.username}/media/${item.spec.file.id}`}>{item.spec.file.name}</NavLink>
                }
              </div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_FormatTitle')}</div>
              <div>
                {item.spec.file.orientation === "vertical" && I18n.t('key_CustomerAdCardInfo_FormatVertical')}
                {item.spec.file.orientation === "horizontal" && I18n.t('key_CustomerAdCardInfo_FormatHorizontal')}
              </div>
            </li>
            <li className="list-group-item bg-transparent">
              <div className="text-uppercase font-weight-bold d-flex">
                <div className="w-100">
                  {I18n.t('key_CustomerAdCardInfo_Dates')}
                </div>
                <div className="flex-shrink-1">
                  {this.state.data.status === "created" && (
                    <i className="fa fa-edit" onClick={() => this.handleEdit("date")}/>
                  )}
                </div>
              </div>
              <div>
                <table className="table table-sm table-striped table-hover">
                  <thead>
                  <tr>
                    <th className="text-center">{I18n.t('key_CustomerAdCardInfo_StartDateTitle')}</th>
                    <th className="text-center">{I18n.t('key_CustomerAdCardInfo_EndDateTitle')}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">{moment(item.spec.start).format(dateFormat)}</td>
                      <td className="text-center">{moment(item.spec.end).format(dateFormat)}</td>
                    </tr>
                    <tr>
                      <td className="text-center">{moment(parseTime(item.spec.start_time)).format( "HH:mm")}</td>
                      <td className="text-center">{moment(parseTime(item.spec.end_time)).format( "HH:mm")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li className="list-group-item bg-transparent">
              <div className="text-uppercase font-weight-bold d-flex">
                <div className="w-100">
                  {I18n.t('key_CustomerAdCardInfo_TagsTitle')}
                </div>
                <div className="flex-shrink-1">
                  {this.state.data.status === "created" && (
                    <i className="fa fa-edit" onClick={() => this.handleEdit("tags")}/>
                  )}
                </div>
              </div>
              <div>
                {
                  this.state.showTags.map((item, index) => (
                      <span key={index} className="badge badge-info pr-2 mr-2">{item}</span>
                    )
                  )
                }
              </div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_StatusTitle')}</div>
              <div>
                <select className="form-control form-control-sm" id="status"
                        name="status"
                        value={this.state.data.status}
                        onChange={this.handleChange}>
                  <option value="created">{I18n.t('key_CustomerAdCardInfo_StatusCreated')}</option>
                  <option value="accepted">{I18n.t('key_CustomerAdCardInfo_StatusAccepted')}</option>
                  <option value="rejected">{I18n.t('key_CustomerAdCardInfo_StatusRejected')}</option>
                  <option value="done">{I18n.t('key_CustomerAdCardInfo_StatusDone')}</option>
                </select>
              </div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerAdCardInfo_CostTitle')}</div>
              <div>&mdash;</div>
            </li>
            <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
              <div className="text-uppercase font-weight-bold">
                {I18n.t('key_AdPageInto_TagsStatistics')}
              </div>
              <div>
                <div className="align-self-center pb-2">
                  <button
                    disabled={this.state.data.status !== "done"}
                    className="btn btn-sm btn-success text-uppercase"
                    onClick={() => this.handleExportToExcel(item)}>
                    <i className="fa fa-file-excel"/>&nbsp;{I18n.t('key_ExportToExcel')}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* ================================================================================= */}
        {/* UPDATE FORMS ==================================================================== */}
        {/* ================================================================================= */}
        <div className="col-md-8 col-sm-12">
          {(!this.state.editType) && <AdFormTagsStatisticsInfo list={this.state.tags_statistics_list}/>}

          {(!!this.state.editType) && (
            <div className="card">
                <div className="card-body">
                  <div className="mb-4">
                    {this.state.editType === "date" && (
                      <div>
                        <div className="mt-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                                {I18n.t('key_CustomerAdCardInfoFormUpdate_LabelDateOrDates')}
                              </label>
                            </div>
                            <DatePicker.RangePicker id="date_filter"
                                                    className="mr-sm-2"
                                                    placeholder={[I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateStart'), I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateEnd')]}
                                                    ranges={this.ranges}
                                                    defaultValue={[moment(this.state.data.start, dateFormat), moment(this.state.data.end, dateFormat)]}
                                                    value={[moment(this.state.data.start, timeFormat), moment(this.state.data.end, timeFormat)]}
                                                    format={dateFormat}
                                                    showTime={false}
                                                    disabledDate={this.disabledDate}
                                                    onChange={this.handleDateChange}
                                                    allowClear={false}
                                                    showToday={false}
                                                    showOk={true}
                                                    allowEmpty={false}
                            />
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                                {I18n.t('key_AdvertisingCampaignFormUpdate_LabelTime')}
                              </label>
                            </div>
                            <TimePicker format={timeFormat}
                                        inputReadOnly={true}
                                        allowClear={false}
                                        defaultValue={moment('00:00', timeFormat)}
                                        value={moment(this.state.data.start_time)}
                                        onChange={val => this.handleTimeChange("start_time", val)}
                                        disabled={this.state.data.allDay}
                                        showNow={false}
                            />

                            <TimePicker format={timeFormat}
                                        inputReadOnly={true}
                                        allowClear={false}
                                        defaultValue={moment('23:59:59', timeFormat)}
                                        value={moment(this.state.data.end_time)}
                                        onChange={val => this.handleTimeChange("end_time", val)}
                                        disabled={this.state.data.allDay}
                                        showNow={false}
                            />
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                   defaultChecked={this.state.data.allDay}
                                   value={this.state.data.allDay}
                                   onClick={this.handleUseTime}
                                   id="flexCheckDefault"/>
                            <label className="form-check-label"
                                   htmlFor="flexCheckDefault"
                                   style={{marginTop: "1px"}}>
                              Проигрывать в течение дня
                            </label>
                          </div>
                        </div>

                      </div>
                    )}
                    {this.state.editType === "media" && (
                      <div>
                        <div className="list-group list-group-flush">

                          {
                            (this.state.media.items).map((item, index) => {
                              return (
                                <div key={index}
                                     className="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
                                     onClick={e => this.handleSelectFile(e, item)}>

                                  <div style={{verticalAlign: "middle", maxWidth: "300px"}}
                                       className="text-truncate">{item.meta.name}</div>

                                  <div style={{verticalAlign: "middle"}}>
                                <span className="badge badge-info" style={{width: "60px"}}>
                                  {Resource.MemoryBytesToHumanSize(item.meta.size, 2)}
                                </span>
                                  </div>

                                </div>
                              )
                            })
                          }

                        </div>

                        <div className="mt-4">
                          <Pagination maxPages={this.state.media.maxPages}
                                      currentPage={this.state.media.page}
                                      numPages={this.state.media.pages}
                                      change={this.handleMediaFilePageChange}/>
                        </div>

                        <div>
                          <div className="d-flex justify-content-center">
                            <MultiFileUploader accept=".mkv,.mp4"
                                               onMessage={this.handleEvent}
                                               uploadURI={[window._env_.API_HOST, "a", "customer", this.props.customer.username, "media", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token")}/>
                          </div>
                        </div>

                      </div>
                    )}
                    {this.state.editType === "tags" && (
                      <div>
                        {
                          (!this.state.treeTags.length)
                            ? <div>{I18n.t('key_CustomerAdCardInfo_ThereAreNoTagsAvailable')}</div>
                            : <TagTreeCard name="tags"
                                           data={this.state.treeTags}
                                           selected={this.state.data.tags}
                                           onChange={this.handleChange}/>
                        }
                      </div>
                    )}
                  </div>

                  <div className="clearfix">
                    <div className="float-left">
                      <button className="btn btn-outline-default" onClick={() => this.handleEdit(null)}>
                        {I18n.t('key_Cancel')}
                      </button>
                    </div>
                    <div className="float-right">
                      <button className="btn btn-success"
                              disabled={this.handleApplyDisabled()}
                              onClick={this.handleApply}>
                        {I18n.t('key_Apply')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
          )}
        </div>

      </div>
    )
  }
}

CustomerAdCardInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return props
};

export default withRouter(connect(mapStateToProps)(CustomerAdCardInfo));
