export function serialize(obj) {
  let str = [];
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && !!obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
}

export function uuid4() {
  //// return uuid of form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
  let uuid = '', ii;
  for (ii = 0; ii < 32; ii += 1) {
    switch (ii) {
      case 8:
      case 20:
        uuid += '-';
        uuid += (Math.random() * 16 | 0).toString(16);
        break;
      case 12:
        uuid += '-';
        uuid += '4';
        break;
      case 16:
        uuid += '-';
        uuid += (Math.random() * 4 | 8).toString(16);
        break;
      default:
        uuid += (Math.random() * 16 | 0).toString(16);
    }
  }
  return uuid;
}

export function parseBoolean(string) {
  switch (true) {
    case !string:
      return false;
    case string.toLowerCase() === 'true':
      return true;
    case string.toLowerCase() === 'false':
      return false;
    default:
      return false;
  }
}

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compareObjects(o, p) {
  let i,
    keysO = Object.keys(o).sort(),
    keysP = Object.keys(p).sort();
  if (keysO.length !== keysP.length)
    return false;//not the same nr of keys
  if (keysO.join('') !== keysP.join(''))
    return false;//different keys
  for (i = 0; i < keysO.length; ++i) {
    if (o[keysO[i]] instanceof Array) {
      if (!(p[keysO[i]] instanceof Array))
        return false;
      //if (compareObjects(o[keysO[i]], p[keysO[i]] === false) return false
      //would work, too, and perhaps is a better fit, still, this is easy, too
      if (p[keysO[i]].sort().join('') !== o[keysO[i]].sort().join(''))
        return false;
    } else if (o[keysO[i]] instanceof Date) {
      if (!(p[keysO[i]] instanceof Date))
        return false;
      if (('' + o[keysO[i]]) !== ('' + p[keysO[i]]))
        return false;
    } else if (o[keysO[i]] instanceof Function) {
      if (!(p[keysO[i]] instanceof Function))
        return false;
      //ignore functions, or check them regardless?
    } else if (o[keysO[i]] instanceof Object) {
      if (!(p[keysO[i]] instanceof Object))
        return false;
      if (o[keysO[i]] === o) {//self reference?
        if (p[keysO[i]] !== p)
          return false;
      } else if (compareObjects(o[keysO[i]], p[keysO[i]]) === false)
        return false;//WARNING: does not deal with circular refs other than ^^
    }
    if (o[keysO[i]] !== p[keysO[i]])//change !== to != for loose comparison
      return false;//not the same value
  }
  return true;
}

export function parseTime(timeStr, dt) {

  if (!dt) dt = new Date(1970, 0, 1); // Epoch

  let time = timeStr.match(/(\d+)(?::(\d\d))?(?::(\d\d))?\s*(p?)/i);
  if (!time) {
    return NaN;
  }
  let hours = parseInt(time[1], 10);
  if (hours === 12 && !time[4]) {
    hours = 12;
  } else {
    hours += (hours < 12 && time[4]) ? 12 : 0;
  }

  dt.setHours(hours);
  dt.setMinutes(parseInt(time[2], 10) || 0);
  dt.setSeconds(parseInt(time[3], 10) || 0);

  return dt;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}


// ===============================================================================
// Resource utils
// ===============================================================================

export const Resource = (() => {

  const binaryMaps = {
    b: 1,
    kib: 1024,
    mib: 1.049e+6,
    gib: 1.074e+9,
    tib: 1.1e+12,
    pib: 1.126e+15,
    eib: 1.153e+18,
    zib: 1.181e+21,
    yib: 1.209e+24,
  };

  const decimalMaps = {
    b: 1,
    kb: 1e+3,
    mb: 1e+6,
    gb: 1e+9,
    tb: 1e+12,
    pb: 1e+15,
    eb: 1e+18,
    zb: 1e+21,
    yb: 1e+24,
  };

  const decimalSizeName = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const binarySizeName = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  function getSize(bytes, base, maps, precision = 0, raw = false) {
    if (typeof bytes !== "number") return undefined;
    if (typeof base !== "number") return undefined;

    let neg = bytes < 0;

    if (neg) bytes = -bytes;
    if (bytes < 1) {
      return raw
        ? {neg: (neg ? '-' : ''), val: bytes, unit: "B"}
        : [(neg ? '-' : ''), bytes, "B"].join('');
    }

    let exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(base)), maps.length - 1);
    let unit = maps[exponent];
    let size = Number((bytes / Math.pow(base, exponent)).toFixed(precision));

    return raw
      ? {neg: (neg ? '-' : ''), val: size, unit: unit}
      : [(neg ? '-' : ''), size, unit].join('');
  }

  class _resource {

    // constructor() {}

    // MemoryBytesToBinarySize returns a human-readable size in bytes, kibibytes,
    // mebibytes, gibibytes, or tebibytes (eg. "44kiB", "17MiB").
    MemoryBytesToBinarySize = (bytes, precision = 0, raw = false) => {
      return getSize(bytes, 1024, binarySizeName, precision, raw);
    };

    // MemoryBytesToDecimalSize returns a human-readable size in bytes, kilobytes,
    // megabytes, gigabytes, or terabytes (eg. "44kiB", "17MiB").
    MemoryBytesToDecimalSize = (bytes, precision = 0, raw = false) => {
      return getSize(bytes, 1000, decimalSizeName, precision, raw);
    };

    // MemoryBytesToHumanSize returns a human-readable approximation of a size.
    MemoryBytesToHumanSize = (bytes, precision = 0, raw = false) => {
      return getSize(bytes, 1000, decimalSizeName, precision, raw);
    };

    MemoryBytesToCustomSize = (bytes, unit, precision = 0) => {
      if (typeof bytes !== "number") return undefined;
      if (typeof unit !== "string") return undefined;

      let neg = bytes < 0;
      if (neg) bytes = -bytes;

      let u = unit.replace(/\s/g, '').toLowerCase();

      if (u === "b" || bytes < 1) return bytes;

      if (u[1] === "i") {
        // ki,mi,gi,ti,pi,ei,zi,yi
        if (u.length === 2) u += 'b';
        return Number((bytes / Math.pow(1024, Object.keys(binaryMaps).indexOf(u))).toFixed(precision));
      }

      return Number((bytes / Math.pow(1000, Object.keys(decimalMaps).indexOf(u))).toFixed(precision));
    };

    MemorySizeToBytes = (s) => {

      if (typeof s !== "string") return undefined;

      s = s.replace(/\s/g, '');
      let match = s.match(/^(\d+(\.\d+)*)?[\s]?(([kKmMgGtTpPeEzZyYB])?[iI]?[bB]?)?$/i);
      if (!match || match.length !== 5) return undefined;
      if (!match[1] || !match[3]) return undefined;

      let size = parseFloat(match[1]);
      let unit = match[3].toLowerCase();

      if (unit === "b") return size;
      if (unit[1] === "i") {
        // ki,mi,gi,ti,pi,ei,zi,yi
        if (unit.length === 2) unit += 'b';
        return !!binaryMaps[unit] ? size * binaryMaps[unit] : undefined;
      }
      return !!decimalMaps[unit] ? size * decimalMaps[unit] : undefined;
    };

    CPUToHumanSize = (size, precision = 1) => {
      return parseFloat(size || 0).toFixed(precision)
    }

  }

  return new _resource();
})();

