import React from "react";
import {
  SettingsPartialGeneral,
  SettingsPartialSystemTriggers,
  SettingsPartialSmtpServer,
  SettingsPartialTagList,
  SettingsPartialTelegram,
  SettingsPartialVpnList,
  SettingsPartialFFmpegCommandList
} from "./Settings";
import {I18n} from "../utils/i18n";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import qs from "query-string";
import {serialize} from "../utils/helpers";

class PageSettings extends React.Component {
  constructor(props) {
    super(props);
    let query = qs.parse(this.props.history.location.search);

    this.state = {
      tab: query.tab || "general",
    };
  }

  handleSelectTab = (e, tab) => {
    e.preventDefault()
    let query = qs.parse(this.props.history.location.search);
    query.tab = tab;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
    this.setState({tab: tab})
  }

  render() {
    return (
      <section className="container-flex content">

        <div className="subnav-panel">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
            <li className="breadcrumb-item active">{I18n.t('key_Tags')}</li>
          </ol>
        </div>

        <div className="row">

          <div className="col-md-2">
            <div className="nav flex-column nav-pills">
              <NavLink to="#" className={`nav-link ${this.state.tab === "general" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "general")}>
                {I18n.t('key_PageSettingsInfo_TabGeneral')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "tags" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "tags")}>
                {I18n.t('key_PageSettingsInfo_TabTags')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "smtp" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "smtp")}>
                {I18n.t('key_PageSettingsInfo_TabSmtp')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "telegram" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "telegram")}>
                {I18n.t('key_PageSettingsInfo_TabTelegram')}s
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "vpn" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "vpn")}>
                {I18n.t('key_PageSettingsInfo_TabVpn')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "rules" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "rules")}>
                {I18n.t('key_PageSettingsInfo_Rules')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "ffmpeg" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "ffmpeg")}>
                {I18n.t('key_PageSettingsInfo_FfmpegCommands')}
              </NavLink>
            </div>
          </div>

          <div className="col-md-10">
            {this.state.tab === "general" && <SettingsPartialGeneral/>}
            {this.state.tab === "tags" && <SettingsPartialTagList/>}
            {this.state.tab === "smtp" && <SettingsPartialSmtpServer/>}
            {this.state.tab === "telegram" && <SettingsPartialTelegram/>}
            {this.state.tab === "vpn" && <SettingsPartialVpnList/>}
            {this.state.tab === "rules" && <SettingsPartialSystemTriggers/>}
            {this.state.tab === "ffmpeg" && <SettingsPartialFFmpegCommandList/>}
          </div>

        </div>
      </section>
    )
  }
}

const mapStateToProps = (state, props) => props

export default connect(mapStateToProps)(PageSettings);


