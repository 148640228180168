import {FFmpeg} from '../../api';
import {FFMPEG_COMMAND_CREATE_FAILURE, FFMPEG_COMMAND_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import ffmpegCommandSchema from "../../schemas/ffmpeg_command";


const SuccessAction = (payload) => ({
  type: FFMPEG_COMMAND_CREATE_SUCCESS,
  payload: normalize([payload], [ffmpegCommandSchema])
});

const FailureAction = (payload) => ({
  type: FFMPEG_COMMAND_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    FFmpeg.CommandCreate(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};
