import React from "react";
import {I18n} from "../utils/i18n";

class PageDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <section className="container content">
        <div className="row">
          <div className="col-12">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">{I18n.t('key_Home')}</li>
              <li className="breadcrumb-item active">{I18n.t('key_Dashboard')}</li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <img src="../images/dashboard.png" alt="" style={{width: "inherit"}}/>
          </div>
        </div>
      </section>
    )
  }
}

export default PageDashboard
