import React from "react";
import {connect} from "react-redux";
import {Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Partner} from "../actions";
import {getPartnerByID, getMediaFileByID} from "../selectors";
import {PartnerMediaCardInfo} from "../containers";
import {fetchImageFromUrlToCanvas, fetchPDFFromUrlToCanvas, fetchTiffFromUrlToCanvas} from "../utils/content";
import {Image, Layer, Stage} from "react-konva";
import * as api from "../api";

class PagePartnerMediaInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      error: null,
      pending: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(Partner.Fetch(this.props.match.params.partner)),
      this.props.dispatch(Partner.MediaFetch(this.props.match.params.partner, this.props.match.params.media))
    ])
      .then((res) => {
        const item = res[1];
        if (item.meta.type === "static") {
          this.handleView(item.meta, (cb, err) => {
            let src;
            if (!err) {
              src = (
                <Stage width={cb.width} height={cb.height}>
                  <Layer>
                    <Image image={cb}/>
                  </Layer>
                </Stage>
              )
            } else {
              src = <span>Bad file</span>;
            }
            this.setState({pending: false, src: src});
          })
        } else {
          let src = api.Partner.MediaStreamUrl(this.props.match.params.partner, this.props.match.params.media)
          this.setState({pending: false, src: src});
        }
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/p/${this.props.match.params.partner}/media`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleView = (item, cb) => {
    let src = api.Partner.MediaDownloadUrl(this.props.match.params.partner, this.props.match.params.media)
    let opts = {
      screenWidth: 480,
      screenHeight: 480,
    }

    if (item.mime === "application/pdf") {
      fetchPDFFromUrlToCanvas(src, opts, cb)
      return;
    }

    if (item.mime === "image/tiff") {
      fetchTiffFromUrlToCanvas(src, opts, cb)
      return
    }

    fetchImageFromUrlToCanvas(src, opts, cb)
  };

  handleDownload = () => {
    const uri = api.Partner.MediaDownloadUrl(this.props.match.params.partner, this.props.match.params.media)
    fetch(uri)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = this.props.media.meta.name;
          a.click();
        });
        window.location.href = response.url;
      });
  }

  handleUpdate = (data) => {
    return this.props.dispatch(Partner.MediaUpdate(this.props.match.params.partner, this.props.match.params.media, data))
  }

  handleError = (e) => {
    this.setState({error: I18n.t('key_errorMsg_VideoNotSupportedInBrowser')})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {partner, media} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/partners"}>{I18n.t('key_Partners')}</NavLink></li>
                <li className="breadcrumb-item">
                  <NavLink to={`/p/${partner.username}`}>{partner.email}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/p/${partner.username}/media`}>{I18n.t('key_Medias')}</NavLink>
                </li>
                <li className="breadcrumb-item active">{media.meta.name}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <PartnerMediaCardInfo item={media} partner={partner} handleDownload={this.handleDownload}
                                   submit={this.handleUpdate}/>
          </div>
          <div className="col-md-6">
            {
              (media.status.state === "ready")
                ? (media.meta.type === "static")
                ? <div className="d-flex justify-content-end">{this.state.src}</div>
                : (
                  <div>
                    {
                      (!!this.state.error)
                        ? (
                          <div className="alert alert-danger text-center">
                            <div><h4>{this.state.error}</h4></div>
                            <div><i className="fa fa-sad-cry fa-4x"/></div>
                          </div>)
                        : (
                          <div className="d-flex justify-content-end">
                            <video width="100%" height="350" controls="controls" autoPlay={false}
                                   onError={this.handleError}
                                   muted
                                   playsInline
                                   disablePictureInPicture
                                   controlsList="nodownload">
                              <source src={this.state.src}/>
                              {I18n.t('key_errorMsg_VideoNotSupportedInBrowser')}
                            </video>
                          </div>
                        )
                    }
                  </div>
                )
                : <div className="alert alert-warning text-center">{I18n.t('key_errorMsg_VideoNotUploaded')}</div>
            }
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    partner: getPartnerByID(state, props.match.params.partner),
    media: getMediaFileByID(state, props.match.params.media),
  }
};

export default connect(mapStateToProps)(PagePartnerMediaInfo);

