import {LocalStorage} from "../utils/storage";
import {
  ACCOUNT_CREATE_FAILURE,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CURRENT_SET_SUCCESS,
  ACCOUNT_FETCH_FAILURE,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_LIST_FETCH_FAILURE,
  ACCOUNT_LIST_FETCH_SUCCESS,
  ACCOUNT_PASSWORD_CHANGE_FAILURE,
  ACCOUNT_PASSWORD_CHANGE_SUCCESS,
  ACCOUNT_PASSWORD_RECOVERY_FAILURE,
  ACCOUNT_PASSWORD_RECOVERY_SUCCESS,
  ACCOUNT_PASSWORD_UPDATE_FAILURE,
  ACCOUNT_PASSWORD_UPDATE_SUCCESS,
  ACCOUNT_PROFILE_FETCH_FAILURE,
  ACCOUNT_PROFILE_FETCH_SUCCESS,
  ACCOUNT_PROFILE_UPDATE_FAILURE,
  ACCOUNT_PROFILE_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAILURE,
  ACCOUNT_UPDATE_SUCCESS
} from "../constants";

export const accountReducer = {

  [ACCOUNT_CURRENT_SET_SUCCESS]: (state, payload) => {
    LocalStorage().set("acc", payload.username);
    return state.mergeDeep({...payload, current_account: payload.username})
  },

  [ACCOUNT_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [ACCOUNT_FETCH_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_LIST_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [ACCOUNT_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_CREATE_SUCCESS]: (state) => {
    return state;
  },
  [ACCOUNT_CREATE_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [ACCOUNT_UPDATE_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_PROFILE_UPDATE_SUCCESS]: (state) => {
    return state;
  },
  [ACCOUNT_PROFILE_UPDATE_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_PROFILE_FETCH_SUCCESS]: (state, payload) => {
    let newState = Object.assign({}, state);
    newState.profile.name = payload.name || "";
    newState.profile.company = payload.company || "";
    newState.profile.country = payload.country || "";
    newState.profile.endpoint = payload.url || "";
    newState.profile.biography = payload.biography || "";
    newState.profile.location = payload.location || "";
    return state.mergeDeep({account: newState})
  },
  [ACCOUNT_PROFILE_FETCH_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_PASSWORD_UPDATE_SUCCESS]: (state) => {
    return state;
  },
  [ACCOUNT_PASSWORD_UPDATE_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_PASSWORD_RECOVERY_SUCCESS]: (state) => {
    return state;
  },
  [ACCOUNT_PASSWORD_RECOVERY_FAILURE]: (state) => {
    return state;
  },

  [ACCOUNT_PASSWORD_CHANGE_SUCCESS]: (state) => {
    return state;
  },
  [ACCOUNT_PASSWORD_CHANGE_FAILURE]: (state) => {
    return state;
  }
};
