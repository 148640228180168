import {Customer} from '../../api';

import {CUSTOMER_CREATE_FAILURE, CUSTOMER_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import customerSchema from "../../schemas/customer";


const SuccessAction = (payload) => ({
  type: CUSTOMER_CREATE_SUCCESS,
  payload: normalize([payload], [customerSchema])
});

const FailureAction = (payload) => ({
  type: CUSTOMER_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Customer.Create(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};
