import {
  VPN_SERVER_CREATE_FAILURE,
  VPN_SERVER_CREATE_SUCCESS,
  VPN_SERVER_FETCH_FAILURE,
  VPN_SERVER_FETCH_SUCCESS,
  VPN_SERVER_LIST_FETCH_FAILURE,
  VPN_SERVER_LIST_FETCH_SUCCESS,
  VPN_SERVER_REMOVE_FAILURE,
  VPN_SERVER_REMOVE_SUCCESS,
  VPN_SERVER_UPDATE_FAILURE,
  VPN_SERVER_UPDATE_SUCCESS,
} from "../constants";


export const vpnReducer = {
  [VPN_SERVER_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [VPN_SERVER_CREATE_FAILURE]: (state) => {
    return state;
  },
  [VPN_SERVER_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [VPN_SERVER_FETCH_FAILURE]: (state) => {
    return state;
  },
  [VPN_SERVER_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "vpn_servers"]);
    return newState.mergeDeep(payload)
  },
  [VPN_SERVER_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [VPN_SERVER_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [VPN_SERVER_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [VPN_SERVER_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "vpn_servers", payload.id]);
  },
  [VPN_SERVER_REMOVE_FAILURE]: (state) => {
    return state;
  },
};
