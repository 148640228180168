import React from "react";
import {connect} from "react-redux";
import {PartnerMediaCardList, Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Customer, Partner} from "../actions";
import {getPartnerByID, getMediaFiles} from "../selectors";
import qs from "query-string";
import {serialize} from "../utils/helpers";
import {Pagination} from "../containers";
import debounce from "lodash.debounce";

class PagePartnerMediaList extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      status: query.status || '',
      filter: query.filter || '',
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Partner.Fetch(this.props.match.params.partner))
      .then(() => {
        return this.props.dispatch(Partner.MediaList(this.props.match.params.partner, {
          page: this.state.page,
          limit: this.state.limit,
          filter: this.state.filter,
          status: this.state.status,
        }))
          .then((res) => {
            const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

            if (res.page > pages) return this.handlePageChange(pages);

            this.setState({total: res.total, page: res.page, pages: pages, pending: false})
          })
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/p/${this.props.match.params.partner}`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(Partner.MediaList(this.props.match.params.partner, {
      page: page,
      limit: this.state.limit,
      filter: this.state.filter,
      status: this.state.status,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  };


  handleFilterStatusChange = (e) => {
    const target = e.target;
    const value = target.value;

    let query = qs.parse(this.props.history.location.search);
    query.status = value;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    return this.props.dispatch(Partner.MediaList(this.props.match.params.partner, {
      page: this.state.page,
      limit: this.state.limit,
      filter: this.state.filter,
      status: value,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({status: value, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleFilterChange = debounce((value) => {
    let query = qs.parse(this.props.history.location.search);
    query.filter = value;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    return this.props.dispatch(Partner.MediaList(this.props.match.params.partner, {
      page: this.state.page,
      limit: this.state.limit,
      status: this.state.status,
      filter: value,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({filter: value, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }, 300)

  handleRemove = (item) => {
    return this.props.dispatch(Partner.MediaRemove(this.props.match.params.partner, item.id))
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {partner, medias} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/partners"}>{I18n.t('key_Partners')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink
                  to={`/p/${partner.username}`}>{partner.email}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_Medias')}</li>
              </ol>
            </div>
          </div>
        </div>

        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <NavLink className="nav-link active" to={`/p/${partner.id}/media`}>{I18n.t('key_Medias')}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/p/${partner.id}/mp`}>{I18n.t('key_MediaPlans')}</NavLink>
          </li>
        </ul>

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-4">
              <div>
                <input type="text"
                       style={{minWidth: "400px"}}
                       className="form-control form-control-sm"
                       placeholder={I18n.t('key_PageCustomerAdList_FilterSearch')}
                       name="filter"
                       defaultValue={this.state.filter}
                       onChange={e => this.handleFilterChange(e.target.value)}/>
              </div>
              <div style={{maxWidth: "250px"}}>
                <select className="form-control form-control-sm" id="status"
                        name="status"
                        value={this.state.status}
                        onChange={this.handleFilterStatusChange}>
                  <option value="">{I18n.t('key_PartnerMediaCardList_FilterStatusAll')}</option>
                  <option value="created">{I18n.t('key_PartnerMediaCardList_FilterStatusCreated')}</option>
                  <option value="accepted">{I18n.t('key_PartnerMediaCardList_FilterStatusAccepted')}</option>
                  <option value="rejected">{I18n.t('key_PartnerMediaCardList_FilterStatusRejected')}</option>
                </select>
              </div>
            </div>

            <PartnerMediaCardList partner={partner} items={medias.sortDateDesc()} onRemove={this.handleRemove}/>

            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    partner: getPartnerByID(state, props.match.params.partner),
    medias: getMediaFiles(state),
  }
};

export default connect(mapStateToProps)(PagePartnerMediaList);

