import {I18n} from "../utils/i18n";

export const USERNAME_CANNOT_BE_BLANK = () => I18n.t('key_errorMsg_USERNAME_CANNOT_BE_BLANK');
export const USERNAME_OR_EMAIL_INVALID = () => I18n.t('key_errorMsg_USERNAME_OR_EMAIL_INVALID');
export const USERNAME_EXISTS = () => I18n.t('key_errorMsg_USERNAME_EXISTS');
export const USERNAME_FORMAT_INVALID = () => I18n.t('key_errorMsg_USERNAME_FORMAT_INVALID');
export const USERNAME_TO_SHORT = () => I18n.t('key_errorMsg_USERNAME_TO_SHORT');
export const USERNAME_TO_LONG = () => I18n.t('key_errorMsg_USERNAME_TO_LONG');
export const EMAIL_EXISTS = () => I18n.t('key_errorMsg_EMAIL_EXISTS');
export const EMAIL_DOES_NOT_EXISTS = () => I18n.t('key_errorMsg_EMAIL_DOES_NOT_EXISTS');
export const EMAIL_CANNOT_BE_BLANK = () => I18n.t('key_errorMsg_EMAIL_CANNOT_BE_BLANK');
export const EMAIL_FORMAT_INVALID = () => I18n.t('key_errorMsg_EMAIL_FORMAT_INVALID');
export const PASSWORD_CANNOT_BE_BLANK = () => I18n.t('key_errorMsg_PASSWORD_CANNOT_BE_BLANK');
export const PASSWORD_FORMAT_INVALID = () => I18n.t('key_errorMsg_PASSWORD_FORMAT_INVALID');
export const PASSWORDS_ARE_EQUAL = () => I18n.t('key_errorMsg_PASSWORDS_ARE_EQUAL');
export const PASSWORDS_NOT_EQUAL = () => I18n.t('key_errorMsg_PASSWORDS_NOT_EQUAL');
export const PASSWORD_NOT_VALID = () => I18n.t('key_errorMsg_PASSWORD_NOT_VALID');
export const PASSWORD_TO_SHORT = () => I18n.t('key_errorMsg_PASSWORD_TO_SHORT');
export const ENVIRONMENT_NOT_EMPTY = () => I18n.t('key_errorMsg_ENVIRONMENT_NOT_EMPTY');
export const NAME_CANNOT_BE_BLANK = () => I18n.t('key_errorMsg_NAME_CANNOT_BE_BLANK');
export const NAME_FORMAT_INVALID = () => I18n.t('key_errorMsg_NAME_FORMAT_INVALID');
export const NAME_TO_SHORT = () => I18n.t('key_errorMsg_NAME_TO_SHORT');
export const NAME_TO_LONG = () => I18n.t('key_errorMsg_NAME_TO_LONG');
export const INVALID_REDIRECT_URI = () => I18n.t('key_errorMsg_INVALID_REDIRECT_URI');
export const INVALID_FIELD_VALUE = () => I18n.t('key_errorMsg_INVALID_FIELD_VALUE');
export const FIELD_CANNOT_BE_BLANK = () => I18n.t('key_errorMsg_FIELD_CANNOT_BE_BLANK');
export const INVALID_INCOMING_JSON = () => I18n.t('key_errorMsg_INVALID_INCOMING_JSON');
export const INTERNAL_SERVER_ERROR = () => I18n.t('key_errorMsg_INTERNAL_SERVER_ERROR');
export const UNDEFINED = () => I18n.t('key_errorMsg_UNDEFINED');
export const LOGIN_FAILED = () => I18n.t('key_errorMsg_LOGIN_FAILED');
export const PASSWORD_FAILED = () => I18n.t('key_errorMsg_PASSWORD_FAILED');
export const SIGNIN_FAILED = () => I18n.t('key_errorMsg_SIGNIN_FAILED');
export const SIGNUP_FAILED = () => I18n.t('key_errorMsg_SIGNUP_FAILED');
export const UNAUTHORIZED = () => I18n.t('key_errorMsg_UNAUTHORIZED');
export const FORBIDDEN = () => I18n.t('key_errorMsg_FORBIDDEN');
export const BAD_REQUEST = () => I18n.t('key_errorMsg_BAD_REQUEST');
export const NOT_FOUND = () => I18n.t('key_errorMsg_NOT_FOUND');
export const NOT_IMPLEMENTED = () => I18n.t('key_errorMsg_NOT_IMPLEMENTED');
export const NOT_ACCEPTABLE = () => I18n.t('key_errorMsg_NOT_ACCEPTABLE');
export const BAD_GATEWAY = () => I18n.t('key_errorMsg_BAD_GATEWAY');
export const PAYMENT_REQUIRED = () => I18n.t('key_errorMsg_PAYMENT_REQUIRED');
export const FIELD_TOTAL_LESS_FIELD_DAILY = () => I18n.t('key_errorMsg_FIELD_TOTAL_LESS_FIELD_DAILY');
export const INCORRECT_DATE_OR_TIME = () => I18n.t('key_errorMsg_INCORRECT_DATE_OR_TIME');
export const API_USERNAME_ALREADY_IN_USE = 'Username is already in use';
export const API_EMAIL_ALREADY_IN_USE = 'Email is already in use';

export default {
  USERNAME_CANNOT_BE_BLANK,
  USERNAME_OR_EMAIL_INVALID,
  USERNAME_EXISTS,
  USERNAME_FORMAT_INVALID,
  USERNAME_TO_SHORT,
  USERNAME_TO_LONG,
  EMAIL_EXISTS,
  PASSWORD_NOT_VALID,
  EMAIL_DOES_NOT_EXISTS,
  EMAIL_CANNOT_BE_BLANK,
  EMAIL_FORMAT_INVALID,
  PASSWORD_CANNOT_BE_BLANK,
  PASSWORD_FORMAT_INVALID,
  PASSWORDS_ARE_EQUAL,
  PASSWORDS_NOT_EQUAL,
  PASSWORD_TO_SHORT,
  ENVIRONMENT_NOT_EMPTY,
  NAME_CANNOT_BE_BLANK,
  NAME_FORMAT_INVALID,
  NAME_TO_SHORT,
  NAME_TO_LONG,
  INVALID_REDIRECT_URI,
  INVALID_FIELD_VALUE,
  FIELD_CANNOT_BE_BLANK,
  INVALID_INCOMING_JSON,
  INTERNAL_SERVER_ERROR,
  UNDEFINED,
  LOGIN_FAILED,
  SIGNUP_FAILED,
  UNAUTHORIZED,
  FORBIDDEN,
  NOT_FOUND,
  BAD_REQUEST,
  NOT_IMPLEMENTED,
  NOT_ACCEPTABLE,
  BAD_GATEWAY,
  PAYMENT_REQUIRED,
  PASSWORD_FAILED,
  FIELD_TOTAL_LESS_FIELD_DAILY,
  INCORRECT_DATE_OR_TIME,
};
