import {Account} from '../../api';

import {SESSION_CREATE_SUCCESS, ACCOUNT_CREATE_FAILURE} from '../../constants';

const SuccessAction = (payload) => ({
  type: SESSION_CREATE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: ACCOUNT_CREATE_FAILURE,
  payload
});

export default (username, email, passwordOrName, code) => (dispatch) => {

  let body = {username: username, email: email};
  body.password = passwordOrName

  return new Promise((resolve, reject) => {
    Account.Create(body, {code: code})
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};