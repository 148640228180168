import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {Preloader} from "../components";
import {getPartnerByID} from "../selectors";
import {I18n} from "../utils/i18n";
import {PartnerFormPasswordChange, PartnerFormProfileInfo} from "../containers";
import {Partner} from "../actions";

class PagePartnerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      changePasswordFormShow: false
    };
  }

  componentDidMount() {
    this.props.dispatch(Partner.Fetch(this.props.match.params.partner))
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/partners`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleInfoUpdate = (data) => {
    const {partner, dispatch} = this.props;
    return dispatch(Partner.ProfileUpdate(partner, {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
    }))
  }

  handlePasswordUpdate = (data) => {
    return this.props.dispatch(Partner.PasswordChange(this.props.match.params.partner, {
      password: data.password,
      validate: data.confirm
    }))
      .then(() => this.setState({changePasswordFormShow: false}))
  };

  handleOrganizationUpdate = (data) => {
    const {partner, dispatch} = this.props;
    return dispatch(Partner.OrganizationUpdate(partner, data))
  }

  handlerPasswordFormShow = () => {
    this.setState({changePasswordFormShow: true})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {partner} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/partners"}>{I18n.t('key_Partners')}</NavLink></li>
                <li className="breadcrumb-item active">{partner.email}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-4">
            <h4>{I18n.t('key_PagePartnerInfo_ProfileInfo')}</h4>
            <PartnerFormProfileInfo account={partner} submit={this.handleInfoUpdate}/>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-8">
            <h4>{I18n.t('key_PagePartnerInfo_Password')}</h4>
            {
              this.state.changePasswordFormShow
                ? <PartnerFormPasswordChange submit={this.handlePasswordUpdate}/>
                : <button className="btn btn-primary"
                          onClick={this.handlerPasswordFormShow}>{I18n.t('key_Change')}</button>
            }

          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    partner: getPartnerByID(state, props.match.params.partner),
  }
};

export default connect(mapStateToProps)(PagePartnerInfo);

