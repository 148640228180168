import React, {Component} from "react";
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

import "./style.css";


class FileUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      progress: {},
      uploading: false,
      uploaded: false,
      uploadURI: props.uploadURI,
      name: props.name,
    };

    this.fileInput = React.createRef();
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    let fileInput = document.querySelector(".input-file"),
      button = document.querySelector(".input-file-trigger")

    button.addEventListener("keydown", (e) => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        fileInput.focus();
      }
    });

    button.addEventListener("click", (e) => {
      fileInput.focus();
      return false;
    });
  }

  async uploadFile(e) {
    this.setState({progress: {}, uploading: true});
    const promises = [];
    promises.push(this.sendRequest(this.state.file));
    try {
      await Promise.all(promises);
      this.setState({uploaded: true, uploading: false});
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({uploaded: true, uploading: false});
    }
  }

  sendRequest = (file) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", e => {
        if (e.lengthComputable) {
          const p = {...this.state.progress};
          p[file.name] = {
            state: "pending",
            percentage: (e.loaded / e.total) * 100
          };
          this.setState({progress: p});
        }
      });

      req.upload.addEventListener("load", event => {
        const p = {...this.state.progress};
        p[file.name] = {state: "done", percentage: 100};
        this.setState({progress: p});
        resolve(req.response);
        if (typeof this.props.onMessage === "function") this.props.onMessage({
          id: this.state.name,
          file: file,
          status: "success",
        })
      });

      req.upload.addEventListener("error", event => {
        const p = {...this.state.progress};
        p[file.name] = {state: "error", percentage: 0};
        this.setState({progress: p});
        reject(req.response);
        if (typeof this.props.onMessage === "function") this.props.onMessage({
          id: this.state.name,
          file: file,
          status: "error",
          message: req.response,
        })
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", this.state.uploadURI);
      req.send(formData);
    });

  };

  renderProgress = (file) => {
    const progress = this.state.progress[file.name];

    if (this.state.uploading || this.state.uploaded) {
      return (
        <div className="progress-wrapper">
          <div
            className={`progress-bar ${progress && progress.state === "done"
              ? "success"
              : progress && progress.state === "error" ? "failed" : ""}`}>
            <div className="progress" style={{width: (!!progress) ? progress.percentage + "%" : 0 + "%"}}/>
          </div>
        </div>
      );
    }
  };

  handleChange = (e) => {
    e.preventDefault()
    this.setState({file: this.fileInput.current.files[0]});
  }

  removeFile = (e) => {
    this.setState({file: null, progress: {}, uploading: false, uploaded: false});
  }

  render() {
    return (
      <div className="uploader-file">
        {!this.state.file && (
          <div className="input-file-container">
            <input name={this.state.name} className="input-file" id={`id-${this.state.name}`} type="file"
                   onChange={this.handleChange} ref={this.fileInput}/>
            <label tabIndex="0" htmlFor={`id-${this.state.name}`} className="input-file-trigger">
              Select a file...
            </label>
          </div>
        )}

        {!!this.state.file && (
          <div>
            <p className="file-return">Selected file: <span>{this.state.file.name}</span> <i
              className="fas fa-times text-danger ml-2 cursor-pointer" onClick={this.removeFile}/>
            </p>
            {this.renderProgress(this.state.file)}
            {
              (!!this.state.file && !this.state.uploading && !this.state.uploaded) && (
                <button className="btn btn-primary"
                        disabled={!this.state.file || this.state.uploading}
                        onClick={this.uploadFile}>
                  {I18n.t('key_Upload')}
                </button>
              )
            }
          </div>
        )}
      </div>
    );
  }
}

FileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  onMessage: PropTypes.func,
  accept: PropTypes.string,
  uploadURI: PropTypes.string,
};

export default FileUploader;