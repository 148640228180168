import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Gravatar} from "../components";
import {getAccount, getAccounts, getSessionToken} from "../selectors";
import {Session} from "../actions"
import {I18n} from "../utils/i18n";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: true
    };
  }

  isActive = (path) => {
    return ((path === "/" && path === this.props.location.pathname) || (path !== "/" && this.props.location.pathname.startsWith(path))) ? "active" : ""
  };

  handleLogOut = () => {
    this.props.dispatch(Session.Remove())
  };

  render() {

    window.$(() => window.$('[data-toggle="tooltip"]').tooltip());

    const {account} = this.props;

    return (
      <nav className="navbar navbar-expand-lg fixed-top navbar-light">

        <NavLink to={"/"} className="navbar-brand">
          <img className="align-top d-none d-lg-block" height="40" src="/images/logo/logo-white.svg"
               alt="Mosfilm"/>
          <img className="align-top d-lg-none" height="40" src="/images/logo/logo-white.svg" alt="Mosfilm"/>
        </NavLink>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTop"
                aria-controls="navbarTop" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>

        <div className="collapse navbar-collapse" id="navbarTop">

          <ul className="navbar-nav mr-auto">
            <li className={`nav-item ${this.isActive("/")} ${this.isActive("/devices")}`}>
              <NavLink to="/devices" className="nav-link">
                {I18n.t('key_Header_TabItemDevicesMenu')}
              </NavLink>
            </li>

            <li className={`nav-item ${this.isActive("/partners")}`}>
              <NavLink to="/partners" className="nav-link">
                {I18n.t('key_Header_TabItemPartnerMenu')}
              </NavLink>
            </li>

            <li className={`nav-item ${this.isActive("/customers")}`}>
              <NavLink to="/customers" className="nav-link">
                {I18n.t('key_Header_TabItemCustomerMenu')}
              </NavLink>
            </li>

            <li className={`nav-item ${this.isActive("/settings")}`}>
              <NavLink to="/settings" className="nav-link">
                {I18n.t('key_Header_Dropdown_Settings')}
              </NavLink>
            </li>

          </ul>

          <ul className="nav navbar-nav">

            <li className="dropdown">

              <NavLink to="/" className="dropdown-toggle"
                       id="dropdownMenuAvatar"
                       data-toggle="dropdown"
                       role="button"
                       aria-haspopup="true"
                       aria-expanded="false">
                  <span className="mr-2" aria-labelledby="dropdownMenuAvatar">
                    <Gravatar imageClassName="rounded"
                              size={30}
                              email={account.email}
                              alt={account.email}/>&nbsp;&nbsp;{account.email}
                  </span>
                <span className="mt-2 caret"/>
              </NavLink>

              <ul className="dropdown-menu dropdown-menu-right">
                <li>
                  <NavLink to="/account" className="dropdown-item py-2">
                    <i className="fas fa-user" style={{width: "20px"}}/>&nbsp;{I18n.t('key_Header_Dropdown_Settings')}
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/signin" className="dropdown-item py-2" onClick={this.handleLogOut}>
                    <i className="fas fa-sign-out-alt" style={{width: "20px"}}/>&nbsp;{I18n.t('key_LogOut')}
                  </NavLink>
                </li>
              </ul>

            </li>
          </ul>

        </div>

      </nav>
    )
  }
}

const mapStateToProps = (state) => ({
  socket: state.socket,
  token: getSessionToken(state),
  account: getAccount(state),
  accounts: getAccounts(state),
});

export default withRouter(connect(mapStateToProps)(Header));