import {schema} from 'normalizr';

const deviceSchema = new schema.Entity('devices', {}, {
  idAttribute: data => data.meta.id,
  processStrategy: (data) => {
    data.id = data.meta.id;
    data.meta.updated = new Date(data.meta.updated * 1000);
    data.meta.created = new Date(data.meta.created * 1000);
    return data;
  }
});

export default deviceSchema;
