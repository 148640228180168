import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {Button} from "../containers";
import Validator from "../utils/validator";

import "antd/dist/antd.min.css";

class PartnerFormCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      error: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "first_name":
      case "last_name":
      case "email":
      case "phone":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    let errors = {};
    errors.first_name = Validator.NotEmptyField(data.first_name);
    errors.last_name = Validator.NotEmptyField(data.last_name);
    errors.phone = Validator.NotEmptyField(data.phone);
    errors.email = Validator.NotEmptyField(data.email);

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad first_name parameter") e.first_name = I18n.t('key_errorMsg_BadFirstNameParameter');
    if (errors.message === "Bad last_name parameter") e.last_name = I18n.t('key_errorMsg_BadLastNameParameter');
    if (errors.message === "Bad email parameter") e.email = I18n.t('key_errorMsg_BadEmailParameter');
    if (errors.message === "Email is already in use") e.email = I18n.t('key_errorMsg_EMAIL_EXISTS');
    if (errors.message === "Bad phone parameter") e.phone = I18n.t('key_errorMsg_BadPhoneParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        <fieldset className="mb-2">
          <legend>{I18n.t('key_PartnerFormGeneralCreate_ProfileInfo')}</legend>

          <div className="form-group">
            <label htmlFor="email">{I18n.t('key_PartnerFormGeneralCreate_EmailLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.email ? 'is-invalid' : ''}`} type="text"
                   id="email" name="email"
                   onChange={this.handleChange}
                   value={this.state.data.email}/>
            <div className="invalid-feedback">{this.state.error.email}</div>
          </div>

          <div className="form-group">
            <label htmlFor="first_name">{I18n.t('key_PartnerFormGeneralCreate_FirstNameLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.first_name ? 'is-invalid' : ''}`}
                   type="text" id="first_name" name="first_name"
                   onChange={this.handleChange}
                   value={this.state.data.first_name}/>
            <div className="invalid-feedback">{this.state.error.first_name}</div>
          </div>

          <div className="form-group">
            <label htmlFor="last_name">{I18n.t('key_PartnerFormGeneralCreate_LastNameLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.last_name ? 'is-invalid' : ''}`}
                   type="text" id="last_name" name="last_name"
                   onChange={this.handleChange}
                   value={this.state.data.last_name}/>
            <div className="invalid-feedback">{this.state.error.last_name}</div>
          </div>

          <div className="form-group">
            <label htmlFor="phone">{I18n.t('key_PartnerFormGeneralCreate_PhoneLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.phone ? 'is-invalid' : ''}`} type="text"
                   id="phone" name="phone"
                   onChange={this.handleChange}
                   value={this.state.data.phone}/>
            <div className="invalid-feedback">{this.state.error.phone}</div>
          </div>
        </fieldset>

        <div className="form-group text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Create')}
          />
        </div>

      </div>
    )
  }
}

PartnerFormCreate.propTypes = {
  name: PropTypes.string,
  account: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default PartnerFormCreate;
