import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Preloader} from "../../components";
import {getAccount} from "../../selectors";
import {AccountFormSmtpServer} from "../../containers";
import * as api from "../../api";
import {I18n} from "../../utils/i18n";


class SettingsPartialSmtpServer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      pending: true,
    };
  }

  componentDidMount() {
    api.Account.SmtpFetch()
      .then((res) => this.setState({pending: false, data: res}))
      .catch(() => this.setState({pending: false}))
  }

  handleSmtpServerUpdate = (data) => {
    return api.Account.SmtpUpdate(data)
  };

  render() {

    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <div>

        <div className="row mb-2">
          <div className="col-md-6">
            <h4>{I18n.t('key_SettingsPartialSmtpServer_Title')}</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <AccountFormSmtpServer data={this.state.data} submit={this.handleSmtpServerUpdate}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default withRouter(connect(mapStateToProps)(SettingsPartialSmtpServer));

