import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {BankSuggestionsByBic, Button, OrganizationSuggestionsByInn} from "../containers";
import Validator from "../utils/validator";

import "antd/dist/antd.min.css";

const dadata_key = window._env_.DADATA_KEY;

class CustomerFormCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        itn: "", // ИНН
        iec: "", // КПП
        psrn: "", // ОГРН
        bic: "", // БИK
        name: "", // Название организации
        actual_address: "", // Фактический адрес
        legal_address: "", // Юридический адрес
        ceo: "", // Гениральный директор
        bank: "", // Название банка
        current_account: "", // Расчетный свет
        corresponding_account: "", // Корреспондирующий счёт
      },
      error: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        itn: "",
        iec: "",
        psrn: "",
        bic: "",
        name: "",
        actual_address: "",
        legal_address: "",
        ceo: "",
        bank: "",
        current_account: "",
        corresponding_account: "",
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "first_name":
      case "last_name":
      case "email":
      case "phone":
      case  "iec":
      case  "psrn":
      case  "name":
      case  "actual_address":
      case  "legal_address":
      case  "bank":
      case  "ceo":
      case  "current_account":
      case  "corresponding_account":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      case  "bic":
        patch = {
          error: {
            ...this.state.error,
            bank: "",
            bic: "",
            corresponding_account: "",
          },
          data: {
            ...this.state.data,
            bank: value.name || "",
            bic: value.bic || "",
            corresponding_account: value.correspondent_account || "",
          }
        };
        break;
      case "itn":
        patch = {
          error: {
            ...this.state.error,
            itn: "",
            iec: "",
            psrn: "",
            legal_address: "",
            name: "",
            ceo: "",
          },
          data: {
            ...this.state.data,
            itn: value.itn || "",
            iec: value.iec || "",
            psrn: value.psrn || "",
            legal_address: value.legal_address || "",
            name: value.name || "",
            ceo: value.ceo || "",
          }
        };
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    let errors = {};
    errors.first_name = Validator.NotEmptyField(data.first_name);
    errors.last_name = Validator.NotEmptyField(data.last_name);
    errors.phone = Validator.NotEmptyField(data.phone);
    errors.email = Validator.NotEmptyField(data.email);
    errors.itn = Validator.NotEmptyField(data.itn);
    errors.iec = Validator.NotEmptyField(data.iec);
    errors.psrn = Validator.NotEmptyField(data.psrn);
    errors.bic = Validator.NotEmptyField(data.bic);
    errors.name = Validator.NotEmptyField(data.name);
    errors.actual_address = Validator.NotEmptyField(data.actual_address);
    errors.legal_address = Validator.NotEmptyField(data.legal_address);
    errors.ceo = Validator.NotEmptyField(data.ceo);
    errors.bank = Validator.NotEmptyField(data.bank);
    errors.current_account = Validator.NotEmptyField(data.current_account);
    errors.corresponding_account = Validator.NotEmptyField(data.corresponding_account);

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad first_name parameter") e.first_name = I18n.t('key_errorMsg_BadFirstNameParameter');
    if (errors.message === "Bad last_name parameter") e.last_name = I18n.t('key_errorMsg_BadLastNameParameter');
    if (errors.message === "Bad email parameter") e.email = I18n.t('key_errorMsg_BadEmailParameter');
    if (errors.message === "Email is already in use") e.email = I18n.t('key_errorMsg_EMAIL_EXISTS');
    if (errors.message === "Bad phone parameter") e.phone = I18n.t('key_errorMsg_BadPhoneParameter');
    if (errors.message === "Bad itn parameter") e.itn = I18n.t('key_errorMsg_BadItnParameter');
    if (errors.message === "Bad iec parameter") e.iec = I18n.t('key_errorMsg_BadIecParameter');
    if (errors.message === "Bad psrn parameter") e.psrn = I18n.t('key_errorMsg_BadPsrnParameter');
    if (errors.message === "Bad bic parameter") e.bic = I18n.t('key_errorMsg_BadBicParameter');
    if (errors.message === "Bad name parameter") e.name = I18n.t('key_errorMsg_BadNameParameter');
    if (errors.message === "Bad actual_address parameter") e.actual_address = I18n.t('key_errorMsg_BadActualAddressParameter');
    if (errors.message === "Bad legal_address parameter") e.legal_address = I18n.t('key_errorMsg_BadLegalAddressParameter');
    if (errors.message === "Bad ceo parameter") e.ceo = I18n.t('key_errorMsg_BadCeoParameter');
    if (errors.message === "Bad bank parameter") e.bank = I18n.t('key_errorMsg_BadBankParameter');
    if (errors.message === "Bad current_account parameter") e.current_account = I18n.t('key_errorMsg_BadCurrentAccountParameter');
    if (errors.message === "Bad corresponding_account parameter") e.corresponding_account = I18n.t('key_errorMsg_BadCorrespondingAccountParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    return (
      <div>

        <fieldset className="mb-2">
          <legend>{I18n.t('key_CustomerFormGeneralCreate_ProfileInfo')}</legend>

          <div className="form-group">
            <label htmlFor="email">{I18n.t('key_CustomerFormGeneralCreate_EmailLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.email ? 'is-invalid' : ''}`} type="text"
                   id="email" name="email"
                   onChange={this.handleChange}
                   value={this.state.data.email}/>
            <div className="invalid-feedback">{this.state.error.email}</div>
          </div>

          <div className="form-group">
            <label htmlFor="first_name">{I18n.t('key_CustomerFormGeneralCreate_FirstNameLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.first_name ? 'is-invalid' : ''}`}
                   type="text" id="first_name" name="first_name"
                   onChange={this.handleChange}
                   value={this.state.data.first_name}/>
            <div className="invalid-feedback">{this.state.error.first_name}</div>
          </div>

          <div className="form-group">
            <label htmlFor="last_name">{I18n.t('key_CustomerFormGeneralCreate_LastNameLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.last_name ? 'is-invalid' : ''}`}
                   type="text" id="last_name" name="last_name"
                   onChange={this.handleChange}
                   value={this.state.data.last_name}/>
            <div className="invalid-feedback">{this.state.error.last_name}</div>
          </div>

          <div className="form-group">
            <label htmlFor="phone">{I18n.t('key_CustomerFormGeneralCreate_PhoneLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.phone ? 'is-invalid' : ''}`} type="text"
                   id="phone" name="phone"
                   onChange={this.handleChange}
                   value={this.state.data.phone}/>
            <div className="invalid-feedback">{this.state.error.phone}</div>
          </div>
        </fieldset>

        <fieldset className="mb-2">
          <legend>{I18n.t('key_CustomerFormGeneralCreate_OrganizationInfo')}</legend>

          <div className="form-group">
            <label htmlFor="itn">{I18n.t('key_CustomerFormOrganizationCreate_ITNLabel')}</label>
            <OrganizationSuggestionsByInn id="itn" name="itn"
                                          className={`form-control form-control-sm ${this.state.error.itn ? 'is-invalid' : ''}`}
                                          token={dadata_key}
                                          error={this.state.error.itn}
                                          value={this.state.data.itn}
                                          onChange={this.handleChange}/>
          </div>

          <div className="form-group">
            <label htmlFor="iec">{I18n.t('key_CustomerFormOrganizationCreate_IECLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.iec ? 'is-invalid' : ''}`} type="text"
                   id="iec" name="iec"
                   onChange={this.handleChange}
                   value={this.state.data.iec}/>
            <div className="invalid-feedback">{this.state.error.iec}</div>
          </div>

          <div className="form-group">
            <label htmlFor="psrn">{I18n.t('key_CustomerFormOrganizationCreate_PSRNLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.psrn ? 'is-invalid' : ''}`} type="text"
                   id="psrn" name="psrn"
                   onChange={this.handleChange}
                   value={this.state.data.psrn}/>
            <div className="invalid-feedback">{this.state.error.psrn}</div>
          </div>

          <div className="form-group">
            <label htmlFor="name">{I18n.t('key_CustomerFormOrganizationCreate_NameLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.name ? 'is-invalid' : ''}`} type="text"
                   id="name" name="name"
                   onChange={this.handleChange}
                   value={this.state.data.name}/>
            <div className="invalid-feedback">{this.state.error.name}</div>
          </div>

          <div className="form-group">
            <label htmlFor="actual_address">{I18n.t('key_CustomerFormOrganizationCreate_ActualAddressLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.actual_address ? 'is-invalid' : ''}`}
                   type="text"
                   id="actual_address" name="actual_address"
                   onChange={this.handleChange}
                   value={this.state.data.actual_address}/>
            <div className="invalid-feedback">{this.state.error.actual_address}</div>
          </div>

          <div className="form-group">
            <label htmlFor="legal_address">{I18n.t('key_CustomerFormOrganizationCreate_LegalAddressLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.legal_address ? 'is-invalid' : ''}`}
                   type="text"
                   id="legal_address" name="legal_address"
                   onChange={this.handleChange}
                   value={this.state.data.legal_address}/>
            <div className="invalid-feedback">{this.state.error.legal_address}</div>
          </div>

          <div className="form-group">
            <label htmlFor="ceo">{I18n.t('key_CustomerFormOrganizationCreate_CEOLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.ceo ? 'is-invalid' : ''}`} type="text"
                   id="ceo" name="ceo"
                   onChange={this.handleChange}
                   value={this.state.data.ceo}/>
            <div className="invalid-feedback">{this.state.error.ceo}</div>
          </div>

        </fieldset>


        <fieldset className="mb-2">
          <legend>{I18n.t('key_CustomerFormGeneralCreate_BankInfo')}</legend>

          <div className="form-group">
            <label htmlFor="bic">{I18n.t('key_CustomerFormOrganizationCreate_BICLabel')}</label>
            <BankSuggestionsByBic id="bic" name="bic"
                                  className={`form-control form-control-sm ${this.state.error.bic ? 'is-invalid' : ''}`}
                                  error={this.state.error.bic}
                                  token={dadata_key}
                                  value={this.state.data.bic}
                                  onChange={this.handleChange}/>
          </div>

          <div className="form-group">
            <label htmlFor="bank">{I18n.t('key_CustomerFormOrganizationCreate_BankLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.bank ? 'is-invalid' : ''}`}
                   type="text" id="bank" name="bank"
                   onChange={this.handleChange}
                   value={this.state.data.bank}/>
            <div className="invalid-feedback">{this.state.error.bank}</div>
          </div>

          <div className="form-group">
            <label htmlFor="current_account">{I18n.t('key_CustomerFormOrganizationCreate_CurrentAccountLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.current_account ? 'is-invalid' : ''}`}
                   type="text" id="current_account" name="current_account"
                   onChange={this.handleChange}
                   value={this.state.data.current_account}/>
            <div className="invalid-feedback">{this.state.error.current_account}</div>
          </div>

          <div className="form-group">
            <label
              htmlFor="corresponding_account">{I18n.t('key_CustomerFormOrganizationCreate_CorrespondingAccountLabel')}</label>
            <input
              className={`form-control form-control-sm ${this.state.error.corresponding_account ? 'is-invalid' : ''}`}
              type="text" id="corresponding_account"
              name="corresponding_account"
              onChange={this.handleChange}
              value={this.state.data.corresponding_account}/>
            <div className="invalid-feedback">{this.state.error.corresponding_account}</div>
          </div>

        </fieldset>

        <div className="form-group text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Create')}
          />
        </div>

      </div>
    )
  }
}

CustomerFormCreate.propTypes = {
  name: PropTypes.string,
  account: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default CustomerFormCreate;
