import Create from "./CustomerCreate";
import Fetch from "./CustomerFetch";
import List from "./CustomerList";
import OrganizationUpdate from "./CustomerOrganizationUpdate";
import ProfileUpdate from "./CustomerProfileUpdate";
import PasswordChange from "./CustomerPasswordChange";
import Remove from "./CustomerRemove";
import AdList from "./CustomerAdList";
import AdFetch from "./CustomerAdFetch";
import AdUpdate from "./CustomerAdUpdate";
import AdRemove from "./CustomerAdRemove";
import MediaList from "./CustomerMediaList";
import MediaFetch from "./CustomerMediaFetch";
import MediaUpdate from "./CustomerMediaUpdate";
import MediaRemove from "./CustomerMediaRemove";

export default {
  Create,
  Fetch,
  List,
  Remove,
  OrganizationUpdate,
  ProfileUpdate,
  PasswordChange,
  AdList,
  AdFetch,
  AdUpdate,
  AdRemove,
  MediaList,
  MediaFetch,
  MediaUpdate,
  MediaRemove
}
