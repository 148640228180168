import {Account} from '../../api'
import {ACCOUNT_FETCH_FAILURE, ACCOUNT_FETCH_SUCCESS} from "../../constants"
import accountSchema from "../../schemas/account";
import {normalize} from "normalizr";

const SuccessAction = (payload) => ({
  type: ACCOUNT_FETCH_SUCCESS,
  payload: normalize([payload], [accountSchema])
});

const FailureAction = (payload) => ({
  type: ACCOUNT_FETCH_FAILURE,
  payload
});

// Parameter owner [optional]
export default () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.Fetch()
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
}
