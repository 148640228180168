import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

import {CustomerTransactionCardList, Preloader} from "../components";
import {getCustomerByID} from "../selectors";
import * as api from "../api";
import {I18n} from "../utils/i18n";
import {Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";
import {Customer} from "../actions";

class PageCustomerTransactionInfo extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      data: {
        amount: "",
        description: "",
      },
      error: {
        amount: "",
        description: "",
      },
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      items: [],
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Customer.Fetch(this.props.match.params.customer))
      .then((res) => {
        return api.Customer.TransactionList(res.username, {
          page: this.state.page,
          limit: this.state.limit
        })
          .then((res) => {
            const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

            if (res.page > pages) return this.handlePageChange(pages);

            this.setState({total: res.total, page: res.page, pages: pages, items: res.items, pending: false})
          })
          .catch(() => this.setState({pending: false}));
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handlePageChange = (page) => {
    this.props.dispatch(Customer.Fetch(this.props.match.params.customer))
      .then((res) => {
        api.Customer.TransactionList(res.username, {
          page: page,
          limit: this.state.limit
        })
          .then((res) => {
            const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

            if (res.page > pages) return this.handlePageChange(pages);

            let query = qs.parse(this.props.match.params.customer, this.props.history.location.search);
            query.page = res.page;
            this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

            this.setState({total: res.total, page: res.page, pages: pages, items: res.items, pending: false})
          })
          .catch(() => this.setState({pending: false}));
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "amount":
      case "description":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleTransactionCreate = () => {
    api.Customer.TransactionCreate(this.props.customer.username, {
      amount: parseFloat(this.state.data.amount.replace(",", ".")),
      description: this.state.data.description,
    })
      .then(() => {
        return api.Customer.TransactionList(this.props.customer.username, {
          page: 1,
          limit: 50
        })
          .then((res) => {
            const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

            if (res.page > pages) return this.handlePageChange(pages);

            let query = qs.parse(this.props.match.params.customer, this.props.history.location.search);
            query.page = res.page;
            this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

            this.setState({
              total: res.total,
              page: res.page,
              pages: pages,
              items: res.items,
              data: {amount: "", description: ""}
            })
          })
          .catch(() => this.setState({data: {amount: "", description: ""}}));
      })
      .catch((e) => console.error(e));
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {customer} = this.props;

    return (
      <section className="container content">


        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/customers"}>{I18n.t('key_Customers')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink
                  to={`/c/${customer.username}`}>{customer.organization.name}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_Transactions')}</li>
              </ol>
            </div>
          </div>
        </div>

        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <NavLink className="nav-link" to={`/c/${customer.id}/media`}>{I18n.t('key_Medias')}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`/c/${customer.id}/ad`}>{I18n.t('key_AdvertisingCampaigns')}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link active" to={`/c/${customer.id}/balance`}>{I18n.t('key_Transactions')}</NavLink>
          </li>
        </ul>

        <div className="row justify-content-end">
          <div className="col-6">
            <div className="alert alert-light text-right" role="alert">
              <div className="d-flex">
                <div className="mr-auto">
                  <span style={{fontSize: "24px"}}><strong>{I18n.t('key_CustomerBalance')}:</strong></span>
                </div>
                <div>
                  <span className={`badge badge-pill badge-${customer.balance > 0 ? "success" : "danger"}`}
                        style={{fontSize: "24px"}}>{customer.balance.toFixed(2)} {I18n.t('key_Rub')}.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">

            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <input
                  placeholder="Сумма"
                  className={`form-control form-control-sm ${this.state.error.amount ? 'is-invalid' : ''}`}
                  type="text"
                  id="amount" name="amount"
                  onChange={this.handleChange}
                  value={this.state.data.amount}/>
              </div>
              <input
                className={`form-control form-control-sm ${this.state.error.description ? 'is-invalid' : ''}`}
                placeholder="Обоснование"
                type="text"
                id="description" name="description"
                onChange={this.handleChange}
                value={this.state.data.description}/>
              <div className="input-group-append">
                <button type="submit" className="btn btn-sm btn-primary" onClick={this.handleTransactionCreate}>
                  {I18n.t('key_NewTransaction')}
                </button>
              </div>
            </div>

            <CustomerTransactionCardList items={this.state.items}/>

            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: getCustomerByID(state, props.match.params.customer),
  }
};

export default connect(mapStateToProps)(PageCustomerTransactionInfo);

