import React from "react";

import "./style.css"

const Preloader = () => (
  <div className="boxes">
    <div className="box">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
    <div className="box">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
    <div className="box">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
    <div className="box">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
);

export default Preloader;



