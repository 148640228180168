import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Preloader} from "../../components";
import {getAccount} from "../../selectors";
import {TagCardList, TagGroupFormCreate} from "../../containers";
import {I18n} from "../../utils/i18n";
import * as api from "../../api";

const list_to_tree = (list) => {

  let map = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      name: item.name,
      id: item.id,
      parentId: item.parent_id,
      created: new Date(item.created * 1000),
      updated: new Date(item.updated * 1000),
    }
  });

  for (i = 0; i < items.length; i++) {
    map[items[i].id] = i; // initialize the map
    items[i].children = []; // initialize the children
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      items[map[node.parentId]].children.push(node);
    } else {
      roots.push(node)
    }
  }

  return roots;
}

class SettingsPartialTagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      pending: true,
      showCreateGroupForm: false,
      tag: null,
      mediaplans: null,
      customers: null,
    };
  }

  componentDidMount() {
    api.Tag.List({all: true})
      .then((res) => {
        this.setState({pending: false, tags: list_to_tree(res)})
      })
      .catch(() => this.setState({pending: false}));
  }

  handleCreate = (data) => {
    return api.Tag.Create({name: data.name, parent: data.parent})
      .then((res) => {
        let tags = this.state.tags;
        let tag = {
          name: res.name,
          id: res.id,
          children: [],
          created: new Date(res.created * 1000),
          updated: new Date(res.updated * 1000),
        };
        if (!!data.parent) {
          tag.parentId = data.parent;
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === data.parent) {
              if (!tags[i].children) tags[i].children = [];
              tags[i].children.push(tag);
              break;
            }
          }
        } else {
          tags.push(tag);
        }
        this.setState({tags: tags, showCreateGroupForm: false})
      })
  };

  handleRemoveCancel = () => {
    this.setState({customers: null, mediaplans: null, file: null})
  }

  handleRemoveApply = () => {

    const data = this.state.tag;

    return api.Tag.Remove(data.id)
      .then(() => {
        let tags = this.state.tags;

        if (!!data.parent) {
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === data.parent) {
              for (let j = 0; j < tags[i].children.length; j++) {
                if (tags[i].children[j].id === data.id) {
                  tags[i].children.splice(j, 1);
                  break;
                }
              }
              break;
            }
          }
        } else {
          for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === data.id) {
              tags.splice(i, 1);
              break;
            }
          }
        }
        this.setState({customers: null, mediaplans: null, tag: null, tags: tags})
      })
  }

  handleRemove = (data) => {
    api.Tag.MediaPlanListByTag(data.id)
      .then((res) => {
        this.setState({customers: res.customers, mediaplans: res.mediaplans, tag: data})
      })
  };

  showCreateGroupForm = () => {
    this.setState({showCreateGroupForm: true})
  }

  handleCreateGroupCancel = () => {
    this.setState({showCreateGroupForm: false})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    if (!!this.state.mediaplans || !!this.state.customers) {
      return (
        <section className="container content">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div>
                {
                  this.state.tag.isGroup
                    ?
                    <h3>{I18n.t('key_SettingsPartialTagList_MediaRemoveGroupConfirm', {name: this.state.tag.name})}</h3>
                    : <h3>{I18n.t('key_SettingsPartialTagList_MediaRemoveTagConfirm', {name: this.state.tag.name})}</h3>
                }
              </div>

              {(this.state.mediaplans.length > 0)
                ? (
                  <div className="my-2 alert alert-warning">
                    <div className="d-flex">
                      <div><i className="fas fa-2x fa-exclamation-triangle text-warning"/></div>
                      <div className="flex-grow-1 pt-1">
                        {
                          this.state.tag.isGroup
                            ? <h5>{I18n.t('key_SettingsPartialTagList_GroupUseInMediaPlanList')}</h5>
                            : <h5>{I18n.t('key_SettingsPartialTagList_TagUseInMediaPlanList')}</h5>
                        }
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="my-2 alert alert-success">
                    <div className="d-flex">
                      <div><i className="fas fa-2x fa-check-circle text-success"/></div>
                      <div className="flex-grow-1 pt-1">
                        {
                          this.state.tag.isGroup
                            ? <h5>{I18n.t('key_SettingsPartialTagList_GroupNotUseInMediaPlanList')}</h5>
                            : <h5>{I18n.t('key_SettingsPartialTagList_TagNotUseInMediaPlanList')}</h5>
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          {
            (this.state.mediaplans.length > 0) && (
              <div className="row justify-content-center">
                <div className="col-12">
                  <ol>
                    {this.state.mediaplans.map((item, index) => (
                      <li key={index}>
                        <span>{I18n.t('key_SettingsPartialTagList_TagUseByMediaPlan', {name: item.meta.name, owner: item.meta.owner_email})}</span>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            )
          }

          <div className="row justify-content-center">
            <div className="col-12 text-center">
              {(this.state.customers.length > 0)
                ? (
                  <div className="my-2 alert alert-warning">
                    <div className="d-flex">
                      <div><i className="fas fa-2x fa-exclamation-triangle text-warning"/></div>
                      <div className="flex-grow-1 pt-1">
                        {
                          this.state.tag.isGroup
                            ? <h5>{I18n.t('key_SettingsPartialTagList_GroupUseInCustomerList')}</h5>
                            : <h5>{I18n.t('key_SettingsPartialTagList_TagUseInCustomerList')}</h5>
                        }
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="my-2 alert alert-success">
                    <div className="d-flex">
                      <div><i className="fas fa-2x fa-check-circle text-success"/></div>
                      <div className="flex-grow-1 pt-1">
                        {
                          this.state.tag.isGroup
                            ? <h5>{I18n.t('key_SettingsPartialTagList_GroupNotUseInCustomerList')}</h5>
                            : <h5>{I18n.t('key_SettingsPartialTagList_TagNotUseInCustomerList')}</h5>
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>

          {
            (this.state.customers.length > 0) && (
              <div className="row justify-content-center">
                <div className="col-12">
                  <ol>
                    {this.state.customers.map((item, index) => (
                      <li key={index}>
                        <span>{I18n.t('key_SettingsPartialTagList_TagUseByCustomer', {username: item.username, email: item.email})}</span>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            )
          }

          <div className="row justify-content-center mt-2">
            <div className="col-4">
              <div className="clearfix">
                <div className="float-left">
                  <button className="btn btn-sm btn-secondary"
                          onClick={this.handleRemoveCancel}>{I18n.t('key_Cancel')}</button>
                </div>
                <div className="float-right">
                  <button className="btn btn-sm btn-danger"
                          onClick={this.handleRemoveApply}>{I18n.t('key_Remove')}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

    if (this.state.showCreateGroupForm) {
      return (
        <div>
          <div className="row mb-4">
            <div className="col-md-6">
              <h4>{I18n.t('key_SettingsPartialTagList_AddTagGroupTitle')}</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TagGroupFormCreate submit={this.handleCreate} cancel={this.handleCreateGroupCancel}/>
            </div>
          </div>
        </div>
      )
    }

    const {tags} = this.state;

    return (
      <div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h4>{I18n.t('key_SettingsPartialTagList_ListTitle')}</h4>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={this.showCreateGroupForm}>
                {I18n.t('key_AddGroup')}
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="accordion" id="tags-group-accordion">
              {
                tags.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="clearfix">
                        <div id={`heading-${index}`} className="float-left">
                          <h2 className="mb-0">
                            <div className="btn btn-link btn-block text-left collapsed"
                                 data-toggle="collapse" data-target={`#collapse-${index}`}>
                              {index + 1}. {item.name}
                            </div>
                          </h2>
                        </div>
                        <div className="float-right">
                          <div className="dropdown-item text-danger cursor-pointer"
                               onClick={() => this.handleRemove({id: item.id, name: item.name, isGroup: true})}>
                            {I18n.t('key_Remove')}
                          </div>
                        </div>
                      </div>
                      <div id={`collapse-${index}`} className="collapse"
                           data-parent="#tags-group-accordion">
                        <div className="card-body">
                          <TagCardList list={item.children}
                                       onRemove={data => this.handleRemove({
                                         parent: item.id,
                                         id: data.id,
                                         name: data.name,
                                         isGroup: false,
                                       })}
                                       onCreate={name => this.handleCreate({parent: item.id, name: name})}/>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default withRouter(connect(mapStateToProps)(SettingsPartialTagList));

