import {Customer} from "../../api";
import {ADVERTISING_CAMPAIGN_UPDATE_FAILURE, ADVERTISING_CAMPAIGN_UPDATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/advertising_campaign";

const SuccessAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_UPDATE_SUCCESS,
  payload: normalize([payload], [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_UPDATE_FAILURE,
  payload
});

export default (owner, id, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Customer.AdUpdate(owner, id, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
