import React from "react";
import {I18n} from "../utils/i18n";

const PageNotFound = ({history}) => {
  const goBack = () => {
    history.push(`/`);
  };

  return (
    <section className="container content">

      <div className="text-center">
        <h3 className="display-4 mt-5">{I18n.t('key_PageNotFound_404PageNotFound')}</h3>

        <p className="m-5">{I18n.t('key_PageNotFound_WeAreSorry')}</p>

        <button onClick={goBack}
                className="btn btn-primary btn-lg text-white">{I18n.t('key_PageNotFound_GoToHOME')}</button>
      </div>

    </section>
  );
};


export default PageNotFound;