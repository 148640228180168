import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";

function range(start, stop) {
  if (arguments.length <= 1) {
    stop = start || 0;
    start = 0;
  }

  let length = Math.max(stop - start, 0);
  let idx = 0;
  let arr = new Array(length);

  while (idx < length) {
    arr[idx++] = start;
    start += 1;
  }

  return arr;
}

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.currentPage || 1
    };

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.currentPage || 1
    })
  };

  handleClick(page) {
    if (this.state.page === page) return;
    if (page > this.props.numPages || page < 1) return;
    this.setState({page: page});
    if (this.props.change) this.props.change(page);
  }

  displayCount() {
    return (this.props.numPages > this.props.maxPages) ? this.props.maxPages : this.props.numPages;
  }

  pageRange() {
    let displayCount = this.displayCount();
    let page = this.state.page;
    let idx = (page - 1) % displayCount;
    let start = page - idx;
    let remaining = this.props.numPages - page;
    if (page > displayCount && remaining < displayCount) {
      start = this.props.numPages - displayCount + 1;
    }
    return range(start, start + displayCount);
  }

  renderPage(n, i) {
    return (
      <li key={i} className={`page-item ${this.state.page === n ? 'active' : ''}`}
          onClick={this.handleClick.bind(this, n)}>
        <Link to={'#'} className="page-link" onClick={e => e.preventDefault()}>{n}</Link>
      </li>
    );
  }

  render() {
    let page = this.state.page;
    let prevClassName = page === 1 ? 'disabled' : '';
    let nextClassName = page >= this.props.numPages ? 'disabled' : '';

    return (
      <nav>
        <ul className="pagination">
          <li className={`page-item ${prevClassName}`} onClick={this.handleClick.bind(this, page - 1)}>
            <Link to={"#"} className="page-link" aria-label="previous" onClick={e => e.preventDefault()}>
              <span aria-hidden={true}>&laquo;</span>
              <span className="sr-only">{I18n.t('key_Pagination_Previous')}</span>
            </Link>
          </li>

          {this.pageRange().map(this.renderPage, this)}

          <li className={`page-item ${nextClassName}`} onClick={this.handleClick.bind(this, page + 1)}>
            <Link to={"#"} className="page-link" aria-label="next" onClick={e => e.preventDefault()}>
              <span aria-hidden={true}>&raquo;</span>
              <span className="sr-only">{I18n.t('key_Pagination_Next')}</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired
};

export default Paginator;