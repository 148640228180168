import {download, requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Customer {

  static Create(data) {
    let uri = [api_host, "a", "customer"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static List(opts) {
    let uri = [api_host, "a", "customer"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Fetch(customer) {
    let uri = [api_host, "a", "customer", customer].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static UpdateProfile(customer, data) {
    let uri = [api_host, "a", "customer", customer, "profile"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static UpdateOrganization(customer, data) {
    let uri = [api_host, "a", "customer", customer, "organization"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static PasswordChange(customer, data) {
    let uri = [api_host, "a", "customer", customer, "password"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static TransactionCreate(owner, data) {
    let uri = [api_host, "a", "customer", owner, "transaction"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static TransactionList(owner, opts) {
    let uri = [api_host, "a", "customer", owner, "transaction"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static AdList(owner, opts) {
    let uri = [api_host, "a", "customer", owner, "ac"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
      status: opts.status || '',
      filter: opts.filter || '',
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static AdFetch(customer, ad) {
    let uri = [api_host, "a", "customer", customer, "ac", ad].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static AdUpdate(customer, ad, data) {
    let uri = [api_host, "a", "customer", customer, "ac", ad].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static AdRemove(customer, ad) {
    let uri = [api_host, "a", "customer", customer, "ac", ad].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static AdTagsStatisticsList(customer, id) {
    let uri = [api_host, "a", "customer", customer, "ac", id, "statistic", "tags"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static AdTagsStatisticsExcelExport(customer, id, name) {
    let uri = [api_host, "a", "customer", customer, "ac", id, "statistic", "tags"].join("/");
    let headers = {}
    headers["Content-Type"] = "application/vnd.ms-excel";
    return download(uri, name + `.xlsx`, true, headers);
  }

  static MediaList(owner, opts) {
    let uri = [api_host, "a", "customer", owner, "media"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
      status: opts.status || '',
      filter: opts.filter || '',
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static MediaFetch(customer, media) {
    let uri = [api_host, "a", "customer", customer, 'media', media].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static MediaUpdate(customer, media, data) {
    let uri = [api_host, "a", "customer", customer, 'media', media].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static MediaCopy(customer, media, data) {
    let uri = [api_host, "a", "customer", customer, 'media', media].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static MediaSetPreset(customer, media, data) {
    let uri = [api_host, "a", "customer", customer, 'media', media, "preset"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static MediaRemove(customer, media) {
    let uri = [api_host, "a", "customer", customer, 'media', media].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static MediaStreamUrl(customer, media) {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "a", "customer", customer, "media", media, "stream"].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")
    return uri;
  }

  static MediaDownloadUrl(customer, media) {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "a", "customer", customer, "media", media, "download"].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")
    return uri;
  }

}

export default Customer
