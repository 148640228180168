import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getDeviceByID} from "../../selectors";

class DevicePartialSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {

  }


  render() {
    // const {device} = this.props;

    return (
      <div>

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.match.params.device),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialSchedule));

