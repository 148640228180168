import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";

const AdFormTagsStatisticsInfo = ({list}) => {
  return (
    <table className="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col" style={{textAlign: "center", width: "50%"}}>
          {I18n.t('key_AdFormTagsStatisticsInfo_TagName')}
        </th>
        <th scope="col" style={{textAlign: "center", width: "50%"}}>
          {I18n.t('key_AdFormTagsStatisticsInfo_AdImpressions')}
        </th>
      </tr>
      </thead>
      <tbody>

      {
        list.map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="align-middle" align="center" valign="middle">{item.tag_name}</td>
              <td className="align-middle" align="center" valign="middle">{item.ad_impressions}</td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

AdFormTagsStatisticsInfo.defaultProps = {
  list: [],
};

AdFormTagsStatisticsInfo.propTypes = {
  list: PropTypes.array,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(AdFormTagsStatisticsInfo);
