import React from "react"
import PropTypes from "prop-types";
import qs from "query-string";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getMediaFiles} from "../selectors";
import {Device} from "../actions";
import * as api from "../api";
import {Preloader} from "../components";
import {serialize} from "../utils/helpers";
import {DeviceMediaFileCardList, MultiFileUploader, Pagination} from "../containers";

import "antd/dist/antd.min.css";

const default_type = "image/png,image/bmp,image/tiff,image/jpeg,application/pdf,video/mp4,application/x-matroska,.mkv";

class DeviceMediaSettings extends React.Component {

  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 5,
      pages: 1,
      maxPages: 5,
      file: null,
      files: [],
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Device.FileList(this.props.device.meta.id, {
      page: this.state.page,
      limit: this.state.limit
    }))
      .then((res) => {

        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState({...this.state, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(Device.FileList(this.props.device.meta.id, {
      page: page,
      limit: this.state.limit,
      filter: this.state.filter,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({...this.state, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  };

  handleRemove = (file) => {
    this.props.dispatch(Device.FileRemove(this.props.device.meta.id, file.id))
  };

  handleEvent = () => {
    this.handlePageChange(this.state.page)
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {files, device} = this.props;

    return (
      <div>
        <DeviceMediaFileCardList files={files}
                                 device={device}
                                 onRemove={this.handleRemove}/>
        {
          (!!Object.keys(files).length) && (
            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          )
        }

        <div className="d-flex justify-content-center">
          <MultiFileUploader accept={default_type}
                             uploadURI={api.Device.FileContentUpload(this.props.device.meta.id)}
                             onMessage={this.handleEvent}
          />
        </div>
      </div>

    )
  }
}

DeviceMediaSettings.propTypes = {
  name: PropTypes.string,
  device: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state) => ({
  files: getMediaFiles(state),
});

export default withRouter(connect(mapStateToProps)(DeviceMediaSettings));
