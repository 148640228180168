import {
  FFMPEG_COMMAND_CREATE_FAILURE,
  FFMPEG_COMMAND_CREATE_SUCCESS,
  FFMPEG_COMMAND_FETCH_FAILURE,
  FFMPEG_COMMAND_FETCH_SUCCESS,
  FFMPEG_COMMAND_LIST_FETCH_FAILURE,
  FFMPEG_COMMAND_LIST_FETCH_SUCCESS,
  FFMPEG_COMMAND_REMOVE_FAILURE,
  FFMPEG_COMMAND_REMOVE_SUCCESS,
  FFMPEG_COMMAND_UPDATE_FAILURE,
  FFMPEG_COMMAND_UPDATE_SUCCESS,
} from "../constants";


export const ffmpegReducer = {
  [FFMPEG_COMMAND_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [FFMPEG_COMMAND_CREATE_FAILURE]: (state) => {
    return state;
  },
  [FFMPEG_COMMAND_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [FFMPEG_COMMAND_FETCH_FAILURE]: (state) => {
    return state;
  },
  [FFMPEG_COMMAND_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "ffmpeg_commands"]);
    return newState.mergeDeep(payload)
  },
  [FFMPEG_COMMAND_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [FFMPEG_COMMAND_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [FFMPEG_COMMAND_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [FFMPEG_COMMAND_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "ffmpeg_commands", payload.id]);
  },
  [FFMPEG_COMMAND_REMOVE_FAILURE]: (state) => {
    return state;
  },
};
