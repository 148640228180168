import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Customer {

  static Create(data) {
    let uri = [api_host, "a", "partner"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static List(opts) {
    let uri = [api_host, "a", "partner"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static Fetch(partner) {
    let uri = [api_host, "a", "partner", partner].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static UpdateProfile(partner, data) {
    let uri = [api_host, "a", "partner", partner, "profile"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static PasswordChange(partner, data) {
    let uri = [api_host, "a", "partner", partner, "password"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static MpList(owner, opts) {
    let uri = [api_host, "a", "partner", owner, "mp"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static MpFetch(partner, ad) {
    let uri = [api_host, "a", "partner", partner, "mp", ad].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static MpUpdate(partner, ad, data) {
    let uri = [api_host, "a", "partner", partner, "mp", ad].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static MpRemove(partner, ad) {
    let uri = [api_host, "a", "partner", partner, "mp", ad].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static MediaList(owner, opts) {
    let uri = [api_host, "a", "partner", owner, "media"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
      filter: opts.filter || '',
      status: opts.status || '',
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static MediaFetch(partner, media) {
    let uri = [api_host, "a", "partner", partner, 'media', media].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static MediaUpdate(partner, media, data) {
    let uri = [api_host, "a", "partner", partner, 'media', media].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static MediaCopy(customer, media, data) {
    let uri = [api_host, "a", "partner", customer, 'media', media].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static MediaSetPreset(customer, media, data) {
    let uri = [api_host, "a", "partner", customer, 'media', media, "preset"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static MediaRemove(partner, media) {
    let uri = [api_host, "a", "partner", partner, 'media', media].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static MediaStreamUrl(partner, media) {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "a", "partner", partner, "media", media, "stream"].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")
    return uri;
  }

  static MediaDownloadUrl(partner, media) {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "a", "partner", partner, "media", media, "download"].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")
    return uri;
  }

}

export default Customer
