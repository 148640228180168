import {Partner} from '../../api';

import {PARTNER_PASSWORD_CHANGE_FAILURE, PARTNER_PASSWORD_CHANGE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: PARTNER_PASSWORD_CHANGE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: PARTNER_PASSWORD_CHANGE_FAILURE,
  payload
});

export default (customer, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.PasswordChange(customer, data)
      .then(() => {
        dispatch(SuccessAction(customer));
        resolve(customer)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};