import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

const CustomerCardList = ({customers}) => {
  return (
    <table className="table table-hover">
      <thead>
      <tr>
        <th scope="col" style={{width: "200px"}}>{I18n.t('key_CustomerCardList_TableHeaderName')}</th>
        <th scope="col" style={{minWidth: "150px"}}>{I18n.t('key_CustomerCardList_TableHeaderOrganization')}</th>
        <th scope="col"
            style={{textAlign: "center", minWidth: "50px"}}>{I18n.t('key_CustomerCardList_TableHeaderMediaCounts')}</th>
        <th scope="col"
            style={{textAlign: "center", minWidth: "50px"}}>{I18n.t('key_CustomerCardList_TableHeaderADCounts')}</th>
        <th scope="col"
            style={{textAlign: "center", width: "150px"}}>{I18n.t('key_CustomerCardList_TableHeaderBalance')}</th>
      </tr>
      </thead>
      <tbody>

      {
        customers.sortDateDesc().map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="text-truncate align-middle">
                <small>{item.email}</small>
              </td>
              <td className="align-middle">
                <NavLink to={`/c/${item.id}`}>{item.organization.name}</NavLink>
              </td>
              <td align="center" className="align-middle">
                <NavLink to={`/c/${item.id}/media`}>{item.stats.medias_count || 0}</NavLink>
              </td>
              <td align="center" className="align-middle">
                <NavLink to={`/c/${item.id}/ad`}>{item.stats.advertising_campaigns_count || 0}</NavLink>
              </td>
              <td align="center" className="align-middle" style={{verticalAlign: "middle"}}>
                <NavLink to={`/c/${item.id}/balance`}>{item.balance || 0}</NavLink>
              </td>
            </tr>
          )
        })
      }

      </tbody>
    </table>
  )
}

CustomerCardList.defaultProps = {
  customers: {},
};

CustomerCardList.propTypes = {
  customers: PropTypes.object,
  onSelect: PropTypes.func,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(CustomerCardList);
