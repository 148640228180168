import React from "react";
import {connect} from "react-redux";
import {PartnerMpCardList, Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Partner} from "../actions";
import {getMediaPlans, getPartnerByID} from "../selectors";
import {Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";

class PagePartnerMpList extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {

    this.props.dispatch(Partner.Fetch(this.props.match.params.partner))
      .then(() => {
        return this.props.dispatch(Partner.MpList(this.props.match.params.partner, {
          page: this.state.page,
          limit: this.state.limit
        }))
          .then((res) => {
            const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

            if (res.page > pages) return this.handlePageChange(pages);

            this.setState({total: res.total, page: res.page, pages: pages, pending: false})
          })
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(Partner.MpList(this.props.match.params.partner, {
      page: page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  };

  handleRemove = (item) => {
    return this.props.dispatch(Partner.MpRemove(this.props.match.params.partner, item))
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {partner, media_plans} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/partners"}>{I18n.t('key_Partners')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={`/p/${partner.username}`}>{partner.email}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_MediaPlans')}</li>
              </ol>
            </div>
          </div>
        </div>

        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <NavLink className="nav-link" to={`/p/${partner.id}/media`}>{I18n.t('key_Medias')}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link active" to={`/p/${partner.id}/mp`}>{I18n.t('key_MediaPlans')}</NavLink>
          </li>
        </ul>

        <div className="card">
          <div className="card-body">
            <PartnerMpCardList partner={partner} items={media_plans.sortDateDesc()} onRemove={this.handleRemove} />

            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    partner: getPartnerByID(state, props.match.params.partner),
    media_plans: getMediaPlans(state),
  }
};

export default connect(mapStateToProps)(PagePartnerMpList);

