import {Device} from "../../api";
import {DEVICE_UPDATE_FAILURE, DEVICE_UPDATE_SUCCESS} from "../../constants";
import {normalize} from "normalizr";
import deviceSchema from "../../schemas/device";


const SuccessAction = (payload) => ({
  type: DEVICE_UPDATE_SUCCESS,
  payload: normalize([payload], [deviceSchema])
});

const FailureAction = (payload) => ({
  type: DEVICE_UPDATE_FAILURE,
  payload
});

export default (device, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Device.Update(device.id, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction({error}));
        reject(error);
      });
  });
};
