import React from "react";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import {Account, Session} from "../actions";
import {getSessionToken} from "../selectors";
import {AccountPartialRecovery, Layout} from "./Auth";
import {I18n} from "../utils/i18n";

class PageRecovery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      step: props.match.params.token ? 'password' : 'login'
    };
  }

  handlerAccountPasswordRecovery = (login) => {
    let dispatch = this.props.dispatch;
    return this.state.step === 'login'
      ? dispatch(Account.Password.Recovery(login))
        .then(() => this.setState({success: true}))
      : dispatch(Account.Password.Change(this.props.match.params.token, login))
        .then((res) => {
          this.props.dispatch(Session.Set((!!res && !!res.token) ? res.token : null))
          this.props.history.push(`/`);
        })
  };

  handleToLogin = () => this.props.history.push(`/signin`);

  render() {

    if (this.props.token) return <Redirect to="/"/>;

    return (
      <section className="lb-auth-page container-fluid vh-100">

        {
          (!this.state.success) && (
            <Layout actionBtnName="Back to Login" onAction={this.handleToLogin}
                    content={<AccountPartialRecovery step={this.state.step}
                                                     cancel={this.handleToLogin}
                                                     submit={this.handlerAccountPasswordRecovery}/>}/>
          )
        }

        {
          (!!this.state.success) && (
            <Layout actionBtnName="Back to Login" onAction={this.handleToLogin}
                    content={<div>
                      <div>
                        <h2 className="pb-4">{I18n.t('key_PageRecovery_PasswordRecovery')}</h2>
                        <div className="alert alert-primary text-center" role="alert">
                          {I18n.t('key_PageRecovery_MessageSent')}
                        </div>

                        <button className='btn btn-default'
                                onClick={this.handleToLogin}>
                          {I18n.t('key_Back')}
                        </button>

                      </div>
                    </div>}
            />
          )
        }

      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  token: getSessionToken(state)
});

export default connect(mapStateToProps)(PageRecovery);
