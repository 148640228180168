import {Partner} from '../../api';

import {PARTNER_CREATE_FAILURE, PARTNER_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import customerSchema from "../../schemas/partner";


const SuccessAction = (payload) => ({
  type: PARTNER_CREATE_SUCCESS,
  payload: normalize([payload], [customerSchema])
});

const FailureAction = (payload) => ({
  type: PARTNER_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.Create(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};
