const UTIF = require('utif');
const pdfjs = require('pdfjs-dist/es5/build/pdf');

export function fetchImageFromUrlToCanvas(uri, opts, cb) {
  let img = new Image();
  img.crossOrigin = "*";
  img.onerror = (e) => {
    console.error(e)
    cb(null, "The image could not be loaded.")
  }
  img.addEventListener("load", () => {

    opts = opts || {};
    if (!opts.screenWidth) opts.screenWidth = window.innerWidth
    if (!opts.screenHeight) opts.screenHeight = window.screenHeight

    let cnv = document.createElement("canvas");
    cnv.width = opts.screenWidth;
    cnv.height = opts.screenHeight;

    let imgWidth = img.width;
    let imgHeight = img.height;

    let cfg = resize(opts.screenWidth, opts.screenHeight, imgWidth, imgHeight)

    let ctx = cnv.getContext("2d");
    ctx.drawImage(img, cfg.positionX, cfg.positionY, cfg.imageWidth, cfg.imageHeight);

    cb(cnv)
  }, false);
  img.src = uri;
}

export function fetchTiffFromUrlToCanvas(uri, opts, cb) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', uri);
  xhr.responseType = 'arraybuffer';
  xhr.onerror = (e) => cb(null, "The tiff could not be loaded.")
  xhr.onload = (e) => {

    const ifds = UTIF.decode(e.target.response);
    UTIF.decodeImage(xhr.response, ifds[0])
    const rgba = UTIF.toRGBA8(ifds[0]);  // Uint8Array with RGBA pixels

    let imgWidth = ifds[0].width;
    let imgHeight = ifds[0].height;

    opts = opts || {};
    if (!opts.screenWidth) opts.screenWidth = window.innerWidth
    if (!opts.screenHeight) opts.screenHeight = window.screenHeight

    const tempCnv = document.createElement('canvas');
    tempCnv.width = imgWidth;
    tempCnv.height = imgHeight;

    const tempCtx = tempCnv.getContext('2d');
    const imageData = tempCtx.createImageData(imgWidth, imgHeight);

    for (let i = 0; i < rgba.length; i++) {
      imageData.data[i] = rgba[i];
    }

    tempCtx.putImageData(imageData, 0, 0, 0, 0, imgWidth, imgHeight);

    let cfg = resize(opts.screenWidth, opts.screenHeight, imgWidth, imgHeight)

    const cnv = document.createElement('canvas');
    cnv.width = opts.screenWidth;
    cnv.height = opts.screenHeight;

    const ctx = cnv.getContext('2d');

    ctx.drawImage(tempCnv, cfg.positionX, cfg.positionY, cfg.imageWidth, cfg.imageHeight);

    cb(cnv)
  }
  xhr.send();
}

export function fetchPDFFromUrlToCanvas(uri, opts, cb) {
  pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/es5/build/pdf.worker.entry');

  let loadingTask = pdfjs.getDocument(uri);

  loadingTask.promise.then(function getPdf(pdf) {

    return pdf.getPage(1).then(function getPage(page) {

      opts = opts || {};
      if (!opts.screenWidth) opts.screenWidth = window.innerWidth
      if (!opts.screenHeight) opts.screenHeight = window.screenHeight

      let viewport = page.getViewport({scale: 1.5});

      const tempCnv = document.createElement('canvas');
      tempCnv.width = viewport.width;
      tempCnv.height = viewport.height;

      let tempCtx = tempCnv.getContext('2d');
      let renderContext = {canvasContext: tempCtx, viewport: viewport};
      let renderTask = page.render(renderContext);

      return renderTask.promise.then(function () {

        const cnv = document.createElement('canvas');
        cnv.width = opts.screenWidth;
        cnv.height = opts.screenHeight;
        const ctx = cnv.getContext('2d');

        let cfg = resize(opts.screenWidth, opts.screenHeight, viewport.width, viewport.height)

        ctx.drawImage(tempCnv, cfg.positionX, cfg.positionY, cfg.imageWidth, cfg.imageHeight);

        cb(cnv)
      })

    });
  })
    .catch(e => cb(null, "The pdf could not be loaded."));

}

function resize(width, height, imageWidth, imageHeight) {

  let iw = imageWidth;
  let ih = imageHeight;
  let sw = 1;
  let sh = 1;

  if (imageHeight > height || imageWidth > width) {
    sh = height / imageHeight;
    sw = width / imageWidth;

    // Vertical
    if (imageHeight > imageWidth) {
      if (sh > sw) {
        sh = sw;
      } else {
        sw = sh;
      }
      ih = Math.round(imageHeight * sh);
      iw = Math.round(imageWidth * sw);
    } else {
      // Horizontal
      if (sw > sh) {
        sw = sh;
      } else {
        sh = sw;
      }
      iw = Math.round(imageWidth * sw);
      ih = Math.round(imageHeight * sh);
    }
  }

  return {
    imageWidth: iw,
    imageHeight: ih,
    scaleWidth: sw,
    scaleHeight: sh,
    positionX: Math.round((width - iw) / 2),
    positionY: Math.round((height - ih) / 2),
  }
}