import {Customer} from "../../api";
import {CUSTOMER_UPDATE_FAILURE, CUSTOMER_UPDATE_SUCCESS} from "../../constants";
import {normalize} from "normalizr";
import customerSchema from "../../schemas/customer";

const SuccessAction = (payload) => ({
  type: CUSTOMER_UPDATE_SUCCESS,
  payload: normalize([payload], [customerSchema])
});

const FailureAction = (payload) => ({
  type: CUSTOMER_UPDATE_FAILURE,
  payload
});

export default (customer, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Customer.UpdateProfile(customer.id, data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction({error}));
        reject(error);
      });
  });
};
