import {createSelector} from 'reselect';


const getSessionMap = (state) => {
  return state.data.getIn(['session']);
};

export const getSessionToken = createSelector(
  [getSessionMap],
  (item) => (!!item && typeof item.toJS === "function") ? item.toJS().token : ""
);
