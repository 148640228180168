import React from "react"
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {TimePicker} from "antd";
import moment from "moment";
import {Button} from "../containers";
import {parseTime} from "../utils/helpers";

import "antd/dist/antd.min.css";

const timeFormat = 'mm';

class SettingsFormUpdate extends React.Component {

  constructor(props) {
    super(props);
    const data = props.data || {};
    this.state = {
      data: {
        deviceUpdateInterval: (!!data.deviceUpdateInterval)
          ? parseTime(data.deviceUpdateInterval)
          : parseTime("00:00:00"),
      },
      error: {
        deviceUpdateInterval: "",
      },
      pending: false,
    };
  }

  handleDateChange = (name, value) => {
    let date = !!value ? value.toDate() : null;
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, date.getMinutes(), 0);
    this.setState({data: {...this.state.data, [name]: date}})
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};
    data.deviceUpdateInterval = data.deviceUpdateInterval.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })
    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad device_update_interval parameter") e.name = errors.message;

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || !this.state.data.deviceUpdateInterval
    )
  };

  render() {
    return (
      <div className="form-inline">
        <div className="form-group mr-3">
          <TimePicker format={timeFormat}
                      size="large"
                      className="form-control"
                      minuteStep={1}
                      showNow={false}
                      disabledMinutes={() => [0]}
                      suffixIcon={<span/>}
                      inputReadOnly={true}
                      allowClear={false}
                      hideDisabledOptions={true}
                      defaultValue={moment('01', timeFormat)}
                      value={moment(this.state.data.deviceUpdateInterval, timeFormat)}
                      onChange={val => this.handleDateChange("deviceUpdateInterval", val)}/>
        </div>
        <Button type='submit'
                style={{marginTop: "7px"}}
                className='btn btn-primary text-capitalize'
                disabled={this.handleDisabled()}
                submit={this.handleSubmit}
                main={I18n.t('key_Apply')}/>

      </div>
    )
  }
}

SettingsFormUpdate.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default SettingsFormUpdate;
