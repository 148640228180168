import {schema} from 'normalizr';

const ffmpegCommandSchema = new schema.Entity('ffmpeg_commands', {}, {
  idAttribute: data => data.id,
  processStrategy: (data) => {
    data.updated = new Date(data.updated * 1000);
    data.created = new Date(data.created * 1000);
    return data;
  }
});

export default ffmpegCommandSchema;
