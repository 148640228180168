import {LocalStorage} from "../utils/storage";
import {
  SESSION_CREATE_FAILURE,
  SESSION_CREATE_SUCCESS,
  SESSION_REMOVE_SUCCESS,
  SESSION_SET_SUCCESS
} from "../constants";

export const sessionReducer = {
  [SESSION_CREATE_SUCCESS]: (state, payload) => {
    LocalStorage().set("token", (!!payload && !!payload.token) ? payload.token : null);
    return state.mergeDeep({session: payload})
  },
  [SESSION_SET_SUCCESS]: (state, payload) => {
    return state.mergeDeep({session: payload})
  },
  [SESSION_CREATE_FAILURE]: (state) => {
    return state;
  },
  [SESSION_REMOVE_SUCCESS]: (state) => {
    LocalStorage().remove("token");
    LocalStorage().remove("acc");
    return state.remove("entities").remove("session")
  }
};