import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {Resource} from "../utils/helpers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ModalDialog} from "./index";
import {LocalStorage} from "../utils/storage";
import {fetchImageFromUrlToCanvas, fetchPDFFromUrlToCanvas, fetchTiffFromUrlToCanvas} from "../utils/content";
import {Image, Layer, Stage} from "react-konva";
import {Preloader} from "../components";

class DeviceMediaFileCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || {},
      pending: true
    };
  }

  componentDidMount() {

    if (!Object.keys(this.state.files).length) {
      this.setState({pending: false})
      return
    }

    let statics = 0
    Object.keys(this.state.files).forEach((key) => {
      let item = this.state.files[key];
      if (item.meta.type === "static") {
        ++statics;
        this.handleView(item.meta, (cb, err) => {
          --statics;
          if (!err) {
            item.src = (
              <Stage width={cb.width} height={cb.height}>
                <Layer>
                  <Image image={cb}/>
                </Layer>
              </Stage>
            )
          } else {
            item.src = <span>Bad file</span>;
          }

          this.setState((state) => {
            state.files[key] = item;
            return state
          })
          if (statics === 0) this.setState({pending: false});
        })
      } else {
        const api_host = window._env_.API_HOST;
        let uri = [api_host, "device", this.props.device.id, "media", this.state.files[key].id].join("/");
        uri += "?x-access-token=" + LocalStorage().get("token")
        item.src = uri
        this.setState((state) => {
          state.files[key] = item;
          return state
        })
        if (statics === 0) this.setState({pending: false});
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let keysState = Object.keys(this.state.files)

    keysState.forEach((key) => {
      if (!this.props.files[key]) {
        this.setState((state) => {
          delete (state.files[key])
          return state
        })
      }
    })

    let keysProps = Object.keys(this.props.files)
    keysProps.forEach((key) => {
      if (!this.state.files[key]) {
        this.setState((state) => {
          state.files[key] = this.props.files[key];
          return state
        })
      }
    })

  }

  handleRemove = (e, item) => {
    e.stopPropagation();
    this.props.onRemove(item);
  };

  handleView = (item, cb) => {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "device", this.props.device.id, "media", item.id].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")

    let opts = {
      screenWidth: 350,
      screenHeight: 350,
    }

    if (item.mime === "application/pdf") {
      fetchPDFFromUrlToCanvas(uri, opts, cb)
      return;
    }

    if (item.mime === "image/tiff") {
      fetchTiffFromUrlToCanvas(uri, opts, cb)
      return
    }

    fetchImageFromUrlToCanvas(uri, opts, cb)
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center">
        <Preloader/>
      </section>
    );

    return (
      <div className="files-list__table">
        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col" style={{width: "75px"}}>{I18n.t('key_MediaFileCardList_TableHeaderStatus')}</th>
            <th scope="col"
                style={{minWidth: "100%"}}>{I18n.t('key_MediaFileCardList_TableHeaderName')}</th>
            <th scope="col" style={{width: "50px"}}>{I18n.t('key_MediaFileCardList_TableHeaderSize')}</th>
            <th scope="col" style={{width: "50px"}}>{I18n.t('key_MediaFileCardList_TableHeaderUpdated')}</th>
            <th scope="col" style={{width: "10px"}}/>
            <th scope="col" style={{width: "10px"}}/>
          </tr>
          </thead>
          <tbody>

          {
            this.props.files.sortDateDesc().map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{verticalAlign: "middle"}}>
                    {(item.status.state === "error") &&
                    <span style={{width: "70px"}} className="badge badge-danger">{item.status.state}</span>}
                    {(item.status.state === "ready") &&
                    <span style={{width: "70px"}} className="badge badge-success">{item.status.state}</span>}
                    {(item.status.state === "uploading") &&
                    <span style={{width: "70px"}} className="badge badge-primary">{item.status.state}</span>}
                    {(item.status.state === "preparing") &&
                    <span style={{width: "70px"}} className="badge badge-primary">{item.status.state}</span>}
                    {(item.status.state === "deleting") &&
                    <span style={{width: "70px"}} className="badge badge-danger">{item.status.state}</span>}
                    {(item.status.state === "unknown") &&
                    <span style={{width: "70px"}} className="badge badge-warning">{item.status.state}</span>}
                  </td>
                  <td style={{verticalAlign: "middle", maxWidth: "300px"}}
                      className="text-truncate">{item.meta.name}</td>
                  <td style={{verticalAlign: "middle"}}>
                    <span className="badge badge-info"
                          style={{width: "80px"}}>{Resource.MemoryBytesToHumanSize(item.meta.size, 2)}</span>
                  </td>
                  <td style={{verticalAlign: "middle"}} className="text-center">
                    {new Date(item.meta.created).toLocaleDateString("ru-RU")}
                  </td>
                  <td style={{verticalAlign: "middle"}} className="text-center">
                    {item.status.state === "ready" && (
                      <ModalDialog name={`viewer${index}`}
                                   btnOpen={<i data-toggle="modal" data-target={`#viewer${index}`}
                                               className="fas fa-eye cursor-pointer"/>}
                                   btnCloseName={I18n.t('key_Close')}
                                   content={(() => {
                                     return (item.meta.type === "static") ? (
                                       <div className="d-flex justify-content-center">
                                         {this.state.files[item.id].src}
                                       </div>
                                     ) : (
                                       <video width="320" height="240" controls src={this.state.files[item.id].src}
                                              autoPlay={true} muted/>
                                     )
                                   })()}
                                   btnOkName={I18n.t('key_Ok')}
                      />
                    )}
                  </td>
                  <td style={{verticalAlign: "middle"}} onClick={e => e.stopPropagation()}>
                    {
                      (item.status.state !== "deleting") && (
                        <div className="btn-group text-center cursor-pointer">
                          <div style={{width: "20px"}} data-toggle="dropdown">
                            <i className="fas fa-ellipsis-v cursor-pointer px-1"/>
                          </div>
                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="dropdown-item text-danger cursor-pointer"
                                 onClick={e => this.handleRemove(e, item)}>
                              {I18n.t('key_Remove')}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </td>
                </tr>
              )
            })
          }

          </tbody>
        </table>

      </div>
    );
  }
}

DeviceMediaFileCardList.defaultProps = {
  files: {},
  device: {},
};

DeviceMediaFileCardList.propTypes = {
  device: PropTypes.object,
  files: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(DeviceMediaFileCardList));
