import React from "react";
import {connect} from "react-redux";
import {I18n} from "../../utils/i18n";
import {Preloader} from "../../components";
import {CustomerFormOrganizationInfo, CustomerFormPasswordChange, CustomerFormProfileInfo} from "../../containers";
import {Customer} from "../../actions";
import PropTypes from "prop-types";
import DeviceFormRemove from "../../containers/DeviceFormRemove";

class CustomerInfoPartialGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordFormShow: false
    };
  }

  handleInfoUpdate = (data) => {
    const {customer, dispatch} = this.props;
    return dispatch(Customer.ProfileUpdate(customer, {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
    }))
  }

  handlePasswordUpdate = (data) => {
    return this.props.dispatch(Customer.PasswordChange(this.props.customer.username, {
      password: data.password,
      validate: data.confirm
    }))
      .then(() => this.setState({changePasswordFormShow: false}))
  };

  handleOrganizationUpdate = (data) => {
    const {customer, dispatch} = this.props;
    return dispatch(Customer.OrganizationUpdate(customer, data))
  }

  handlerPasswordFormShow = () => {
    this.setState({changePasswordFormShow: true})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {customer} = this.props;

    return (
      <div className="row">
        <div className="col-12 mb-2">

          <div className="row mb-5">
            <div className="col-md-4">
              <h4>{I18n.t('key_PageCustomerList_ProfileInfo')}</h4>
              <CustomerFormProfileInfo account={customer} submit={this.handleInfoUpdate}/>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-8">
              <h4>{I18n.t('key_PageCustomerList_Password')}</h4>
              {
                this.state.changePasswordFormShow
                  ? <CustomerFormPasswordChange submit={this.handlePasswordUpdate}/>
                  : <button className="btn btn-primary"
                            onClick={this.handlerPasswordFormShow}>{I18n.t('key_Change')}</button>
              }

            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-8">
              <h4>{I18n.t('key_PageCustomerList_OrganizationInfo')}</h4>
              <CustomerFormOrganizationInfo account={customer} submit={this.handleOrganizationUpdate}/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

DeviceFormRemove.propTypes = {
  customer: PropTypes.object.isRequired,
};


const mapStateToProps = (state, props) => {
  return {
  }
};

export default connect(mapStateToProps)(CustomerInfoPartialGeneral);

