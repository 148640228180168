import {
  PARTNER_CREATE_FAILURE,
  PARTNER_CREATE_SUCCESS,
  PARTNER_FETCH_FAILURE,
  PARTNER_FETCH_SUCCESS,
  PARTNER_LIST_FETCH_FAILURE,
  PARTNER_LIST_FETCH_SUCCESS,
  PARTNER_UPDATE_FAILURE,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_REMOVE_FAILURE,
  PARTNER_REMOVE_SUCCESS,
  PARTNER_PASSWORD_CHANGE_FAILURE,
  PARTNER_PASSWORD_CHANGE_SUCCESS,
} from "../constants";


export const partnerReducer = {
  [PARTNER_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [PARTNER_CREATE_FAILURE]: (state) => {
    return state;
  },
  [PARTNER_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [PARTNER_FETCH_FAILURE]: (state) => {
    return state;
  },
  [PARTNER_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "partners"]);
    return newState.mergeDeep(payload)
  },
  [PARTNER_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [PARTNER_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [PARTNER_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [PARTNER_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "partners", payload.id]);
  },
  [PARTNER_REMOVE_FAILURE]: (state) => {
    return state;
  },
  [PARTNER_PASSWORD_CHANGE_SUCCESS]: (state) => {
    return state;
  },
  [PARTNER_PASSWORD_CHANGE_FAILURE]: (state) => {
    return state;
  },

};
