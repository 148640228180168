

import {
  SESSION_SET_SUCCESS
} from '../../constants';

const SuccessAction = (payload) => ({
  type: SESSION_SET_SUCCESS,
  payload
});

export default (token) => (dispatch) => {
  return dispatch(SuccessAction({token: token}));
};
