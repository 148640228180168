import React from "react";
import PropTypes from "prop-types";
import Validator from "../utils/validator";
import {I18n} from "../utils/i18n";
import {PASSWORDS_NOT_EQUAL} from "../utils/error";
import {Button} from '../containers'

class AccountFormPasswordRecovery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        login: '',
        password: '',
        confirm: ''
      },
      error: {
        notEqual: null
      },
      pending: false,
      step: props.step
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      error: Object.assign(this.state.error, {[name]: ''}),
      data: Object.assign(this.state.data, {[name]: value})
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data,
      error = this.state.error;
    if (this.state.step === 'login') {
      return Promise.resolve(this.props.submit(data.login))
        .then(this.handleSuccess, this.handleError)
    } else {
      if (data.password !== data.confirm) {
        error.notEqual = PASSWORDS_NOT_EQUAL(this.props.dictionaries, this.props.currentLanguage);
        this.setState({error: Object.assign(this.state.error, error)});
        return
      }
      return this.props.submit(data.password)
    }
  };

  handleDisabled = () => {
    if (this.state.step === 'login') {
      return (
        (!!Validator.LoginField(this.state.data.login) && !!Validator.EmailField(this.state.data.login)) ||
        this.state.pending
      )
    } else {
      return !!Validator.PasswordField(this.state.data.password) || !!Validator.PasswordField(this.state.data.confirm)
    }
  };

  handleSuccess = () => {
    this.setState({pending: false});
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.code === 404) e.form = 'Account not found';
    this.setState({error: {...this.state.error, e}, pending: false});
  };

  handleBack =() => this.props.cancel();

  render() {
    if (this.state.step === 'login') {
      return (
        <form>

          <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
            <h4 className="text-center text-danger">{this.state.error.form}</h4>
          </div>

          <div className="form-group">
            <label htmlFor="loginInput">{I18n.t('key_AccountFormPasswordRecovery_LabelEmail')}</label>
            <input id="loginInput"
                   type="text"
                   className={`form-control form-control-border ${this.state.error.email ? 'is-invalid' : ''}`}
                   name="login"
                   value={this.state.data.login}
                   onChange={this.handleChange}
            />
            <div className="invalid-feedback">{this.state.error.login}</div>
          </div>

          <div className="clearfix">
            <div className="float-left">
              <button type="button"
                      className='btn btn-default'
                      onClick={this.handleBack}
              >
                {I18n.t('key_Back')}
              </button>

            </div>
            <div className="float-right">
              <div className="form-group text-center">
                <Button className='btn btn-primary'
                        type="submit"
                        disabled={this.handleDisabled()}
                        submit={this.handleSubmit}
                        main={I18n.t('key_Send')}
                />
              </div>
            </div>
          </div>

        </form>
      )
    }

    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h4 className="text-center text-danger">{this.state.error.form}</h4>
        </div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormPasswordRecovery_LabelEnterNewPassword')}</label>
          <input id="passwordInput"
                 type="password"
                 className={`form-control form-control-border ${this.state.error.notEqual ? 'is-invalid' : ''}`}
                 name="password"
                 value={this.state.data.password}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.notEqual}</div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmInput">{I18n.t('key_AccountFormPasswordRecovery_LabelConfirmPassword')}</label>
          <input id="confirmInput"
                 type="password"
                 className={`form-control form-control-border ${this.state.error.notEqual ? 'is-invalid' : ''}`}
                 name='confirm'
                 value={this.state.data.confirm}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.notEqual}</div>
        </div>

        <div className="clearfix">
          <div className="float-left">
            <button type="button"
                    className='btn btn-default'
                    onClick={this.handleBack}
            >
              {I18n.t('key_Back')}
            </button>

          </div>
          <div className="float-right">
            <div className="form-group text-center">
              <Button className='btn btn-primary'
                      type="submit"
                      disabled={this.handleDisabled()}
                      submit={this.handleSubmit}
                      main={I18n.t('key_Recovery')}
              />
            </div>
          </div>
        </div>

      </form>
    )
  }
}

AccountFormPasswordRecovery.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired
};

export default AccountFormPasswordRecovery;
