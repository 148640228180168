import React from "react";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {AccountFormPasswordChange, AccountFormProfile} from "../containers";
import {Account, Session} from "../actions";
import * as api from "../api";
import {connect} from "react-redux";

class PageAccountSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      smtp: {},
    }
  }


  handleProfileUpdate = (data) => {
    return this.props.dispatch(Account.Profile.Update(data))
  };

  handlePasswordUpdate = (data) => {
    return this.props.dispatch(Account.Password.Update(data))
      .then((res) => {
        this.props.dispatch(Session.Set((!!res && !!res.token) ? res.token : null))
      })
  };

  handleSmtpServerUpdate = (data) => {
    return api.Account.SmtpUpdate(data)
  };

  render() {
    return (
      <section className="container content">

        <div className="subnav-panel">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
            <li className="breadcrumb-item active">{I18n.t('key_Profile')}</li>
          </ol>
        </div>

        <div className="row">
          <div className="col-4">
            <h4 className="text-success">{I18n.t('key_AccountPartialGeneral_Profile')}</h4>
            <span>{I18n.t('key_AccountPartialGeneral_YorEmailIsIdentity')}</span>
          </div>

          <div className="col-6">
            <AccountFormProfile submit={this.handleProfileUpdate}/>
          </div>
        </div>

        <div className="row">
          <div className="col-12 py-4">
            <hr/>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <h4 className="text-success">{I18n.t('key_AccountPartialGeneral_Password')}</h4>
            <span>{I18n.t('key_AccountPartialGeneral_ChangingPassword')}</span>
          </div>

          <div className="col-6">
            <AccountFormPasswordChange submit={this.handlePasswordUpdate}/>
          </div>
        </div>

        <div className="row">
          <div className="col-12 py-4">
            <hr/>
          </div>
        </div>

        {/*<div className="row">*/}
        {/*  <div className="col-12 py-4">*/}
        {/*    <hr/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="row">*/}
        {/*  <div className="col-4">*/}
        {/*    <h4 className="text-success">{I18n.t('key_AccountPartialGeneral_Language')}</h4>*/}
        {/*    <span>{I18n.t('key_AccountPartialGeneral_ChangeLanguage')}</span>*/}
        {/*  </div>*/}

        {/*  <div className="col-6">*/}
        {/*    <ChangeLocalization/>*/}
        {/*  </div>*/}
        {/*</div>*/}

      </section>
    )
  }
}

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(PageAccountSettings)