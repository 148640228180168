import React from "react";

import "./style.css"
import PropTypes from "prop-types";


class ModalDialog extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      placeholder: typeof props.placeholder === "string" ? props.placeholder : "",
      name: typeof props.name === "string" ? props.name : "modal",
      btnOpen: !!typeof props.btnOpen ? props.btnOpen : null,
      btnOpenName: typeof props.btnOpenName === "string" ? props.btnOpenName : "Open",
      btnOpenClassName: typeof props.btnOpenClassName === "string" ? props.btnOpenClassName : "btn btn-primary",
      btnCloseName: typeof props.btnCloseName === "string" ? props.btnCloseName : "Close",
      btnCloseClassName: typeof props.btnCloseClassName === "string" ? props.btnCloseClassName : "btn btn-secondary",
      btnOkName: typeof props.btnOkName === "string" ? props.btnOkName : "Ok",
      btnOkClassName: typeof props.btnOkClassName === "string" ? props.btnOkClassName : "btn btn-primary",
      className: typeof props.className === "string" ? props.className : "",
      modalClassName: typeof props.modalClassName === "string" ? props.modalClassName : "modal-dialog modal-dialog-centered",
      header: typeof props.header === "object" ? props.header : null,
      content: typeof props.content === "object" ? props.content : null,
      onOk: typeof props.onOk === "function" ? (e) => {
        const obj = props.onOk(e)
        if (obj instanceof Promise) {
          obj.then(
            () => {
              this.handleClose()
            },
            (error) => {
              console.error(error)
            }
          )
        } else {
          this.handleClose()
        }
      } : null,
      onCancel: typeof props.onCancel === "function" ? props.onCancel : null,
      disabled: typeof props.disabled === "boolean" ? props.disabled : false,
    };

  }

  componentWillReceiveProps(nextProps) {
    this.setState({content: nextProps.content, btnOpen: nextProps.btnOpen});
  };

  handleClose = () => {
    window.$(() => window.$(`#${this.state.name}`).modal('toggle'));
  }

  render() {
    return (
      <div className={`modal-dialog-container ${this.state.className}`}>

        {
          (!!this.state.btnOpen)
            ? <div>{this.state.btnOpen}</div>
            : (
              <button type="button" className={this.state.btnOpenClassName} data-toggle="modal"
                      data-target={`#${this.state.name}`}>
                {this.state.btnOpenName}
              </button>
            )
        }

        <div className="modal fade" ref={this.myRef} id={this.state.name} role="dialog"
             aria-hidden="true">
          <div className={this.state.modalClassName} role="document">
            <div className="modal-content">

              <div className="modal-header">
                {this.state.header}
              </div>

              <div className="modal-body">
                {this.state.content}
              </div>

              {
                (!!this.state.onOk)
                  ? (
                    <div className="modal-footer">
                      <div className="clearfix">
                        <div className="float-left">
                          <button type="button"
                                  className={this.state.btnCloseClassName}
                                  onClick={this.state.onCancel}
                                  data-dismiss="modal">{this.state.btnCloseName}</button>
                        </div>
                        <div className="float-right">
                          <button type="button"
                                  className={this.state.btnOkClassName}
                                  disabled={this.state.disabled}
                                  onClick={this.state.onOk}>{this.state.btnOkName}</button>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="modal-footer text-center">
                      <button type="button"
                              className={this.state.btnCloseClassName}
                              onClick={this.state.onCancel}
                              data-dismiss="modal">{this.state.btnCloseName}</button>
                    </div>
                  )
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

ModalDialog.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  btnCloseClassName: PropTypes.string,
  btnCloseName: PropTypes.string,
  btnOkClassName: PropTypes.string,
  btnOkName: PropTypes.string,
  className: PropTypes.string,
  modalWrapperClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  btnOpen: PropTypes.node,
  btnOpenName: PropTypes.string,
  btnOpenClassName: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  header: PropTypes.node,
  content: PropTypes.node,
  disabled: PropTypes.bool,
};

export default ModalDialog;
