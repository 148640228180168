import {schema} from 'normalizr';

const accountSchema = new schema.Entity('accounts', {}, {
  idAttribute: data => data.username,
  processStrategy: (data) => {
    data.id = data.username;
    return data;
  }
});

export default accountSchema;
