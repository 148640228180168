import {
  SYSTEM_TRIGGER_LIST_FETCH_FAILURE,
  SYSTEM_TRIGGER_LIST_FETCH_SUCCESS,
  SYSTEM_TRIGGER_UPDATE_FAILURE,
  SYSTEM_TRIGGER_UPDATE_SUCCESS,
} from "../constants";


export const systemTriggerReducer = {
  [SYSTEM_TRIGGER_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "system_triggers"]);
    return newState.mergeDeep(payload)
  },
  [SYSTEM_TRIGGER_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [SYSTEM_TRIGGER_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [SYSTEM_TRIGGER_UPDATE_FAILURE]: (state) => {
    return state;
  },
};
