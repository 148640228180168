import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

class Layout extends React.Component {

  render() {

    return (
      <section className="lb-auth-page container vh-100">

        <div className="row h-100 justify-content-center lb-auth-content">
          <div className="col-lg-6 col-md-10 col-12 align-self-center">
            {this.props.content}
          </div>
        </div>

        <ul className="nav fixed-bottom justify-content-center py-3 lb-auth-footer">
          <li className="nav-item">
            <a className="nav-link disabled" href="/">Mosfilm LLC 2019</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/" rel="noopener noreferrer"
               target="_blank">
              {I18n.t('key_TermsAndConditions')}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/" rel="noopener noreferrer"
               target="_blank">
              {I18n.t('key_PrivacyPolicy')}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/" rel="noopener noreferrer"
               target="_blank">
              {I18n.t('key_Help')}
            </a>
          </li>
        </ul>

      </section>
    );
  }
}


Layout.propTypes = {
  actionBtnName: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default Layout;
