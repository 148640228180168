import React from "react";
import {connect} from "react-redux";
import {Preloader} from "../components";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Customer, FFmpeg} from "../actions";
import {getCustomerByID, getFFmpegCommands, getMediaFileByID} from "../selectors";
import {Button, ModalDialog} from "../containers";
import {fetchImageFromUrlToCanvas, fetchPDFFromUrlToCanvas, fetchTiffFromUrlToCanvas} from "../utils/content";
import {Image, Layer, Stage} from "react-konva";
import * as api from "../api";
import {Resource} from "../utils/helpers";
import Validator from "../utils/validator";

const EXAMPLE = "Пример: ffmpeg -i {{.Source}} пользовательские аргументы {{.Output}}"

class PageCustomerMediaModify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      error: {
        content: "",
        name: "",
        command: "",
      },
      ffmegError: null,
      data: {
        id: "",
        name: "",
        command: "",
      },
      modified: null,
      copyError: "",
      modifiedName: null,
      modifiedSrc: null,
      pending: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(Customer.Fetch(this.props.match.params.customer)),
      this.props.dispatch(Customer.MediaFetch(this.props.match.params.customer, this.props.match.params.media)),
      this.props.dispatch(FFmpeg.CommandList())
    ])
      .then((res) => {
        const item = res[1];
        if (item.meta.type === "static") {
          this.handleView(item.meta, (cb, err) => {
            let src;
            if (!err) {
              src = (
                <Stage width={cb.width} height={cb.height}>
                  <Layer>
                    <Image image={cb}/>
                  </Layer>
                </Stage>
              )
            } else {
              src = <span>Bad file</span>;
            }
            this.setState({pending: false, src: src});
          })
        } else {
          let src = api.Customer.MediaStreamUrl(this.props.match.params.customer, this.props.match.params.media)
          this.setState({pending: false, src: src});
        }
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/c/${this.props.match.params.customer}/media`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleView = (item, cb) => {
    let src = api.Customer.MediaDownloadUrl(this.props.match.params.customer, item.id)
    let opts = {
      screenWidth: 480,
      screenHeight: 480,
    }

    if (item.mime === "application/pdf") {
      fetchPDFFromUrlToCanvas(src, opts, cb)
      return;
    }

    if (item.mime === "image/tiff") {
      fetchTiffFromUrlToCanvas(src, opts, cb)
      return
    }

    fetchImageFromUrlToCanvas(src, opts, cb)
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({
        data: {id: "", name: "", command: ""},
        error: {content: "", name: "", command: ""}
      },
    )

    switch (name) {
      case "data-id":
        const command = this.props.commands[value] ? this.props.commands[value].command : "";
        const name = this.props.commands[value] ? this.props.commands[value].name : "";
        this.setState({data: {...this.state.data, id: value, command: command, name: name}})
        break;
      case "data-command":
        this.setState({data: {...this.state.data, command: value}})
        break;
      case "data-name":
        this.setState({data: {...this.state.data, name: value}})
        break;
      case "file-name":
        this.setState({modifiedName: value})
        break;
    }
  };

  handleExecute = () => {
    this.setState({modified: null, modifiedSrc: null});
    const data = {command: this.state.data.command.trim()}

    let errors = {};
    data.command = data.command.trim()

    const regex = new RegExp('ffmpeg.*-i {{.Source}}.*{{.Output}}$');
    if (!regex.test(data.command)) {
      errors.command = "Не верный формат команды"
    }

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return Promise.reject()
      }
    }

    return api.Customer.MediaSetPreset(this.props.match.params.customer, this.props.match.params.media, data)
      .then(file => {
        if (file.meta.type === "static") {
          this.handleView(file.meta, (cb, err) => {
            let src;
            if (!err) {
              src = (
                <Stage width={cb.width} height={cb.height}>
                  <Layer>
                    <Image image={cb}/>
                  </Layer>
                </Stage>
              )
            } else {
              src = <span>Bad file</span>;
            }
            this.setState({modified: file, modifiedSrc: src});
          })
        } else {
          let src = api.Customer.MediaStreamUrl(this.props.match.params.customer, file.meta.id)
          this.setState({modified: file, modifiedSrc: src});
        }
      })
      .catch(e => {
        this.setState({ffmegError: e.message})
      })
  }

  handleSaveMedia = async () => {
    const data = {
      name: this.state.modifiedName,
    }
    try {
      await api.Customer.MediaCopy(this.props.match.params.customer, this.state.modified.meta.id, data)
      this.setState({modifiedName: null, modified: null, modifiedSrc: null});
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
      return Promise.resolve()
    } catch (e) {
      this.setState({copyError: e.message});
      return Promise.reject()
    }
  }

  handleSaveCommand = async () => {
    const data = {
      name: this.state.data.name,
      command: this.state.data.command,
    }

    let errors = {};
    errors.name = Validator.NotEmptyField(data.name);
    data.command = data.command.trim()

    const regex = new RegExp('ffmpeg.*-i {{.Source}}.*{{.Output}}$');
    if (!regex.test(data.command)) {
      errors.command = "Не верный формат команды"
    }

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return Promise.reject()
      }
    }

    return api.FFmpeg.CommandCreate(data).then((res) => {
      this.props.dispatch(FFmpeg.CommandList()).then(() => {
        this.setState({data: {...this.state.data, id: res.id, command: res.command, name: res.name}})
      })
      this.setState({command: null, error: {...this.state.error, command: null}});
    }).catch(e => {
      if (e.status === "Not Unique") {
        this.setState({error: {...this.state.error, name: I18n.t('key_errorMsg_NAME_ALREADY_EXISTS')}})
      } else {
        this.setState({error: {...this.state.error, command: e.message}})
      }

    })
  }

  handleDisabled = () => {
    return !this.state.data.command
  }

  handleError = (e) => {
    this.setState({error: {content: I18n.t('key_errorMsg_VideoNotSupportedInBrowser')}})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {customer, media, commands} = this.props;

    return (
      <section className="container-fluid content px-5">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/customers"}>{I18n.t('key_Customers')}</NavLink></li>
                <li className="breadcrumb-item">
                  <NavLink to={`/c/${customer.username}`}>{customer.organization.name}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/c/${customer.username}/media`}>{I18n.t('key_Medias')}</NavLink>
                </li>
                <li className="breadcrumb-item active">{media.meta.name}</li>
              </ol>
            </div>
          </div>
        </div>

        <div>
          <h3>{I18n.t('key_MediaEditor')}</h3>
        </div>

        <div className="row">
          <div className="col text-center border-right">
            <div><h5>{I18n.t('key_CurrentMediaSource')}</h5></div>
            <div>
              {
                (media.status.state === "ready")
                  ? (media.meta.type === "static")
                    ? <div className="d-flex justify-content-end">{this.state.src}</div>
                    : (
                      <div>
                        {
                          (!!this.state.error.content)
                            ? (
                              <div className="alert alert-danger text-center">
                                <div><h4>{this.state.error.content}</h4></div>
                                <div><i className="fa fa-sad-cry fa-4x"/></div>
                              </div>)
                            : (
                              <div className="d-flex justify-content-end">
                                <video width="100%" height="350" controls="controls" autoPlay={false}
                                       onError={this.handleError}
                                       muted
                                       playsInline
                                       disablePictureInPicture
                                       controlsList="nodownload">
                                  <source src={this.state.src}/>
                                  {I18n.t('key_errorMsg_VideoNotSupportedInBrowser')}
                                </video>
                              </div>
                            )
                        }
                      </div>
                    )
                  : <div className="alert alert-warning text-center">{I18n.t('key_errorMsg_VideoNotUploaded')}</div>
              }
            </div>
            <div className="mt-4 px-5">
              <MediaInfo media={media}/>
            </div>
          </div>

          <div className="col border-right">
            <div className="text-center"><h5>{I18n.t('key_Preset')}</h5></div>
            <div className="mt-3">
              {this.state.ffmegError && <p className="text-danger">{this.state.ffmegError}</p>}
              <div className="form-group">
                <select className="form-control form-control-sm"
                        id="data-id" name="data-id"
                        value={this.state.data.id}
                        onChange={this.handleChange}>
                  <option value="">Выбрать команду</option>
                  )
                  {(!!Object.keys(commands).length) && commands.sortDateAsc().map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>)
                  )}
                </select>
              </div>
            </div>
            <div>
              {!this.state.data.id && (
                <div>
                  <div className="form-group">
                    <label htmlFor="data-name">{I18n.t('key_CustomerMediaCardInfo_NameTitle')}</label>
                    <input type="text" id="data-name" name="data-name"
                           className={`form-control form-control-sm ${this.state.error?.name ? 'is-invalid' : ''}`}
                           value={this.state.data.name}
                           onChange={this.handleChange}/>
                    <small>*обязательно поле для сохранения команды</small>
                    <div className="invalid-feedback">{this.state.error?.name}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="bioInput">{I18n.t('key_PresetCommand')}</label>
                    <textarea rows={5}
                              id="data-command" name="data-command"
                              onChange={this.handleChange}
                              className={`form-control form-control-sm ${this.state.error?.command ? 'is-invalid' : ''}`}
                              value={this.state.data.command}/>
                    <div className="invalid-feedback">{this.state.error?.command}</div>
                  </div>
                </div>
              )}
              {!!this.state.data.id && (
                <div>
                  <div>
                    <label>{I18n.t('key_CustomerMediaCardInfo_NameTitle')}</label>
                  </div>
                  <div>
                    <small>
                      <code>{this.state.data.command}</code>
                    </small>
                  </div>
                </div>
              )}
              {!this.state.data.id && <small>{EXAMPLE}</small>}
              <div className="invalid-feedback">{this.state.error?.command}</div>
            </div>
            {!this.state.data.id && (
              <div className="d-flex justify-content-between mt-4">
                <div>
                  <Button type='submit'
                          className="btn btn-secondary"
                          disabled={!this.state.data.command || !this.state.data.name}
                          submit={this.handleSaveCommand}
                          main={I18n.t('key_Save')}
                  />
                </div>
                <div>
                  <Button type='submit'
                          className='btn btn-primary'
                          submit={this.handleExecute}
                          disabled={this.handleDisabled()}
                          main={I18n.t('key_Execute')}
                  />
                </div>
              </div>
            )}
            {!!this.state.data.id && (
              <div className="text-center mt-4">
                <Button type='submit'
                        className='btn btn-primary'
                        submit={this.handleExecute}
                        disabled={this.handleDisabled()}
                        main={I18n.t('key_Execute')}
                />
              </div>
            )}
          </div>

          <div className="col text-center">
            <div><h5>{I18n.t('key_ResultMediaSource')}</h5></div>
            <div>
              {this.state.modified && !this.state.modified.status.error &&
                (
                  (this.state.modified.status.state === "ready")
                    ? (this.state.modified.meta.type === "static")
                      ? <div className="d-flex justify-content-end">{this.state.modifiedSrc}</div>
                      : (
                        <div>
                          {
                            (!!this.state.ffmegError)
                              ? (
                                <div className="alert alert-danger text-center">
                                  <div><h4>{this.state.ffmegError}</h4></div>
                                  <div><i className="fa fa-sad-cry fa-4x"/></div>
                                </div>)
                              : (
                                <div className="d-flex justify-content-end">
                                  <video width="100%" height="350" controls="controls" autoPlay={false}
                                         onError={this.handleError}
                                         muted
                                         playsInline
                                         disablePictureInPicture
                                         controlsList="nodownload">
                                    <source src={this.state.modifiedSrc}/>
                                    {I18n.t('key_errorMsg_VideoNotSupportedInBrowser')}
                                  </video>
                                </div>
                              )
                          }
                        </div>
                      )
                    : <div className="alert alert-warning text-center">{I18n.t('key_errorMsg_VideoNotUploaded')}</div>
                )
              }
            </div>
            <div className="mt-4 px-5">
              {this.state.modified && !this.state.modified.status.error && (
                <div>
                  <div>
                    <MediaInfo media={this.state.modified}/>
                  </div>
                  <div>
                    <ModalDialog name="apply"
                                 header={<h3>{I18n.t('key_Save')}</h3>}
                                 btnOpen={<button className="btn btn-success"
                                                  data-toggle="modal" data-target="#apply">
                                   {I18n.t('key_Save')}
                                 </button>}
                                 btnOpenName={I18n.t('key_Apply')}
                                 btnOkClassName="btn btn-danger"
                                 btnCloseName={I18n.t('key_Close')}
                                 btnCloseClassName="btn btn-primary"
                                 btnOkName={I18n.t('key_Save')}
                                 onOk={this.handleSaveMedia}
                                 content={(
                                   <div className="form-group">
                                     <label htmlFor="fileName">{I18n.t('key_CustomerMediaCardInfo_NameTitle')}</label>
                                     <input type="text" id="fileName" name="file-name"
                                            className={`form-control form-control-sm ${this.state.copyError ? 'is-invalid' : ''}`}
                                            onChange={this.handleChange}/>
                                     <div className="invalid-feedback">{this.state.copyError}</div>
                                   </div>
                                 )}
                    />
                  </div>
                </div>
              )}
              {this.state.modified && !!this.state.modified.status.error && (
                <div>
                  {this.state.modified.status.error}
                  {!!this.state.modified.status.message && (
                    <ModalDialog name={`error-info`}
                                 header={<h3>{this.state.modified.status.error}</h3>}
                                 btnOpen={<button className="btn btn-info btn-sm"
                                                  data-toggle="modal" data-target={`#error-info`}>
                                   {I18n.t('key_ShowMore')}
                                 </button>}
                                 btnCloseName={I18n.t('key_Close')}
                                 btnCloseClassName="btn btn-primary"
                                 modalClassName={"modal-dialog modal-xl"}
                                 content={<div>
                                   <code className="text-left">
                                     {this.state.modified.status.message.split('\n').map((line, index) => (
                                         <pre key={index} style={{marginBottom: "1px", fontSize: "10px"}}>{line}</pre>
                                       )
                                     )}
                                   </code>
                                 </div>}
                    />
                  )}
                </div>
              )}
            </div>

          </div>
        </div>

      </section>
    )
  }
}

const MediaInfo = ({media}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_NameTitle')}</div>
        <div>{media.meta.name}</div>
      </div>
      <div className="d-flex justify-content-between align-medias-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_DurationTitle')}</div>
        <div>{media.meta.duration}</div>
      </div>
      <div className="d-flex justify-content-between align-medias-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_ScreenTitle')}</div>
        <div>{media.meta.resolution}</div>
      </div>
      <div className="d-flex justify-content-between align-medias-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_CodecTitle')}</div>
        <div>{media.meta.codec_name}</div>
      </div>
      <div className="d-flex justify-content-between align-medias-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_BitrateTitle')}</div>
        <div>{media.meta.bit_rate}</div>
      </div>
      <div className="d-flex justify-content-between align-medias-center">
        <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_SizeTitle')}</div>
        <div>{Resource.MemoryBytesToHumanSize(media.meta.size, 2)}</div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    customer: getCustomerByID(state, props.match.params.customer),
    media: getMediaFileByID(state, props.match.params.media),
    commands: getFFmpegCommands(state),
  }
};

export default connect(mapStateToProps)(PageCustomerMediaModify);

