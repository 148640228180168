import Fetch from "./AccountFetch";
import Create from "./AccountCreate";
import Profile from "./profile";
import Password from "./password";
import AccountCurrentSet from "./AccountCurrentSet"


export default {
  Fetch,
  Create,
  Profile,
  Password,
  AccountCurrentSet
}
