import {schema} from 'normalizr';

const partnerSchema = new schema.Entity('partners', {}, {
  idAttribute: data => data.username,
  processStrategy: (data) => {
    data.id = data.username;
    data.stats = data.stats || {};
    data.profile = data.profile || {};
    data.updated = new Date(data.updated * 1000);
    data.created = new Date(data.created * 1000);
    return data;
  }
});

export default partnerSchema;
