import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import moment from "moment";
import {Resource} from "../utils/helpers";
import {NavLink, withRouter} from "react-router-dom";
import {Button} from "./index";

const dateFormat = 'DD-MM-YYYY HH:mm';

class CustomerMediaCardInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        status: props.item.status.status || "created",
        orientation: props.item.spec.orientation || "horizontal",
      },
      error: {
        status: "",
        orientation: ""
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "status":
      case "orientation":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad status parameter") e.first_name = I18n.t('key_errorMsg_BadStatusParameter');
    if (errors.message === "Bad orientation parameter") e.first_name = I18n.t('key_errorMsg_BadOrientationParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  render() {
    const {customer, item} = this.props;

    return (
      <div>
        <ul className="list-group list-group-flush bg-transparent">
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_NameTitle')}</div>
            <div>{item.meta.name}&nbsp;&nbsp;<i className="fa fa-download cursor-pointer"
                                                onClick={this.props.handleDownload}/></div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_CreatedTitle')}</div>
            <div>{moment(item.meta.created).format(dateFormat)}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_SizeTitle')}</div>
            <div>{Resource.MemoryBytesToHumanSize(item.meta.size, 2)}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_CustomerTitle')}</div>
            <div><NavLink to={`/c/${customer.username}`}>{customer.organization.name}</NavLink></div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_CodecTitle')}</div>
            <div>{item.meta.codec_name}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_ScreenTitle')}</div>
            <div>{item.meta.resolution}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_BitrateTitle')}</div>
            <div>{item.meta.bit_rate}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_DurationTitle')}</div>
            <div>{item.meta.duration}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_StateTitle')}</div>
            <div>
              {(item.status.state === "error") &&
              <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaError')}</span>}
              {(item.status.state === "ready") &&
              <span style={{width: "70px"}} className="badge badge-success">{I18n.t('key_StatusMediaReady')}</span>}
              {(item.status.state === "uploading") &&
              <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaUploading')}</span>}
              {(item.status.state === "preparing") &&
              <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaPreparing')}</span>}
              {(item.status.state === "deleting") &&
              <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaDeleting')}</span>}
              {(item.status.state === "unknown") &&
              <span style={{width: "70px"}} className="badge badge-warning">{I18n.t('key_StatusMediaUnknown')}</span>}
            </div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_FormatTitle')}</div>
            <div>
              <select className="form-control form-control-sm" id="format"
                      style={{minWidth: "150px"}}
                      name="orientation"
                      disabled={item.status.state !== "ready"}
                      value={this.state.data.orientation}
                      onChange={this.handleChange}>
                <option value="horizontal">{I18n.t('key_CustomerMediaCardInfo_FormatHorizontal')}</option>
                <option value="vertical">{I18n.t('key_CustomerMediaCardInfo_FormatVertical')}</option>
              </select>
            </div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_CustomerMediaCardInfo_StatusTitle')}</div>
            <div>
              <select className="form-control form-control-sm" id="status"
                      style={{minWidth: "150px"}}
                      name="status"
                      disabled={item.status.state !== "ready"}
                      value={this.state.data.status}
                      onChange={this.handleChange}>
                <option value="created">{I18n.t('key_CustomerMediaCardInfo_StatusCreated')}</option>
                <option value="accepted">{I18n.t('key_CustomerMediaCardInfo_StatusAccepted')}</option>
                <option value="rejected">{I18n.t('key_CustomerMediaCardInfo_StatusRejected')}</option>
              </select>
            </div>
          </li>
        </ul>

        <div className="mt-5 text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  disabled={this.handleDisabled()}
                  submit={this.handleSubmit}
                  main={I18n.t('key_Apply')}
          />
        </div>
      </div>
    )
  }
}

CustomerMediaCardInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  handleDownload: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default withRouter(CustomerMediaCardInfo);
