import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"

import "./style.css"

class FileUploader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  handleFileRead = (e, name, value) => {
    let event = new Event(name);
    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: name, value: value},
    });
    this.props.onChange && this.props.onChange(event);
  };

  handleFileChosen = (e) => {
    const fileReader = new FileReader();
    const target = e.target;
    this.setState({name: target.name})
    fileReader.onloadend = (ev) => this.handleFileRead(ev, target.name, fileReader.result);
    fileReader.readAsText(target.files[0]);
  };

  render() {
    return (
      <div className="custom-file">
        <input type="file"
               name={this.props.name}
               className="custom-file-input"
               id={this.props.id}
               accept={this.props.accept}
               disabled={this.props.disabled}
               onChange={this.handleFileChosen}
               required/>
        <label className={`custom-file-label ${!!this.props.disabled ? "disabled": ""}`} htmlFor={this.props.id}>
          {this.state.name ? this.state.name : "Choose file"}
        </label>
      </div>
    )
  }
}

FileUploader.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  accept: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(FileUploader);
