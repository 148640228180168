import React from "react";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Button} from "./index";
import Validator from "../utils/validator";

class TagCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: ""
      },
      error: {
        name: "",
      }
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    let patch = {};
    switch (name) {
      case "name":
        patch = {data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: ''}};
        break;
      default:
    }

    this.setState(patch)
  };

  handleCreate = (e) => {
    e.stopPropagation();
    let data = {...this.state.data}
    let error = {};

    error.name = Validator.NotEmptyField(data.name);

    for (let key in error) {
      if (error[key] !== null && error[key] !== "") {
        this.setState({error: Object.assign(this.state.error, error)});
        return
      }
    }

    return Promise.resolve(this.props.onCreate(data.name))
      .then(this.handleSuccess, this.handleError)
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  handleSuccess = () => {
    this.setState({data: {...this.state.data, name: ""}, pending: false})
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.message === "Bad name parameter") e.name = I18n.t('key_errorMsg_BadNameParameter');
    if (error.message === "Name is already in use") e.name = I18n.t('key_errorMsg_NotUniqueNameParameter');
    this.setState({error: Object.assign({}, this.state.error, e), pending: false});
  };

  handleRemove = (e, item) => {
    e.stopPropagation();
    this.props.onRemove(item);
  };

  render() {
    const {list} = this.props;

    return (
      <div className="tags-list__table">

        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col" style={{width: "100%"}}>{I18n.t('key_TagCardList_TableHeaderName')}</th>
            <th scope="col"
                style={{minWidth: "200px"}}>{I18n.t('key_TagCardList_TableHeaderCreated')}</th>
            <th scope="col" style={{width: "10px"}}/>
          </tr>
          </thead>
          <tbody>

          {
            list.map((item, index) => {
              return (
                <tr key={index} className="cursor-pointer">
                  <td style={{verticalAlign: "middle"}}>
                    {item.name}
                  </td>
                  <td style={{verticalAlign: "middle"}}>
                    {new Date(item.created).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })}
                  </td>
                  <td style={{verticalAlign: "middle"}} onClick={e => e.stopPropagation()}>
                    <div className="btn-group text-center cursor-pointer">
                      <div style={{width: "20px"}} data-toggle="dropdown">
                        <i className="fas fa-ellipsis-v cursor-pointer px-1"/>
                      </div>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-item text-danger cursor-pointer"
                             onClick={e => this.handleRemove(e, item)}>
                          {I18n.t('key_Remove')}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>

        <div>
          <div className={`input-group ${this.state.error.name ? 'is-invalid' : ''}`}>
            <input name="name" type="text"
                   className={`form-control ${this.state.error.name ? 'is-invalid' : ''}`}
                   value={this.state.name}
                   onChange={this.handleChange}/>
            <div className="input-group-append">
              <Button className='btn btn-outline-secondary'
                      type='button'
                      disabled={this.handleDisabled()}
                      submit={this.handleCreate}
                      main={(I18n.t('key_Add'))}
              />
            </div>
          </div>
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>
      </div>
    );
  }
}

TagCardList.defaultProps = {
  list: [],
};

TagCardList.propTypes = {
  list: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(TagCardList));
