import React from "react"
import {AccountFormPasswordRecovery} from "../../containers"
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

export const AccountPartialRecovery = ({submit, cancel, step}) => {
  return (
    <div>
      <h2 className="pb-4 text-center">{I18n.t('AccountPartialRecovery_RecoveryPassword')}</h2>
      <AccountFormPasswordRecovery step={step}
                                   cancel={cancel}
                                   submit={submit}
      />
    </div>
  );
};

AccountPartialRecovery.propTypes = {
  step: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired
};

export default AccountPartialRecovery
