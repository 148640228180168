import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Preloader} from "../../components";
import {getAccount} from "../../selectors";
import {AccountFormTelegram} from "../../containers";
import * as api from "../../api";
import {I18n} from "../../utils/i18n";


class SettingsPartialTelegram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      pending: true,
    };
  }

  componentDidMount() {
    api.Account.TelegramFetch()
      .then((res) => this.setState({pending: false, data: res}))
      .catch(() => this.setState({pending: false}))
  }

  handleEnableChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.checked;

    api.Account.TelegramUpdate({enable: value})
    this.setState({data: {...this.state.data, [name]: value}})
  };

  handleTelegramUpdate = (data) => {
    return api.Account.TelegramUpdate(data)
  };

  render() {

    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <div>

        <div className="row mb-2">
          <div className="col-md-6">
            <h4>{I18n.t('key_SettingsPartialTelegram_Title')}</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12">

            <div className="custom-control custom-checkbox mb-2">
              <input type="checkbox" className="custom-control-input" id="telegram_enable" name="enable"
                     checked={this.state.data.enable}
                     onChange={this.handleEnableChange}/>
              <label className="custom-control-label"
                     htmlFor="telegram_enable">{I18n.t('key_AccountFormTelegram_Enable')}</label>
            </div>

            {
              (!!this.state.data.enable) &&
              <AccountFormTelegram data={this.state.data} submit={this.handleTelegramUpdate}/>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default withRouter(connect(mapStateToProps)(SettingsPartialTelegram));

