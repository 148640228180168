import React from "react";
import {connect} from "react-redux";
import {I18n} from "../../utils/i18n";
import {FileUploader, ModalDialog, SettingsFormUpdate} from "../../containers";
import {Preloader} from "../../components";
import * as api from "../../api";
import {Image, Layer, Stage} from "react-konva";
import {LocalStorage} from "../../utils/storage";
import {fetchImageFromUrlToCanvas, fetchPDFFromUrlToCanvas, fetchTiffFromUrlToCanvas} from "../../utils/content";

const default_type = "image/png,image/bmp,image/tiff,image/jpeg,application/pdf";

class SettingsPartialGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showUploader1: true,
      showUploader2: true,
      pending: true,
      error: {},
      image1: null,
      image2: null,
    };
  }

  componentDidMount() {
    api.System.Fetch()
      .then((system) => {

        if (!!system.default_content) {
          this.handleView(system.default_content, (cnv, err) => {
            this.setState({image1: this.getContent(cnv)})
          })
        }

        if (!!system.default_preloader_content) {
          this.handleView(system.default_preloader_content, (cnv, err) => {
            this.setState({image2: this.getContent(cnv)})
          })
        }

        this.setState({
          pending: false,
          data: {
            deviceUpdateInterval: system.device_update_interval,
            defaultContent: system.default_content,
            defaultPreloaderContent: system.default_preloader_content,
          },
          showUploader1: !system.default_content,
          showUploader2: !system.default_preloader_content
        })
      })
      .catch(() => this.setState({pending: false}));
  }

  handleUpdate = (data) => {
    return api.System.Update({device_update_interval: data.deviceUpdateInterval})
  };

  handleEvent = (event) => {
    if (event.state === "success") {
      api.System.Fetch()
        .then((system) => {

          this.setState({
              data: {
                deviceUpdateInterval: system.device_update_interval,
                defaultContent: system.default_content,
                defaultPreloaderContent: system.default_preloader_content,
              },
              showUploader1: !system.default_content,
              showUploader2: !system.default_preloader_content,
            }
          )
        })
        .catch(() => {
        });
    }
  }

  handleView = (item, cb) => {
    const api_host = window._env_.API_HOST;
    let uri = [api_host, "media", "file", item.id, "download"].join("/");
    uri += "?x-access-token=" + LocalStorage().get("token")

    let opts = {
      screenWidth: 350,
      screenHeight: 350,
    }

    if (item.mime === "application/pdf") {
      fetchPDFFromUrlToCanvas(uri, opts, cb)
      return;
    }

    if (item.mime === "image/tiff") {
      fetchTiffFromUrlToCanvas(uri, opts, cb)
      return
    }

    fetchImageFromUrlToCanvas(uri, opts, cb)
  };

  getContent = (src) => {
    return (
      <div className="d-flex justify-content-center">
        <Stage width={src.width} height={src.height}>
          <Layer>
            <Image image={src}/>
          </Layer>
        </Stage>
      </div>
    )
  }

  handleDefaultContentRemove = (e) => {
    e.preventDefault();
    return this.handleRemove("default")
  }

  handleDefaultPreviewContentRemove = (e) => {
    e.preventDefault();
    return this.handleRemove("preloader")
  }

  handleRemove = (type) => {
    if (type === "default") {
      return api.Media.FileDefaultRemove()
        .then(() => {
          this.setState({
              data: {...this.state.data, defaultContent: null},
              showUploader1: true,
              image1: null,
            }
          )
        })
    }
    if (type === "preloader") {
      return api.Media.FilePreloaderRemove()
        .then(() => {
          this.setState({
              data: {...this.state.data, defaultPreloaderContent: null},
              showUploader2: true,
              image2: null,
            }
          )
        })
    }
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <div className="row">
        <div className="col-12 mb-2">
          <div className="form-group">
            <h5>{I18n.t('key_SettingsPageInfo_TableUpdateContentTime')}</h5>
            <SettingsFormUpdate data={this.state.data} submit={this.handleUpdate}/>
          </div>
        </div>

        <div className="col-12 mb-2">
          <div className="form-group">
            <h5>{I18n.t('key_SettingsPageInfo_TableUpdateDefaultContent')}</h5>
            <div className="form-inline">
              {
                (!this.state.data.defaultContent && !!this.state.showUploader1)
                  ? (
                    <FileUploader name="uploader_one"
                                  accept={default_type}
                                  onMessage={this.handleEvent}
                                  uploadURI={api.Media.FileDefaultContentUploadURI()}/>
                  )
                  : (
                    <div className="d-flex">
                      <div>{this.state.data.defaultContent.filename}</div>
                      <div className="ml-2">
                        {
                          (this.state.image1 !== null) && (
                            <ModalDialog name="viewer1"
                                         btnOpen={<i data-toggle="modal" data-target="#viewer1"
                                                     className="fas fa-eye cursor-pointer"/>}
                                         btnCloseName={I18n.t('key_Close')}
                                         content={this.state.image1}
                                         btnOkName={I18n.t('key_Remove')}
                            />
                          )
                        }
                      </div>
                      <div className="ml-2">
                        <ModalDialog name="remove1"
                                     btnOpen={<i data-toggle="modal" data-target="#remove1"
                                                 className="fas fa-times text-danger cursor-pointer"/>}
                                     btnCloseName={I18n.t('key_Close')}
                                     onOk={this.handleDefaultContentRemove}
                                     content={<div
                                       className="text-center h5">{I18n.t('key_SettingsPageInfo_RemoveApproveQuestions')}</div>}
                                     btnOkClassName="btn btn-danger"
                                     btnOkName={I18n.t('key_Remove')}
                        />
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>

        <div className="col-12 mb-2">
          <div className="form-group">
            <h5>{I18n.t('key_SettingsPageInfo_TableUpdateDefaultContentForUpload')}</h5>
            <div className="form-inline">
              {
                (!this.state.data.defaultPreloaderContent && !!this.state.showUploader2)
                  ? (
                    <FileUploader name="uploader_two"
                                  accept={default_type}
                                  onMessage={this.handleEvent}
                                  uploadURI={api.Media.FileDefaultPreloaderContentUploadURI()}/>
                  )
                  : (
                    <div className="d-flex">
                      <div>{this.state.data.defaultPreloaderContent.filename}</div>
                      <div className="ml-2">
                        {
                          (this.state.image2 !== null) && (
                            <ModalDialog name="viewer2"
                                         btnOpen={<i data-toggle="modal" data-target="#viewer2"
                                                     className="fas fa-eye cursor-pointer"/>}
                                         btnCloseName={I18n.t('key_Close')}
                                         content={this.state.image2}
                                         btnOkName={I18n.t('key_Remove')}
                            />
                          )
                        }
                      </div>
                      <div className="ml-2">
                        <ModalDialog name="remove2"
                                     btnOpen={<i data-toggle="modal" data-target="#remove2"
                                                 className="fas fa-times text-danger cursor-pointer"/>}
                                     btnCloseName={I18n.t('key_Close')}
                                     onOk={this.handleDefaultPreviewContentRemove}
                                     content={<div
                                     className="text-center h5">{I18n.t('key_SettingsPageInfo_RemoveApproveQuestions')}</div>}
                                     btnOkClassName="btn btn-danger"
                                     btnOkName={I18n.t('key_Remove')}
                        />
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps)(SettingsPartialGeneral);

