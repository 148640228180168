import React from "react";
import {connect} from "react-redux";
import {Preloader} from "../components";
import {CustomerAdCardInfo} from "../containers";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Customer} from "../actions";
import {getAdvertisementCampaignByID, getCustomerByID} from "../selectors";
import {formatDate} from "../utils/helpers";

class PageCustomerAdList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(Customer.Fetch(this.props.match.params.customer)),
      this.props.dispatch(Customer.AdFetch(this.props.match.params.customer, this.props.match.params.ad)),
    ])
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/c/${this.props.match.params.customer}/ad`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleUpdate = (data) => {

    let spec = {};
    spec.file_id = data.file.id;
    spec.tags = data.tags;
    spec.status = data.status;
    spec.use_time = !data.allDay;
    spec.start = formatDate(data.start);
    spec.end = formatDate(data.end);

    spec.start_time = data.start_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    spec.end_time = data.end_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return this.props.dispatch(Customer.AdUpdate(this.props.match.params.customer, this.props.match.params.ad, spec))
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {customer, campaign} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/customers"}>{I18n.t('key_Customers')}</NavLink></li>
                <li className="breadcrumb-item">
                  <NavLink to={`/c/${customer.username}`}>{customer.organization.name}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/c/${customer.username}/ad`}>{I18n.t('key_AdvertisingCampaigns')}</NavLink>
                </li>
                <li className="breadcrumb-item active">{campaign.meta.name}</li>
              </ol>
            </div>
          </div>
        </div>

        <div>
          <CustomerAdCardInfo item={campaign}
                              customer={customer}
                              submit={this.handleUpdate}/>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: getCustomerByID(state, props.match.params.customer),
    campaign: getAdvertisementCampaignByID(state, props.match.params.ad),
  }
};

export default connect(mapStateToProps)(PageCustomerAdList);

