

import {Account} from '../../../api';
import {ACCOUNT_PROFILE_UPDATE_FAILURE, ACCOUNT_PROFILE_UPDATE_SUCCESS} from "../../../constants";


const SuccessAction = (payload) => ({
  type: ACCOUNT_PROFILE_UPDATE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: ACCOUNT_PROFILE_UPDATE_FAILURE,
  payload
});

export default (profile) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.ProfileUpdate(profile)
      .then(response => {
        dispatch(SuccessAction(profile));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
