import Create from "./PartnerCreate";
import Fetch from "./PartnerFetch";
import List from "./PartnerList";
import ProfileUpdate from "./PartnerProfileUpdate";
import PasswordChange from "./PartnerPasswordChange";
import Remove from "./PartnerRemove";
import MediaList from "./PartnerMediaList";
import MediaFetch from "./PartnerMediaFetch";
import MediaUpdate from "./PartnerMediaUpdate";
import MediaRemove from "./PartnerMediaRemove";
import MpList from "./PartnerMpList";
import MpFetch from "./PartnerMpFetch";
import MpUpdate from "./PartnerMpUpdate";
import MpRemove from "./PartnerMpRemove";

export default {
  Create,
  Fetch,
  List,
  Remove,
  ProfileUpdate,
  PasswordChange,
  MpList,
  MpFetch,
  MpUpdate,
  MpRemove,
  MediaList,
  MediaFetch,
  MediaUpdate,
  MediaRemove
}
