import {Vpn} from '../../api';
import {VPN_SERVER_REMOVE_FAILURE, VPN_SERVER_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: VPN_SERVER_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: VPN_SERVER_REMOVE_FAILURE,
  payload
});

export default (vpn) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Vpn.ServerRemove(vpn.id)
      .then(() => {
        dispatch(SuccessAction(vpn));
        resolve(vpn)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};