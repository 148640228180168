import {Customer} from "../../api";
import {ADVERTISING_CAMPAIGN_REMOVE_FAILURE, ADVERTISING_CAMPAIGN_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_REMOVE_FAILURE,
  payload
});

export default (owner, item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Customer.AdRemove(owner, item.id)
      .then(() => {
        dispatch(SuccessAction(item));
        resolve(item)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};