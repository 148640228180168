import {Partner} from "../../api";
import {PARTNER_FETCH_FAILURE, PARTNER_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import customerSchema from "../../schemas/partner";


const SuccessAction = (payload) => ({
  type: PARTNER_FETCH_SUCCESS,
  payload: normalize([payload], [customerSchema])
});

const FailureAction = (payload) => ({
  type: PARTNER_FETCH_FAILURE,
  payload
});

export default (partner) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.Fetch(partner)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
