import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";

class DeviceFormAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {items} = this.props;
    return (
      <form>
        {
          (items || []).map((item, index) => {
            return (
              <div key={index} className="form-check">
                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  {item.meta.serial}&nbsp;&nbsp;
                  <span className={`badge ${!!item.status.online ? "badge-success" : "badge-danger"}`}>
                       {item.status.online ? I18n.t('key_Online') : I18n.t('key_Offline')}
                      </span>
                </label>
              </div>
            )
          })
        }

        <div className="mt-5">
          <div className="clearfix">
            <div className="float-left">
              <button type="button"
                      className="btn btn-secondary"
                      onClick={this.props.onCancel}
                      data-dismiss="modal">
                {I18n.t('key_Cancel')}
              </button>
            </div>
            <div className="float-right">
              <button type="button"
                      className="btn btn-success"
                      onClick={this.props.onOk}>
                {I18n.t('key_Add')}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

DeviceFormAdd.propTypes = {
  items: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default DeviceFormAdd;