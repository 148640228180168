

import {Account} from '../../../api';
import {ACCOUNT_PROFILE_FETCH_FAILURE, ACCOUNT_PROFILE_FETCH_SUCCESS} from "../../../constants";

const SuccessAction = (payload) => ({
  type: ACCOUNT_PROFILE_FETCH_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: ACCOUNT_PROFILE_FETCH_FAILURE,
  payload
});

export default () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.ProfileFetch()
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
