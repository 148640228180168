import React from "react"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux"
import {Redirect} from 'react-router-dom';
import {Account, Session} from "../actions"
import {Layout, AccountPartialSignUp} from "./Auth";

class PageSignUp extends React.Component {

  handlerAccountCreate = (username, email, password) => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    return this.props.dispatch(Account.Create(username, email, password, code))
      .then((res) => {
        this.props.dispatch(Session.Set((!!res && !!res.token) ? res.token : null))
        this.props.history.push(`/`)
      })
  };

  handleToLogin = () => this.props.history.push(`/signin`);

  render() {

    if (this.props.token) return <Redirect to="/"/>;

    return (
      <section className="lb-auth-page container-fluid vh-100">
        <Layout actionBtnName="Back to Login" onAction={this.handleToLogin}
                content={<AccountPartialSignUp submit={this.handlerAccountCreate}/>}/>
      </section>
    );
  }
}

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(PageSignUp))
