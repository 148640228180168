import Error from "./error";

const EmailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/i;
const UsernameRegex = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/i;
const NameRegex = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/i;

class Validator {

  static LoginField(login) {
    switch (true) {
      case !login || !login.length:
        return Error.FIELD_CANNOT_BE_BLANK();
      case login.length < 4:
        return Error.USERNAME_TO_SHORT();
      case login.indexOf("@") > 0 && !EmailRegex.test(login):
        return Error.EMAIL_FORMAT_INVALID();
      case login.indexOf("@") === 0 && !UsernameRegex.test(login):
        return Error.USERNAME_FORMAT_INVALID();
      default:
        return null
    }
  }

  static UsernameField(username) {
    switch (true) {
      case !username || !username.length:
        return Error.FIELD_CANNOT_BE_BLANK();
      case username.length < 4:
        return Error.USERNAME_TO_SHORT();
      case username.length > 64:
        return Error.USERNAME_TO_LONG();
      case !UsernameRegex.test(username):
        return Error.USERNAME_FORMAT_INVALID();
      default:
        return null
    }
  }

  static EmailField(email) {
    switch (true) {
      case !email || !email.length:
        return Error.FIELD_CANNOT_BE_BLANK();
      case !EmailRegex.test(email):
        return Error.EMAIL_FORMAT_INVALID();
      default:
        return null
    }
  }

  static PasswordField(password) {
    switch (true) {
      case !password:
        return Error.PASSWORD_TO_SHORT();
      case password.length < 8:
        return Error.PASSWORD_TO_SHORT();
      default:
        return null
    }
  }

  static NameField(name) {
    switch (true) {
      case !name || !name.length:
        return Error.FIELD_CANNOT_BE_BLANK();
      case name.length < 3:
        return Error.NAME_TO_SHORT();
      case name.length > 64:
        return Error.NAME_TO_LONG();
      case !NameRegex.test(name):
        return Error.NAME_FORMAT_INVALID();
      default:
        return null
    }
  }

  static NotEmptyField(data) {
    switch (true) {
      case !data || !data.length:
        return Error.FIELD_CANNOT_BE_BLANK();
      default:
        return null
    }
  }
}

export default Validator