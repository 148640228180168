import {Account} from '../../../api';
import {ACCOUNT_PASSWORD_RECOVERY_FAILURE, ACCOUNT_PASSWORD_RECOVERY_SUCCESS} from '../../../constants';

const SendRecoveryPasswordEmailSuccessAction = (payload) => ({
  type: ACCOUNT_PASSWORD_RECOVERY_SUCCESS,
  payload
});

const SendRecoveryPasswordEmailFailureAction = (payload) => ({
  type: ACCOUNT_PASSWORD_RECOVERY_FAILURE,
  payload
});

export default (login) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.PasswordRecovery(login)
      .then(response => {
        dispatch(SendRecoveryPasswordEmailSuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(SendRecoveryPasswordEmailFailureAction(error));
        reject(error);
      });
  });
};