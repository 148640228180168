import React, {Component} from "react";
import Dropzone from "./Dropzone";
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";

import "./style.css";


class MultiFileUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      progress: {},
      uploaded: false,
      uploadURI: props.uploadURI,
    };

    this.uploadFiles = this.uploadFiles.bind(this);
  }

  onFilesAdded = (files) => {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  };

  async uploadFiles() {
    this.setState({progress: {}, uploading: true});
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      this.setState({uploaded: true, uploading: false});
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({uploaded: true, uploading: false});
    }
  }

  sendRequest = (file) => {

    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {

        if (event.lengthComputable) {
          const p = {...this.state.progress};
          p[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({progress: p});
        }
      });

      req.upload.addEventListener("load", event => {
        const p = {...this.state.progress};
        p[file.name] = {state: "done", percentage: 100};
        this.setState({progress: p});
        resolve(req.response);
        if (typeof this.props.onMessage === "function") this.props.onMessage({
          file: file,
          event: "success",
        })
      });

      req.upload.addEventListener("error", event => {
        const p = {...this.state.progress};
        p[file.name] = {state: "error", percentage: 0};
        this.setState({progress: p});
        reject(req.response);
        if (typeof this.props.onMessage === "function") this.props.onMessage({
          file: file,
          event: "error",
          message: req.response,
        })
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", this.state.uploadURI);
      req.send(formData);
    });

  };

  renderProgress = (file) => {
    const progress = this.state.progress[file.name];

    if (this.state.uploading || this.state.uploaded) {
      return (
        <div className="progress-wrapper">

          <div
            className={`progress-bar ${progress && progress.state === "done" ? "success" : progress && progress.state === "error" ? "failed" : ""}`}>
            <div className="progress" style={{width: progress ? progress.percentage + "%" : 0 + "%"}}/>
          </div>

          {(progress && progress.state === "done") && <i className="fas fa-check text-success ml-3"/>}
          {(progress && progress.state === "error") && <i className="fas fa-times text-danger ml-3"/>}

        </div>
      );
    }
  };

  renderActions() {
    return (this.state.uploaded)
      ? (
        <button className='btn btn-secondary'
                onClick={() => this.setState({files: [], uploading: false, uploaded: false})}>
          {I18n.t('key_Clear')}
        </button>
      )
      :
      (
        <button className="btn btn-primary"
                disabled={!this.state.files.length || this.state.uploading}
                onClick={this.uploadFiles}>
          {I18n.t('key_Upload')}
        </button>
      )
  }

  removeFile(e, index) {
    let files = this.state.files;
    files.splice(index, 1);
    this.setState({files: files, uploading: false, uploaded: false});
  }

  render() {
    return (
      <div>

        <div className="uploader-content text-center mb-4">
          <div className="mb-2">
            <Dropzone onFilesAdded={this.onFilesAdded}
                      limit={10}
                      accept={this.props.accept}
                      disabled={this.state.uploading || this.state.uploaded}/>
          </div>
          <p>{I18n.t('MultiFileUploader_DragAndDropDescription')}</p>
        </div>

        <div className="uploader-files">
          {this.state.files.map((file, index) => {
            return (
              <div key={file.name} className="uploader-file-row">
                <div className="clearfix">
                  <span className="float-left uploader-filename text-truncate">{file.name}</span>
                  <span className="float-right">
                    <i className="fas fa-trash cursor-pointer"
                       onClick={e => this.removeFile(e, index)}/>
                  </span>
                </div>
                {this.renderProgress(file)}
              </div>
            );
          })}
        </div>

        {(!!this.state.files.length) &&
        <div className="d-flex justify-content-center mt-2">{this.renderActions()}</div>}
      </div>
    );
  }
}

MultiFileUploader.propTypes = {
  onMessage: PropTypes.func,
  accept: PropTypes.string,
  uploadURI: PropTypes.string,
};

export default MultiFileUploader;
