import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import moment from "moment";
import {Resource} from "../utils/helpers";
import {NavLink, withRouter} from "react-router-dom";

const dateFormat = 'DD-MM-YYYY HH:mm';

class PartnerMediaCardInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      error: {},
      pending: false,
    };
  }

  render() {
    const {partner, item} = this.props;

    return (
      <div>
        <ul className="list-group list-group-flush bg-transparent">
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_NameTitle')}</div>
            <div>{item.meta.name}&nbsp;&nbsp;<i className="fa fa-download cursor-pointer"
                                                onClick={this.props.handleDownload}/></div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_CreatedTitle')}</div>
            <div>{moment(item.meta.created).format(dateFormat)}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_SizeTitle')}</div>
            <div>{Resource.MemoryBytesToHumanSize(item.meta.size, 2)}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_PartnerTitle')}</div>
            <div><NavLink to={`/p/${partner.username}`}>{partner.email}</NavLink></div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_CodecTitle')}</div>
            <div>{item.meta.codec_name}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_ScreenTitle')}</div>
            <div>{item.meta.resolution}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_BitrateTitle')}</div>
            <div>{item.meta.bit_rate}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_DurationTitle')}</div>
            <div>{item.meta.duration}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMediaCardInfo_StateTitle')}</div>
            <div>
              {(item.status.state === "error") &&
              <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaError')}</span>}
              {(item.status.state === "ready") &&
              <span style={{width: "70px"}} className="badge badge-success">{I18n.t('key_StatusMediaReady')}</span>}
              {(item.status.state === "uploading") &&
              <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaUploading')}</span>}
              {(item.status.state === "preparing") &&
              <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaPreparing')}</span>}
              {(item.status.state === "deleting") &&
              <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaDeleting')}</span>}
              {(item.status.state === "unknown") &&
              <span style={{width: "70px"}} className="badge badge-warning">{I18n.t('key_StatusMediaUnknown')}</span>}
            </div>
          </li>
        </ul>
      </div>
    )
  }
}

PartnerMediaCardInfo.propTypes = {
  partner: PropTypes.object.isRequired,
  handleDownload: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default withRouter(PartnerMediaCardInfo);
