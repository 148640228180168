import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Preloader} from "../../components";
import {getAccount} from "../../selectors";
import {Button, TagSelectableCardList} from "../../containers";
import {I18n} from "../../utils/i18n";
import * as api from "../../api";

const list_to_tree = (list) => {

  let map = {}, mapRoots = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      name: item.name,
      id: item.id,
      selected: (item.selected !== undefined) ? item.selected : false,
      parentId: item.parent_id,
      created: new Date(item.created * 1000),
      updated: new Date(item.updated * 1000),
    }
  });

  for (i = 0; i < items.length; i++) {
    let node = items[i];
    map[node.id] = i; // initialize the map
    if (node.parentId === null) mapRoots[node.id] = node; // initialize the map of roots
    node.children = []; // initialize the children
    items[i] = node;
  }

  // set root as shown if there are selected children
  for (i = 0; i < items.length; i++) {
    let node = items[i];
    if (node.parentId !== null && node.selected) mapRoots[node.parentId].show = true
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      node.selected = mapRoots[node.parentId].selected || node.selected;
      items[map[node.parentId]].children.push(node);
    } else {
      node.show = mapRoots[node.id].show || false;
      roots.push(node)
    }
  }

  return roots;
}

class CustomerInfoPartialTagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      pending: true,
      showCreateGroupForm: false,
      tag: null,
    };
  }

  componentDidMount() {
    api.Tag.ListByCustomer(this.props.customer.id)
      .then((res) => {
        this.setState({pending: false, tags: list_to_tree(res)})
      })
      .catch(() => this.setState({pending: false}));
  }

  handleChange = (e, index) => {
    let tags = this.state.tags;
    let parent = tags[index];
    parent.selected = e.target.checked;

    parent.children = parent.children.map(item => {
      item.selected = parent.selected;
      return item
    })

    this.setState({tags: tags})
  }

  handleSelect = (parentIndex, el) => {
    let tags = this.state.tags;
    let parent = tags[parentIndex];
    parent.children = parent.children.map(item => {
      item.selected = (item.id === el.id) ? el.selected : item.selected;
      return item
    })

    let count = 0;
    parent.children.forEach(item => {
      if (item.selected) count++;
    })
    parent.selected = count === parent.children.length && parent.children.length > 0;
    this.setState({tags: tags})
  }

  handleSubmit = () => {
    const tags = this.state.tags;
    let data = [];

    tags.forEach(item => {
      if (item.selected) data.push(item.id)
      item.children.forEach(tag => {
        if (!!tag.selected) data.push(tag.id);
      })
    })

    return Promise.resolve(api.Tag.UpdateByCustomer(this.props.customer.id, {tags: data}))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true, error: {}});
    setTimeout(() => this.setState({success: false}), 2000);
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.status === "Bad Request") e.form = error.message;

    this.setState({error: e, pending: false});
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {tags} = this.state;

    return (
      <div>
        <div className="row mb-4">
          <div className="col">
            <h4>{I18n.t('key_SettingsPartialTagList_ListTitle')}</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="accordion" id="tags-group-accordion">
              {
                tags.map((item, index) => {
                  return (
                    <div key={index}>

                      <div className="d-flex" id={`heading-${index}`}>
                        <div className="custom-control custom-checkbox" style={{paddingTop: "5px"}}>
                          <input type="checkbox" className="custom-control-input" id={`group-${index}`}
                                 checked={item.selected}
                                 onChange={e => this.handleChange(e, index)}/>
                          <label className="custom-control-label" htmlFor={`group-${index}`}/>
                        </div>
                        <div>
                          <h2 className="mb-0">
                            <div className="btn btn-link btn-block text-left collapsed"
                                 data-toggle="collapse" data-target={`#collapse-${index}`}>
                              {index + 1}. {item.name}
                            </div>
                          </h2>
                        </div>
                      </div>

                      <div id={`collapse-${index}`} className={`collapse ${item.show ? "show": ""}`}
                           data-parent="#tags-group-accordion">
                        <div className="card-body">
                          <TagSelectableCardList list={item.children}
                                                 onSelect={(el) => this.handleSelect(index, el)}/>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Button className='btn btn-primary'
                    type="submit"
                    submit={this.handleSubmit}
                    main={I18n.t('key_Apply')}
            />
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default withRouter(connect(mapStateToProps)(CustomerInfoPartialTagList));

