import {schema} from 'normalizr';

const systemTriggerSchema = new schema.Entity('system_triggers', {}, {
  idAttribute: data => data.id,
  processStrategy: (data) => {
    data.updated = new Date(data.updated * 1000);
    data.created = new Date(data.created * 1000);
    return data;
  }
});

export default systemTriggerSchema;
