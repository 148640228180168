import {
  MEDIA_PLAN_CREATE_FAILURE,
  MEDIA_PLAN_CREATE_SUCCESS,
  MEDIA_PLAN_FETCH_FAILURE,
  MEDIA_PLAN_FETCH_SUCCESS,
  MEDIA_PLAN_LIST_FETCH_FAILURE,
  MEDIA_PLAN_LIST_FETCH_SUCCESS,
  MEDIA_PLAN_UPDATE_FAILURE,
  MEDIA_PLAN_UPDATE_SUCCESS,
  MEDIA_PLAN_REMOVE_FAILURE,
  MEDIA_PLAN_REMOVE_SUCCESS,
} from "../constants";


export const mediaPlanReducer = {
  [MEDIA_PLAN_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [MEDIA_PLAN_CREATE_FAILURE]: (state) => {
    return state;
  },
  [MEDIA_PLAN_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [MEDIA_PLAN_FETCH_FAILURE]: (state) => {
    return state;
  },
  [MEDIA_PLAN_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "media_plans"]);
    return newState.mergeDeep(payload)
  },
  [MEDIA_PLAN_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [MEDIA_PLAN_UPDATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [MEDIA_PLAN_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [MEDIA_PLAN_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "media_plans", payload.id]);
  },
  [MEDIA_PLAN_REMOVE_FAILURE]: (state) => {
    return state;
  },

};
