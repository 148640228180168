import React from "react";
import {connect} from "react-redux";
import {LocalStorage} from "../utils/storage";
import {withRouter} from "react-router-dom";

class ChangeLocalization extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: props.currentLanguage === 'en-US' ? "English" : "Русский",
      currentLanguagesymb: props.currentLanguage
    };
  }

  handleLanguageChange = (e) => {
    const target = e.target;
    const value = target.value;

    if (value === 'en-US') {
      this.setState({
        currentLanguage: "English",
        currentLanguagesymb: 'en-US'
      }, () => this.props.switchLanguage('en-US'))
    }
    if (value === 'ru-RU') {
      this.setState({
        currentLanguage: "Русский",
        currentLanguagesymb: 'ru-RU'
      }, () => this.props.switchLanguage('ru-RU'))
    }
  };

  render() {
    return (
      <div className="form-group mt-2">
        <select className="form-control"
                name="lang"
                onChange={this.handleLanguageChange}
                value={this.state.currentLanguagesymb}>
          <option value={'ru-RU'} key={0}>{"Русский"}</option>
        </select>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: state.i18n.locale
});

const mapDispatchToProps = (dispatch) => ({
  dispatch, switchLanguage: (code) => {
    LocalStorage().set("lang", code);
    window.location.reload();
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeLocalization));
