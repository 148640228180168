import React from "react";
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {Button} from '../containers'

class DeviceFormRemove extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: ""
      },
      error: {},
      pressRemove: false,
      pending: false,
      success: false
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      error: Object.assign(this.state.error, {[name]: ""}),
      data: Object.assign(this.state.data, {[name]: value}),
      pending: false,
      success: false
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    return Promise.resolve(this.props.submit(this.props.device))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
  };

  handleError = (errors) => {
    let e = this.state.error;
    e.form = errors.message;
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return this.state.pending || (this.state.data.name !== this.props.device.meta.serial);
  };

  render() {
    return (
      <form>
        <div className="form-group">
          <label htmlFor="serialInput">{I18n.t('key_DeviceFormRemove_DeviceName')}</label>
          <input type="text" name="name" id="nameInput"
                 className={`form-control ${this.state.error.name ? "is-invalid" : ""}`}
                 placeholder={I18n.t('key_DeviceFormRemove_PleaseType')}
                 value={this.state.data.name}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.form}</div>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-danger'
                  disabled={this.handleDisabled()}
                  submit={this.handleSubmit}
                  main={I18n.t('key_Remove')}
          />
        </div>
      </form>
    )
  }
}

DeviceFormRemove.propTypes = {
  error: PropTypes.object,
  device: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default DeviceFormRemove;
