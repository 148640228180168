import {Account} from '../../../api';
import {ACCOUNT_PASSWORD_CHANGE_FAILURE, SESSION_CREATE_SUCCESS} from '../../../constants';


const SuccessAction = (payload) => ({
  type: SESSION_CREATE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: ACCOUNT_PASSWORD_CHANGE_FAILURE,
  payload
});

export default (token, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Account.PasswordChange(token, password)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
