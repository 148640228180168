import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {Preloader} from "../components";
import {getCustomerByID} from "../selectors";
import {I18n} from "../utils/i18n";
import {Customer} from "../actions";
import {CustomerInfoPartialGeneral, CustomerInfoPartialTagList} from "../layouts/Customer";
import qs from "query-string";
import {serialize} from "../utils/helpers";

class PageCustomerList extends React.Component {
  constructor(props) {
    super(props);
    let query = qs.parse(this.props.history.location.search);

    this.state = {
      tab: query.tab || "general",
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Customer.Fetch(this.props.match.params.customer))
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/customers`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleSelectTab = (e, tab) => {
    e.preventDefault();
    let query = qs.parse(this.props.history.location.search);
    query.tab = tab;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
    this.setState({tab: tab})
  }

  render() {

    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {customer} = this.props;

    return (
      <section className="container-flex content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/customers"}>{I18n.t('key_Customers')}</NavLink></li>
                <li className="breadcrumb-item active">{customer.email}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-md-2">
            <div className="nav flex-column nav-pills">
              <NavLink to="#" className={`nav-link ${this.state.tab === "general" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "general")}>
                {I18n.t('key_PageSettingsInfo_TabGeneral')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "tags" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "tags")}>
                {I18n.t('key_PageSettingsInfo_TabTags')}
              </NavLink>
            </div>
          </div>

          <div className="col-md-10">
            {this.state.tab === "general" && <CustomerInfoPartialGeneral customer={customer}/>}
            {this.state.tab === "tags" && <CustomerInfoPartialTagList customer={customer}/>}
          </div>

        </div>


      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: getCustomerByID(state, props.match.params.customer),
  }
};

export default connect(mapStateToProps)(PageCustomerList);
