import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import moment from "moment-timezone";

const dateFormat = 'DD-MM-YYYY HH:mm:ss';

const DeviceEventCardList = ({events}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover">
        <thead>
        <tr>
          <th scope="col" className="text-uppercase text-center"
              style={{width: "200px"}}>{I18n.t('key_DeviceEventCardList_TableHeaderDate')}</th>
          <th scope="col" className="text-uppercase  text-center"
              style={{width: "125px"}}>{I18n.t('key_DeviceEventCardList_TableHeaderType')}</th>
          <th scope="col" className="text-uppercase  text-center"
              style={{width: "auto"}}>{I18n.t('key_DeviceEventCardList_TableHeaderDescription')}</th>
        </tr>
        </thead>
        <tbody>
        {
          events.map((item, index) => {
            let date = moment(new Date(item.date))
            return (
              <tr key={index}>
                <td
                  style={{verticalAlign: "middle"}}>{item.timezone ? date.tz(item.timezone).format(dateFormat) : ""}</td>
                <td style={{verticalAlign: "middle"}}>{item.type}</td>
                <td style={{verticalAlign: "middle"}}>{item.message}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}

DeviceEventCardList.defaultProps = {
  events: [],
};

DeviceEventCardList.propTypes = {
  events: PropTypes.array,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(DeviceEventCardList);
