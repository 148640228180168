import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {DeviceEventCardList, Preloader} from "../../components";
import {serialize} from "../../utils/helpers";
import {Device} from "../../actions";
import {getDeviceByID} from "../../selectors";
import {I18n} from "../../utils/i18n";
import {DatePicker} from 'antd';
import moment from "moment";
import {Device as deviceAPI} from "../../api";

import 'antd/dist/antd.min.css';
import {Pagination} from "../../containers";
import qs from "query-string";

const {RangePicker} = DatePicker;

const dateFormat = 'DD-MM-YYYY';

class DevicePartialEvents extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      filter: {
        type: query.type || "",
        start_date: (!!query.start_date) ? moment(query.start_date, dateFormat).toDate() : new Date(),
        end_date: (!!query.end_date) ? moment(query.end_date, dateFormat).toDate() : new Date(),
        total: 0,
        page: (!!query.page) ? parseInt(query.page, 10) : 1,
        limit: (!!query.limit) ? parseInt(query.limit, 10) : 15,
        pages: 1,
        maxPages: 5,
      },
      data: {
        events: []
      },
      error: {},
      pending: false,
      eventsLoading: true,
    };

    this.ranges = {};
    this.ranges[I18n.t('key_PageDeviceInfo_FormDate_Today')] = [moment().startOf('day'), moment().endOf('day')];
    this.ranges[I18n.t('key_PageDeviceInfo_FormDate_ThisMonth')] = [moment().startOf('month'), moment().endOf('month')];
  }

  componentDidMount() {
    const {device} = this.props.match.params;
    Promise.all([
      this.props.dispatch(Device.Fetch(device)),
      this.loadEvents(device)
    ])
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) this.props.history.push(`/d`)
      });
  }

  handlePageChange = (page) => {
    const {device} = this.props.match.params;
    let query = qs.parse(this.props.history.location.search);
    query.page = page;

    this.setState(state => {
      state.filter.page = page;
      return state
    }, () => {
      this.loadEvents(device)
        .then(() => this.props.history.push({...this.props.location, search: "?" + serialize(query || {})}))
        .catch(() => this.setState({pending: false}));
    })

  };

  loadEvents = (device) => {
    this.setState({eventsLoading: true})
    let start = this.state.filter.start_date;
    start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
    let end = this.state.filter.end_date;
    end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);

    return deviceAPI.EventList(device, {
      page: this.state.filter.page,
      limit: this.state.filter.limit,
      start_date: start.getTime() / 1000,
      end_date: end.getTime() / 1000,
      type: this.state.filter.type,
    })
      .then((res) => {
        this.setState({eventsLoading: false})
        const pages = (res.total > this.state.filter.limit) ? Math.ceil(res.total / this.state.filter.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState(state => {
          state.filter.total = res.total;
          state.filter.page = res.page;
          state.data.events = res.items;
          state.filter.pages = pages;
          return state;
        })
      })
  }

  handleTypeChange = (e) => {
    const target = e.target;
    this.setState(state => {
      state.filter.type = target.value;
      return state
    })
  }

  handleDateChange = (dates) => {
    this.setState(state => {
      state.filter.start_date = dates[0].toDate();
      state.filter.end_date = dates[1].toDate();
      return state
    })
  }

  handleFilterApply = () => {
    const {device} = this.props.match.params;
    let query = qs.parse(this.props.history.location.search);
    query.type = this.state.filter.type;
    query.start_date = this.state.filter.start_date.toLocaleDateString("ru-RU");
    query.end_date = this.state.filter.end_date.toLocaleDateString("ru-RU");

    this.loadEvents(device)
      .then(() => this.props.history.push({...this.props.location, search: "?" + serialize(query || {})}))
      .catch(() => this.setState({pending: false}));
  };

  handleExportToExcel = () => {
    if (!this.state.data.events.length) return;
    const {device} = this.props.match.params;

    let start = this.state.filter.start_date;
    start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
    let end = this.state.filter.end_date;
    end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);

    deviceAPI.EventsExcelExport(device, {
      all: true,
      start_date: start.getTime() / 1000,
      end_date: end.getTime() / 1000,
      type: this.state.filter.type,
    })
  }

  render() {
    const {device} = this.props;

    if (this.state.pending || !device || !device.id) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <div>
        <div className="row mb-5">
          <div className="col-6">

            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                    {I18n.t('key_PageDeviceInfo_FormDate_Name')}
                  </label>
                </div>
                <RangePicker id="date_filter"
                             className="mr-sm-2"
                             placeholder={[I18n.t('key_PageDeviceInfo_FormDate_StartDatePlaceholder'), I18n.t('key_PageDeviceInfo_FormDate_EndDatePlaceholder')]}
                             ranges={this.ranges}
                             defaultValue={[moment(this.state.filter.start_date, dateFormat), moment(this.state.filter.end_date, dateFormat)]}
                             format={dateFormat}
                             onChange={this.handleDateChange}
                             allowClear={false}
                             allowEmpty={false}
                />
              </div>
            </div>


            <div className="mb-3">
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="type_filter" style={{width: "80px"}}>
                    {I18n.t('key_PageDeviceInfo_FormType_Name')}
                  </label>
                </div>
                <select className="custom-select"
                        style={{maxWidth: "150px"}}
                        id="type_filter"
                        name="type"
                        onChange={this.handleTypeChange}
                        value={this.state.filter.type}>
                  <option value="">{I18n.t('key_PageDeviceInfo_FormTypeSelect_All')}</option>
                  <option value="command">{I18n.t('key_PageDeviceInfo_FormTypeSelect_Command')}</option>
                  <option value="status">{I18n.t('key_PageDeviceInfo_FormTypeSelect_Status')}</option>
                  <option value="info">{I18n.t('key_PageDeviceInfo_FormTypeSelect_Info')}</option>
                  <option value="system">{I18n.t('key_PageDeviceInfo_FormTypeSelect_System')}</option>
                </select>
              </div>
            </div>

            <div>
              <button type="button"
                      className="btn btn-primary"
                      onClick={this.handleFilterApply}>{I18n.t('key_PageDeviceInfo_FormStatusButton_Apply')}</button>
            </div>

          </div>
          <div className="col-6 align-self-center">
            <div className="float-right">
              <button
                disabled={!this.state.data.events.length}
                className="btn btn-lg btn-success text-uppercase"
                onClick={this.handleExportToExcel}>{I18n.t('key_PageDeviceInfo_ButtonExportToExcel')}</button>
            </div>
          </div>
        </div>

        <hr/>

        <div className="card">
          <div className="card-body p-0">
            {this.state.eventsLoading
              ? <div>Загрузка...</div>
              : <DeviceEventCardList events={this.state.data.events}/>
            }

            <div className="mt-4">
              <Pagination maxPages={this.state.filter.maxPages}
                          currentPage={this.state.filter.page}
                          numPages={this.state.filter.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.match.params.device),
  }
};

export default withRouter(connect(mapStateToProps)(DevicePartialEvents));

