import {createReducer} from "../utils/reducer";
import * as storage from "../utils/storage";
import {fromJS} from 'immutable'

import {accountReducer} from "./account";
import {sessionReducer} from "./session";
import {tagReducer} from "./tag";
import {mediaReducer} from "./media";
import {deviceReducer} from "./device";
import {customerReducer} from "./customer";
import {partnerReducer} from "./partner";
import {campaignAdvertisingReducer} from "./advertising_campaign";
import {vpnReducer} from "./vpn";
import {ffmpegReducer} from "./ffmpeg";
import {mediaPlanReducer} from "./mediaplan";
import {systemTriggerReducer} from "./system_trigger";

const initialState = fromJS(
  {
    session: {
      token: storage.LocalStorage().get('token')
    }
  }
);

const reducers = Object.assign(
  sessionReducer,
  accountReducer,
  tagReducer,
  mediaReducer,
  deviceReducer,
  customerReducer,
  partnerReducer,
  campaignAdvertisingReducer,
  vpnReducer,
  ffmpegReducer,
  mediaPlanReducer,
  systemTriggerReducer,
);

export default createReducer(initialState, reducers)
