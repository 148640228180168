import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import moment from "moment";
import {NavLink} from "react-router-dom";

const dateFormat = 'DD-MM-YYYY HH:mm';

class PartnerMpCardInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      error: {
        status: ""
      },
      pending: false,
    };
  }

  render() {

    const {item, partner} = this.props;

    window.$(() => window.$('[data-toggle="tooltip"]').tooltip());

    return (
      <div>
        <ul className="list-group list-group-flush bg-transparent">
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMpCardInfo_NameTitle')}</div>
            <div>{item.meta.name}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMpCardInfo_CreatedTitle')}</div>
            <div>{moment(item.created).format(dateFormat)}</div>
          </li>
          <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
            <div className="text-uppercase font-weight-bold">{I18n.t('key_PartnerMpCardInfo_PartnerTitle')}</div>
            <div><NavLink to={`/p/${partner.username}`}>{partner.email}</NavLink></div>
          </li>
        </ul>
      </div>
    )
  }
}

PartnerMpCardInfo.propTypes = {
  partner: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default PartnerMpCardInfo;
