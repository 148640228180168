import React from "react";
import PropTypes from "prop-types";
import Validator from "../utils/validator";
import {connect} from "react-redux";
import {API_EMAIL_ALREADY_IN_USE, API_USERNAME_ALREADY_IN_USE, EMAIL_EXISTS, USERNAME_EXISTS} from "../utils/error";
import {I18n} from "../utils/i18n";
import {Button} from '../containers'
import {NavLink} from "react-router-dom";

class AccountFormSignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: "",
        email: "",
        password: "",
      },
      error: {
        email: null,
        username: null,
        password: null
      },
      pending: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const error = name === 'username'
      ? Validator.UsernameField(value) :
      name === 'password'
        ? Validator.PasswordField(value)
        : Validator.EmailField(value);
    this.setState({data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: error}})
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = this.state.data;
    return Promise.resolve(this.props.submit(data.username, data.email, data.password))
      .then(this.handleSuccess, this.handleError)
  };

  handleDisabled = () => {
    return (
      !!Validator.EmailField(this.state.data.email) ||
      !!Validator.UsernameField(this.state.data.username) ||
      !!Validator.PasswordField(this.state.data.password) ||
      this.state.pending
    )
  };

  handleSuccess = () => {
    this.setState({data: {}, error: {}, pending: false});
  };

  handleError = (error) => {
    let e = this.state.error;

    if (error.message === "Bad email parameter") e.email = I18n.t('key_errorMsg_BadEmailParameter');
    if (error.message === "Bad username parameter") e.email = I18n.t('key_errorMsg_BadUsernameParameter');
    if (error.message === "Bad password parameter") e.email = I18n.t('key_errorMsg_BadPasswordParameter');
    if (error.message === "Bad invite code parameter") e.form = I18n.t('key_errorMsg_BadInviteCodeParameter');
    if (error.message === API_USERNAME_ALREADY_IN_USE) e.username = USERNAME_EXISTS();
    if (error.message === API_EMAIL_ALREADY_IN_USE) e.email = EMAIL_EXISTS();

    this.setState({error: Object.assign({}, this.state.error, e), pending: false});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h4 className="text-center text-danger">{this.state.error.form}</h4>
        </div>

        <div className="form-group">
          <label htmlFor="loginInput">{I18n.t('key_AccountFormSignUp_LabelUsername')}</label>
          <input id='loginInput'
                 type='text'
                 placeholder={I18n.t('key_AccountFormSignUp_PlaceholderUsername')}
                 className={`form-control form-control-border p-3 ${this.state.error.username ? 'is-invalid' : ''}`}
                 onChange={this.handleChange}
                 value={this.state.data.username}
                 name='username'
          />
          <div className="invalid-feedback">{this.state.error.username}</div>
        </div>

        <div className="form-group">
          <label htmlFor="emailInput">{I18n.t('key_AccountFormSignUp_LabelEmail')}</label>
          <input id='emailInput'
                 type='email'
                 placeholder={I18n.t('key_AccountFormSignUp_PlaceholderEmail')}
                 className={`form-control form-control-border p-3 ${this.state.error.email ? 'is-invalid' : ''}`}
                 onChange={this.handleChange}
                 value={this.state.data.email}
                 name='email'
          />
          <div className="invalid-feedback">{this.state.error.email}</div>
        </div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormSignUp_LabelPassword')}</label>
          <input id='passwordInput'
                 type='password'
                 placeholder={I18n.t('key_AccountFormSignUp_PlaceholderPassword')}
                 className={`form-control form-control-border p-3 ${this.state.error.password ? 'is-invalid' : ''}`}
                 onChange={this.handleChange}
                 value={this.state.data.password}
                 name='password'
          />
          <div className="invalid-feedback">{this.state.error.password}</div>
        </div>

        <div className="row justify-content-md-center">
          <div className="my-2 mb-3 text-center">
            <span>{I18n.t('key_AccountFormSignUp_SigningUpOrSigningUp')}</span>
          </div>
        </div>

        <div className="form-group text-center">
          <Button className='btn btn-primary'
                  type="submit"
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_SignUp')}
          />
        </div>

        <div className="pt-2 text-center">
          <NavLink to={"/signin"}>{I18n.t('key_AccountFormSignUp_IHaveAccount')}</NavLink>
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state, props) => props;

AccountFormSignUp.propTypes = {
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountFormSignUp);
