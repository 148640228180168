import {
  ADVERTISING_CAMPAIGN_CREATE_FAILURE,
  ADVERTISING_CAMPAIGN_CREATE_SUCCESS,
  ADVERTISING_CAMPAIGN_FETCH_FAILURE,
  ADVERTISING_CAMPAIGN_FETCH_SUCCESS,
  ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE,
  ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS,
  ADVERTISING_CAMPAIGN_REMOVE_FAILURE,
  ADVERTISING_CAMPAIGN_REMOVE_SUCCESS,
  ADVERTISING_CAMPAIGN_UPDATE_FAILURE,
  ADVERTISING_CAMPAIGN_UPDATE_SUCCESS,
} from "../constants";


export const campaignAdvertisingReducer = {
  [ADVERTISING_CAMPAIGN_CREATE_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [ADVERTISING_CAMPAIGN_CREATE_FAILURE]: (state) => {
    return state;
  },
  [ADVERTISING_CAMPAIGN_FETCH_SUCCESS]: (state, payload) => {
    return state.mergeDeep(payload)
  },
  [ADVERTISING_CAMPAIGN_FETCH_FAILURE]: (state) => {
    return state;
  },
  [ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "advertising_campaigns"]);
    return newState.mergeDeep(payload)
  },
  [ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE]: (state) => {
    return state;
  },
  [ADVERTISING_CAMPAIGN_UPDATE_SUCCESS]: (state, payload) => {
    let newState = state.removeIn(["entities", "advertising_campaigns", payload.result[0]]);
    return newState.mergeDeep(payload)
  },
  [ADVERTISING_CAMPAIGN_UPDATE_FAILURE]: (state) => {
    return state;
  },
  [ADVERTISING_CAMPAIGN_REMOVE_SUCCESS]: (state, payload) => {
    return state.removeIn(["entities", "advertising_campaigns", payload.id]);
  },
  [ADVERTISING_CAMPAIGN_REMOVE_FAILURE]: (state) => {
    return state;
  },
};
