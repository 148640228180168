import {Partner} from '../../api';

import {PARTNER_REMOVE_FAILURE, PARTNER_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: PARTNER_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: PARTNER_REMOVE_FAILURE,
  payload
});

export default (customer) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.Remove(customer.id)
      .then(() => {
        dispatch(SuccessAction(customer));
        resolve(customer)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};