import React from "react";
import PropTypes from "prop-types";
import Validator from "../utils/validator";
import {PASSWORD_NOT_VALID, PASSWORDS_NOT_EQUAL, UNDEFINED} from "../utils/error";
import {connect} from "react-redux";
import {Button} from '../containers'
import {I18n} from "../utils/i18n";
import {getAccount} from "../selectors";

class AccountFormPasswordChange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success: props.success || false,
      error: Object.assign({}, props.error || {}),
      data: {
        current: "",
        password: "",
        confirm: ""
      },
      pending: false
    };

  }

  componentWillReceiveProps(nextProps) {
    this.setState({error: nextProps.error || {}, success: nextProps.success})
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      error: Object.assign(this.state.error, {[name]: ""}),
      data: Object.assign(this.state.data, {[name]: value})
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = this.state.data;
    let error = {};
    error.current = Validator.PasswordField(data.current);
    error.password = Validator.PasswordField(data.password);
    error.confirm = Validator.PasswordField(data.confirm);
    for (var key in error) {
      if (error[key] !== null && error[key] !== "") {
        this.setState({error: Object.assign(this.state.error, error)});
        return
      }
    }
    if (data.password !== data.confirm) {
      error.notEqual = PASSWORDS_NOT_EQUAL();
      this.setState({error: Object.assign(this.state.error, error)});
      return
    }
    return Promise.resolve(this.props.submit(this.state.data))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true, error: {}});
    setTimeout(() => this.setState({success: false}), 2000);
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.code === 400) {
      e.current = PASSWORD_NOT_VALID()
    } else {
      e.form = UNDEFINED()
    }
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return this.state.pending
      || (this.state.data.confirm === ""
        || this.state.data.current === ""
        || this.state.data.password === ""
        || (this.state.data.current === this.state.data.password)
        || (this.state.data.current === this.state.data.confirm)
        || (this.state.data.password !== this.state.data.confirm)
      );
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h5 className="text-center text-danger">{this.state.error.form}</h5>
        </div>

        <div className="form-group">
          <label htmlFor="currentInput">{I18n.t('key_AccountFormPasswordChange_LabelCurrentPassword')}</label>
          <input type="password"
                 id="currentInput"
                 placeholder={I18n.t('key_AccountFormPasswordChange_PlaceholderCurrentPassword')}
                 className={`form-control form-control-sm ${this.state.error.current ? 'is-invalid' : ''}`}
                 name="current"
                 value={this.state.data.current}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.current}</div>
        </div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormPasswordChange_LabelEnterNewPassword')}</label>
          <input type="password"
                 id="passwordInput"
                 placeholder={I18n.t('key_AccountFormPasswordChange_PlaceholderEnterNewPassword')}
                 className={`form-control form-control-sm ${this.state.error.password ? 'is-invalid' : ''}`}
                 name="password"
                 value={this.state.data.password}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.password || this.state.error.notEqual}</div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmInput">{I18n.t('key_AccountFormPasswordChange_LabelConfirmPassword')}</label>
          <input type="password"
                 id="confirmInput"
                 placeholder={I18n.t('key_AccountFormPasswordChange_PlaceholderConfirmPassword')}
                 className={`form-control form-control-sm ${this.state.error.confirm ? 'is-invalid' : ''}`}
                 name="confirm"
                 value={this.state.data.confirm}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.confirm || this.state.error.notEqual}</div>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Update')}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  account: getAccount(state)
});

AccountFormPasswordChange.propTypes = {
  error: PropTypes.object,
  success: PropTypes.bool,
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountFormPasswordChange);
