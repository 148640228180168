import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import moment from "moment";
import {NavLink} from "react-router-dom";

const dateFormat = 'DD-MM-YYYY HH:mm';

const PartnerMpCardList = ({items, partner, onRemove}) => {
  return (
    <table className="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col" style={{
          textAlign: "center",
          minWidth: "150px"
        }}>{I18n.t('key_PartnerMpCardList_Name')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "100px",
          width: "100px",
        }}>{I18n.t('key_PartnerMpCardList_Created')}</th>
        {/*<th scope="col" style={{*/}
        {/*  textAlign: "center",*/}
        {/*  maxWidth: "100px",*/}
        {/*  width: "100px",*/}
        {/*}}>{I18n.t('key_PartnerMpCardList_Action')}</th>*/}
      </tr>
      </thead>
      <tbody>
      {
        items.map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="align-middle" align="left" valign="middle">
                <NavLink to={`/p/${partner.username}/mp/${item.meta.id}`}>{item.meta.name}</NavLink>
              </td>
              <td className="align-middle" align="center" valign="middle">{moment(item.meta.created).format(dateFormat)}</td>
              {/*<td className="align-middle" align="left" valign="middle">*/}
              {/*  <ModalDialog name={`remove${index}`}*/}
              {/*               header={<h3>{I18n.t('key_PartnerMpCardList_ModalRemoveMp')}</h3>}*/}
              {/*               btnOpen={<button className="btn btn-danger btn-sm"*/}
              {/*                                data-toggle="modal" data-target={`#remove${index}`}*/}
              {/*                                disabled={item.status.state === "deleting"}*/}
              {/*               >*/}
              {/*                 {I18n.t('key_Remove')}*/}
              {/*               </button>}*/}
              {/*               btnOpenName={I18n.t('key_Remove')}*/}
              {/*               btnOkClassName="btn btn-danger"*/}
              {/*               btnCloseName={I18n.t('key_Close')}*/}
              {/*               btnCloseClassName="btn btn-primary"*/}
              {/*               btnOkName={I18n.t('key_Remove')}*/}
              {/*               onOk={() => onRemove(item)}*/}
              {/*               content={<div>{I18n.t('key_PartnerMpCardList_ModalRemoveMpApprove', {name: item.meta.name})}</div>}*/}
              {/*  />*/}
              {/*</td>*/}
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

PartnerMpCardList.defaultProps = {
  items: [],
};

PartnerMpCardList.propTypes = {
  partner: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  items: PropTypes.array,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(PartnerMpCardList);
