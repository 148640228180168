import React from "react";
import {Link, withRouter} from "react-router-dom";
import {I18n} from "../utils/i18n";

class Footer extends React.Component {
  render() {
    return (
      <nav className="navbar justify-content-between">
        <div>2019© LLC Mosfilm</div>
        <div>
          <Link to="/" target="_blank">Mosfilm</Link>
          <Link to="/" target="_blank"
                className="px-3">{I18n.t('key_Footer_Docs')}</Link>
          <span>{I18n.t('key_Footer_Terms')}</span>
        </div>
      </nav>
    )
  }
}

export default withRouter(Footer)