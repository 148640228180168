import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAccount} from "../selectors";
import {Button} from '../containers'
import Validator from "../utils/validator";
import {I18n} from "../utils/i18n";

class AccountFormTelegram extends React.Component {

  constructor(props) {
    super(props);
    const {data} = this.props;
    this.state = {
      error: Object.assign({}, props.error || {}),
      data: {
        access_token: data.access_token || "",
      },
      accessTokenPlaceholder: data.access_token ? "●●●●●●" : "",
      pending: false,
      success: false
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      error: Object.assign(this.state.error, {[name]: ""}),
      data: Object.assign(this.state.data, {[name]: value}),
      pending: false,
      success: false
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = this.state.data;

    let error = {};
    error.access_token = Validator.NotEmptyField(data.access_token);

    for (let key in error) {
      if (error[key] !== null && error[key] !== "") {
        this.setState({error: Object.assign(this.state.error, error)});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true});
    setTimeout(() => this.setState({success: false}), 2000);
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad access_token parameter") e.login = I18n.t('key_errorMsg_BadAccessTokenParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending || this.state.data.access_token === ""
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h5 className="text-center text-danger">{this.state.error.form}</h5>
        </div>

        <div className="form-group">
          <label htmlFor="accessTokenInput">{I18n.t('key_AccountFormTelegram_LabelAccessToken')}</label>
          <input type="password"
                 id="accessTokenInput"
                 name="access_token"
                 className={`form-control form-control-sm ${this.state.error.access_token ? 'is-invalid' : ''}`}
                 value={this.state.data.access_token}
                 autoComplete="access_token"
                 placeholder={this.state.accessTokenPlaceholder}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.access_token}</div>
        </div>

        <div className="text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  disabled={this.handleDisabled()}
                  submit={this.handleSubmit}
                  main={I18n.t('key_Apply')}
          />
        </div>

      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  account: getAccount(state),
});

AccountFormTelegram.propTypes = {
  error: PropTypes.object,
  data: PropTypes.object,
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(AccountFormTelegram);
