import {requestJSON} from "../utils/request";

const api_host = window._env_.API_HOST;

class FFmpeg {

  static CommandCreate(data) {
    let uri = [api_host, "ffmpeg"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static CommandFetch(ffmpeg) {
    let uri = [api_host, "ffmpeg", ffmpeg].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static CommandList() {
    let uri = [api_host, "ffmpeg"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static CommandUpdate(ffmpeg, data) {
    let uri = [api_host, "ffmpeg", ffmpeg].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static CommandRemove(ffmpeg) {
    let uri = [api_host, "ffmpeg", ffmpeg].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

}

export default FFmpeg
