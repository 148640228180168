import React from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import Validator from "../utils/validator";
import {SIGNIN_FAILED} from "../utils/error";
import {connect} from "react-redux";
import {I18n} from "../utils/i18n";
import {Button} from '../containers'

class AccountFormSignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        login: "",
        password: ""
      },
      error: {
        login: null,
        password: null
      },
      pending: false
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const error = name === 'login' ? Validator.LoginField(value) : Validator.PasswordField(value);
    this.setState({data: {...this.state.data, [name]: value}, error: {...this.state.error, [name]: error}})
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = this.state.data;
    return Promise.resolve(this.props.submit(data.login, data.password))
      .then(this.handleSuccess, this.handleError);
  };


  handleDisabled = () => {
    return (
      !!Validator.LoginField(this.state.data.login) ||
      !!Validator.PasswordField(this.state.data.password) ||
      this.state.pending
    )
  };

  handleSuccess = () => {
    this.setState({pending: false});
  };

  handleError = () => {
    let e = this.state.error;

    e.login = SIGNIN_FAILED();
    e.password = SIGNIN_FAILED();

    this.setState({error: e, pending: false});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          <h4 className="text-center text-danger">{this.state.error.form}</h4>
        </div>

        <div className="form-group">
          <label htmlFor="loginInput">{I18n.t('key_AccountFormSignIn_LabelLogin')}</label>
          <input id='loginInput'
                 name='login'
                 type='text'
                 placeholder={I18n.t('key_AccountFormSignIn_PlaceholderLogin')}
                 className={`form-control form-control-border p-3 ${this.state.error.login ? 'is-invalid' : ''}`}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.login}</div>
        </div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormSignIn_LabelPassword')}</label>
          <input id='passwordInput'
                 name='password'
                 type='password'
                 placeholder={I18n.t('key_AccountFormSignIn_PlaceholderPassword')}
                 className={`form-control form-control-border p-3 ${this.state.error.password ? 'is-invalid' : ''}`}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.password}</div>
        </div>

        <div className="form-group">
          <div className="clearfix">
            <div className="float-left pt-2">
              <NavLink to={"/password/recovery"}>{I18n.t('key_AccountFormSignIn_ForgotPassword')}</NavLink>
            </div>
            <div className="float-right">
              <Button className='btn btn-primary'
                      type='submit'
                      disabled={this.handleDisabled()}
                      main={(I18n.t('key_Login'))}
              />
            </div>
          </div>
        </div>

        {/*<div className="pt-2 text-center">*/}
        {/*  <NavLink to={"/signup"}>{I18n.t('key_AccountFormSignIn_CreateAccount')}</NavLink>*/}
        {/*</div>*/}

      </form>
    )
  }
}

AccountFormSignIn.propTypes = {
  submit: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(AccountFormSignIn)
