import React from "react";
import {connect} from "react-redux";
import {Preloader} from "../../components";
import {FFmpegCommandCardList} from "../../containers";
import {I18n} from "../../utils/i18n";
import {getFFmpegCommands} from "../../selectors";
import {withRouter} from "react-router-dom";
import {FFmpeg} from "../../actions";
import {FFmpegCommandFormCreate, FFmpegCommandFormUpdate} from "../../containers";

class SettingsPartialFFmpegCommandList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateForm: false,
      showUpdateForm: false,
      data: null,
      pending: true,
      error: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(FFmpeg.CommandList())
      .then((res) => this.setState({pending: false}))
      .catch(() => this.setState({pending: false}))
  }

  handleCreate = (data) => {
    return this.props.dispatch(FFmpeg.CommandCreate(data))
      .then(() => this.setState({pending: false, showCreateForm: false}))
      .catch((e) => this.setState({pending: false, error: e.message}))
  };

  handleUpdate = (data) => {
    return this.props.dispatch(FFmpeg.CommandUpdate(this.state.data, data))
      .then(() => this.setState({pending: false, showUpdateForm: false, data: null}))
      .catch((e) => this.setState({pending: false, error: e.message}))
  };

  handleRemove = (data) => {
    this.props.dispatch(FFmpeg.CommandRemove(data))
  };

  handleSelect = (data) => {
    this.setState({showUpdateForm: true, data: data})
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      data: {...this.state.data, [name]: value}
    })
  };

  showCreateForm = () => {
    this.setState({showCreateForm: true})
  }

  handleCancel = () => {
    this.setState({showCreateForm: false, showUpdateForm: false, data: null})
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    if (this.state.showCreateForm || this.state.showUpdateForm) {
      return (
        <div>
          <div className="row mb-4">
            <div className="col-md-6">
              {this.state.showCreateForm && <h4>{I18n.t('key_FFmpegCommandFormCreate_Title')}</h4>}
              {this.state.showUpdateForm && <h4>{I18n.t('key_FFmpegCommandFormUpdate_Title')}</h4>}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {this.state. error && <div className="text-danger mb-2">{this.state. error}</div>}
              {this.state.showCreateForm && <FFmpegCommandFormCreate submit={this.handleCreate} cancel={this.handleCancel}/>}
              {this.state.showUpdateForm && <FFmpegCommandFormUpdate data={this.state.data} submit={this.handleUpdate} cancel={this.handleCancel}/>}
            </div>
          </div>
        </div>
      )
    }

    const {commands} = this.props;

    return (
      <div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h4>{I18n.t('key_SettingsPartialFFmpegCommandList_ListTitle')}</h4>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={this.showCreateForm}>
                {I18n.t('key_Add')}
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <FFmpegCommandCardList items={commands}
                                   onRemove={this.handleRemove}
                                   onSelect={this.handleSelect}
                                   submit={this.handleUpdate}/>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    commands: getFFmpegCommands(state),
  }
};

export default withRouter(connect(mapStateToProps)(SettingsPartialFFmpegCommandList));

