import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {Button} from "../containers";
import Validator from "../utils/validator";
import {PASSWORD_NOT_VALID, PASSWORDS_NOT_EQUAL, UNDEFINED} from "../utils/error";

class CustomerFormPasswordChange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success: props.success || false,
      error: Object.assign({}, props.error || {}),
      data: {
        password: "",
        confirm: ""
      },
      pending: false
    };

  }

  componentWillReceiveProps(nextProps) {
    this.setState({error: nextProps.error || {}, success: nextProps.success})
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      error: Object.assign(this.state.error, {[name]: ""}),
      data: Object.assign(this.state.data, {[name]: value})
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = this.state.data;
    let errors = {};
    errors.password = Validator.PasswordField(data.password);
    errors.confirm = Validator.PasswordField(data.confirm);

    if (data.password !== data.confirm) {
      errors.password = PASSWORDS_NOT_EQUAL();
      errors.confirm = PASSWORDS_NOT_EQUAL();
    }

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors)});
        return
      }
    }

    return Promise.resolve(this.props.submit(this.state.data))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
    this.setState({success: true, error: {}});
    setTimeout(() => this.setState({success: false, data: {password: "", confirm: ""}}), 2000);
  };

  handleError = (error) => {
    let e = this.state.error;
    if (error.code === 400) {
      e.current = PASSWORD_NOT_VALID()
    } else {
      e.form = UNDEFINED()
    }

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return this.state.pending
  };

  render() {
    return (
      <div>

        <div className="form-group">
          <label htmlFor="passwordInput">{I18n.t('key_AccountFormPasswordChange_LabelEnterNewPassword')}</label>
          <input type="password"
                 id="passwordInput"
                 placeholder={I18n.t('key_AccountFormPasswordChange_PlaceholderEnterNewPassword')}
                 className={`form-control ${this.state.error.password ? 'is-invalid' : ''}`}
                 name="password"
                 value={this.state.data.password}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.password || this.state.error.notEqual}</div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmInput">{I18n.t('key_AccountFormPasswordChange_LabelConfirmPassword')}</label>
          <input type="password"
                 id="confirmInput"
                 placeholder={I18n.t('key_AccountFormPasswordChange_PlaceholderConfirmPassword')}
                 className={`form-control ${this.state.error.confirm ? 'is-invalid' : ''}`}
                 name="confirm"
                 value={this.state.data.confirm}
                 onChange={this.handleChange}
          />
          <div className="invalid-feedback">{this.state.error.confirm || this.state.error.notEqual}</div>
        </div>

        <div className="form-group text-center">
          <Button type='submit'
                  className='btn btn-primary'
                  submit={this.handleSubmit}
                  disabled={this.handleDisabled()}
                  main={I18n.t('key_Update')}
          />
        </div>

      </div>
    )
  }
}

CustomerFormPasswordChange.propTypes = {
  name: PropTypes.string,
  submit: PropTypes.func,
};

export default CustomerFormPasswordChange;
