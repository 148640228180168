import React from "react";
import {Button} from "./index";
import {I18n} from "../utils/i18n";
import {TimePicker} from 'antd';
import {parseTime} from "../utils/helpers";
import moment from 'moment';
import PropTypes from "prop-types";

const timeFormat = "HH:mm";
const defaultDate = moment("00:00", 'HH:mm')
const formatTime = (date_time) => {
  return date_time.toLocaleTimeString('ru-RU', {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
}

class DeviceFormTvControlUpdate extends React.Component {

  constructor(props) {
    super(props);

    const data = props.device.spec.tv || {
      screen: {
        type: "manual",
        schedule: {
          mo: {}, tu: {}, we: {}, th: {}, fr: {}, sa: {}, su: {}
        },
        manual: {},
        motion_detect: {}
      },
    };

    this.state = {
      data: {
        enable: data.enable,
        ips: data.ips || [],
        screen: {
          type: data.screen.type || "manual",
          schedule: {
            mo: {
              enable: data.screen.schedule.mo.enable,
              start_time: parseTime(data.screen.schedule.mo.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.mo.end_time || "00:00:00"),
            },
            tu: {
              enable: data.screen.schedule.tu.enable,
              start_time: parseTime(data.screen.schedule.tu.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.tu.end_time || "00:00:00"),
            },
            we: {
              enable: data.screen.schedule.we.enable,
              start_time: parseTime(data.screen.schedule.we.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.we.end_time || "00:00:00"),
            },
            th: {
              enable: data.screen.schedule.th.enable,
              start_time: parseTime(data.screen.schedule.th.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.th.end_time || "00:00:00"),
            },
            fr: {
              enable: data.screen.schedule.fr.enable,
              start_time: parseTime(data.screen.schedule.fr.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.fr.end_time || "00:00:00"),
            },
            sa: {
              enable: data.screen.schedule.sa.enable,
              start_time: parseTime(data.screen.schedule.sa.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.sa.end_time || "00:00:00"),
            },
            su: {
              enable: data.screen.schedule.su.enable,
              start_time: parseTime(data.screen.schedule.su.start_time || "00:00:00"),
              end_time: parseTime(data.screen.schedule.su.end_time || "00:00:00"),
            },
          },
          manual: {
            enable: data.screen.manual.enable || false,
          },
          motion_detect: {
            delay_screen_off: data.screen.motion_detect.delay_screen_off || 30,
          }
        },
        volume: data.volume,
        remote_control: data.remote_control,
        input_source: data.input_source || "hdmi1",
      },
      ip: "",
      error: {},
      pending: false,
      success: false
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "tv_ip":
        patch = {...this.state, ip: value}
        break;
      case "screen_motion_detect":
      case "screen_schedule":
      case "screen_manual":
        const type = name.replace("screen_", "", -1)
        patch = {
          data: {
            ...this.state.data,
            screen: {
              ...this.state.data.screen, type: type
            }
          }
        };
        break;
      case "screen_manual_switch":
        patch = {
          data: {
            ...this.state.data,
            screen: {
              ...this.state.data.screen,
              manual: {
                ...this.state.data.screen.manual, enable: value
              }
            }
          }
        };
        break;
      case "screen_motion_detect_delay_screen_off":
        patch = {
          data: {
            ...this.state.data,
            screen: {
              ...this.state.data.screen,
              motion_detect: {
                ...this.state.data.screen.motion_detect, delay_screen_off: value
              }
            }
          }
        };
        break;
      case "screen_mo":
      case "screen_tu":
      case "screen_we":
      case "screen_th":
      case "screen_fr":
      case "screen_sa":
      case "screen_su":
        const prop = name.replace("screen_", "", -1)
        patch = {
          data: {
            ...this.state.data,
            screen: {
              ...this.state.data.screen,
              schedule: {
                ...this.state.data.screen.schedule,
                [prop]: {enable: value.enable, start_time: value.start_time, end_time: value.end_time}
              }
            }
          }
        };
        break;
      case "remote_control":
        patch = {data: {...this.state.data, remote_control: value}};
        break;
      case "volume":
        patch = {data: {...this.state.data, volume: value}};
        break;
      case "input_source_default":
      case "input_source_hdmi1":
      case "input_source_hdmi2":
      case "input_source_hdmi3":
      case "input_source_displayport":
      case "input_source_web":
        patch = {data: {...this.state.data, input_source: name.replace("input_source_", "", -1)}};
        break;
      default:
    }

    this.setState(patch)
  };

  handleAddIP = (e) => {
    let ips = this.state.data.ips;
    ips.push(this.state.ip)
    this.setState({ip: "", data: {...this.state.data, ips: ips}})
  };

  handleDelIP = (e, index) => {
    let ips = this.state.data.ips;
    ips.splice(index, 1);
    this.setState({data: {...this.state.data, ips: ips}})
  };

  handleEnableChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({data: {...this.state.data, [name]: value}}, () => {
      this.handleSubmit(e);
    })
  }

  handleSubmit = () => {
    const res = {
      enable: this.state.data.enable,
      ips: this.state.data.ips || [],
      input_source: this.state.data.input_source || "hdmi1",
      remote_control: this.state.data.remote_control || false,
      volume: this.state.data.volume || false,
      screen: {
        type: this.state.data.screen.type || "manual",
        manual: {
          enable: this.state.data.screen.manual.enable || false,
        },
        motion_detect: {
          delay_screen_off: parseInt(this.state.data.screen.motion_detect.delay_screen_off, 10) || 30,
        },
        schedule: {
          mo: {
            enable: this.state.data.screen.schedule.mo.enable,
            start_time: formatTime(this.state.data.screen.schedule.mo.start_time),
            end_time: formatTime(this.state.data.screen.schedule.mo.end_time),
          },
          tu: {
            enable: this.state.data.screen.schedule.tu.enable,
            start_time: formatTime(this.state.data.screen.schedule.tu.start_time),
            end_time: formatTime(this.state.data.screen.schedule.tu.end_time),
          },
          we: {
            enable: this.state.data.screen.schedule.we.enable,
            start_time: formatTime(this.state.data.screen.schedule.we.start_time),
            end_time: formatTime(this.state.data.screen.schedule.we.end_time),
          },
          th: {
            enable: this.state.data.screen.schedule.th.enable,
            start_time: formatTime(this.state.data.screen.schedule.th.start_time),
            end_time: formatTime(this.state.data.screen.schedule.th.end_time),
          },
          fr: {
            enable: this.state.data.screen.schedule.fr.enable,
            start_time: formatTime(this.state.data.screen.schedule.fr.start_time),
            end_time: formatTime(this.state.data.screen.schedule.fr.end_time),
          },
          sa: {
            enable: this.state.data.screen.schedule.sa.enable,
            start_time: formatTime(this.state.data.screen.schedule.sa.start_time),
            end_time: formatTime(this.state.data.screen.schedule.sa.end_time),
          },
          su: {
            enable: this.state.data.screen.schedule.su.enable,
            start_time: formatTime(this.state.data.screen.schedule.su.start_time),
            end_time: formatTime(this.state.data.screen.schedule.su.end_time),
          },
        }
      }
    }

    return Promise.resolve(this.props.submit(res))
      .then(this.handleSuccess, this.handleError);
  };

  handleSuccess = () => {
  };

  handleError = (errors) => {
    let e = this.state.error;
    e.form = errors.message;
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return this.state.pending;
  };

  render() {
    return (
      <form>

        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="tv_enable" name="enable"
                 checked={this.state.data.enable}
                 onChange={this.handleEnableChange}/>
          <label className="custom-control-label"
                 htmlFor="tv_enable">{I18n.t('key_DeviceFormTvControlUpdate_TvEnable')}</label>
        </div>

        {(this.state.data.enable) && (
          <div className="mt-5">

            <div className="form-group">
              <label htmlFor="screens"
                     className="col-form-label">{I18n.t('key_DeviceFormTvControlUpdate_ScreenIPLabel')}:</label>
              <div>
                {
                  this.state.data.ips.map((ip, index) => (
                    <div key={index} className="form-text pb-1">{ip} <i className="fa fa-times text-danger cursor-pointer"
                      onClick={e => this.handleDelIP(e, index)}/>
                    </div>
                  ))
                }
              </div>
              <div className="form-row">
                <div className="col-auto">
                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">IP</span>
                    </div>
                    <input type="text" className="form-control"
                           htmlFor="addIP"
                           name="tv_ip"
                           value={this.state.ip}
                           onChange={this.handleChange}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary"
                              type="button" id="addIP"
                              disabled={!this.state.ip}
                              onClick={this.handleAddIP}>
                        {I18n.t('key_Add')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3"
                     className="col-sm-3 col-form-label">{I18n.t('key_DeviceFormTvControlUpdate_ScreenControlLabel')}</label>
              <div className="col-sm-9">

                <div className="custom-control custom-radio">
                  <input type="radio" name="screen_manual" className="custom-control-input" id='screen_manual'
                         checked={this.state.data.screen.type === "manual"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label"
                         htmlFor="screen_manual">{I18n.t('key_DeviceFormTvControlUpdate_ScreenTurnOnManual')}</label>
                </div>

                <div className="custom-control custom-radio">
                  <input type="radio" name="screen_schedule" className="custom-control-input" id='screen_schedule'
                         checked={this.state.data.screen.type === "schedule"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label"
                         htmlFor="screen_schedule">{I18n.t('key_DeviceFormTvControlUpdate_ScreenTurnOnSchedule')}</label>
                </div>

                <div className="custom-control custom-radio">
                  <input type="radio" name="screen_motion_detect" className="custom-control-input" id='screen_motion_detect'
                         checked={this.state.data.screen.type === "motion_detect"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label"
                         htmlFor="screen_motion_detect">{I18n.t('key_DeviceFormTvControlUpdate_ScreenTurnOnMotionDetect')}</label>
                </div>

                <div className="mt-2">

                  {(this.state.data.screen.type === "manual") && (
                    <div className="custom-control custom-switch">
                      <input type="checkbox" className="custom-control-input" name="screen_manual_switch"
                             id="screen_manual_switch"
                             checked={this.state.data.screen.manual.enable}
                             onChange={this.handleChange}/>
                      <label className="custom-control-label"
                             htmlFor="screen_manual_switch">{I18n.t('key_DeviceFormTvControlUpdate_ScreenTurnOnSwitchDescription')}</label>
                    </div>
                  )}

                  {(this.state.data.screen.type === "schedule") && (
                    <div>
                      <RangePickerForm name="screen_mo" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeMo')}
                                       enable={this.state.data.screen.schedule.mo.enable}
                                       start_time={this.state.data.screen.schedule.mo.start_time}
                                       end_time={this.state.data.screen.schedule.mo.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_tu" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeTu')}
                                       enable={this.state.data.screen.schedule.tu.enable}
                                       start_time={this.state.data.screen.schedule.tu.start_time}
                                       end_time={this.state.data.screen.schedule.tu.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_we" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeWe')}
                                       enable={this.state.data.screen.schedule.we.enable}
                                       start_time={this.state.data.screen.schedule.we.start_time}
                                       end_time={this.state.data.screen.schedule.we.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_th" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeTh')}
                                       enable={this.state.data.screen.schedule.th.enable}
                                       start_time={this.state.data.screen.schedule.th.start_time}
                                       end_time={this.state.data.screen.schedule.th.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_fr" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeFr')}
                                       enable={this.state.data.screen.schedule.fr.enable}
                                       start_time={this.state.data.screen.schedule.fr.start_time}
                                       end_time={this.state.data.screen.schedule.fr.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_sa" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeSa')}
                                       enable={this.state.data.screen.schedule.sa.enable}
                                       start_time={this.state.data.screen.schedule.sa.start_time}
                                       end_time={this.state.data.screen.schedule.sa.end_time}
                                       onChange={this.handleChange}/>
                      <RangePickerForm name="screen_su" label={I18n.t('key_DeviceFormTvControlUpdate_DateTimeSu')}
                                       enable={this.state.data.screen.schedule.su.enable}
                                       start_time={this.state.data.screen.schedule.su.start_time}
                                       end_time={this.state.data.screen.schedule.su.end_time}
                                       onChange={this.handleChange}/>
                    </div>
                  )}

                  {(this.state.data.screen.type === "motion_detect") && (
                    <div className="form-group">
                      <label htmlFor="screen_motion_detect_delay_screen_off" className="col-form-label">
                        {I18n.t('key_DeviceFormTvControlUpdate_DelayScreenOffLabel')}
                      </label>
                      <div className="form-row">
                        <div className="col-auto">
                          <div className="input-group input-group-sm mb-3">
                            <input type="text"
                                   className="form-control"
                                   htmlFor="screen_motion_detect_delay_screen_off"
                                   name="screen_motion_detect_delay_screen_off"
                                   value={this.state.data.screen.motion_detect.delay_screen_off}
                                   onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3"
                     className="col-sm-3 col-form-label">{I18n.t('key_DeviceFormTvControlUpdate_RemoteTvControlLabel')}</label>
              <div className="col-sm-9">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="remove_control" name="remote_control"
                         checked={this.state.data.remote_control}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label"
                         htmlFor="remove_control">{I18n.t('key_DeviceFormTvControlUpdate_RemoteTvControlSwitchDescription')}</label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3"
                     className="col-sm-3 col-form-label">{I18n.t('key_DeviceFormTvControlUpdate_VolumeControlLabel')}</label>
              <div className="col-sm-9">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="volume" name="volume"
                         checked={this.state.data.volume}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label"
                         htmlFor="volume">{I18n.t('key_DeviceFormTvControlUpdate_VolumeSwitchDescription')}</label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3"
                     className="col-sm-3 col-form-label">{I18n.t('key_DeviceFormTvControlUpdate_InputSourceControlLock')}</label>
              <div className="col-sm-9">
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source0" name="input_source_default" className="custom-control-input"
                         checked={this.state.data.input_source === "default"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source0">Default</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source1" name="input_source_hdmi1" className="custom-control-input"
                         checked={this.state.data.input_source === "hdmi1"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source1">HDMI1</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source2" name="input_source_hdmi2" className="custom-control-input"
                         checked={this.state.data.input_source === "hdmi2"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source2">HDMI2</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source3" name="input_source_hdmi3" className="custom-control-input"
                         checked={this.state.data.input_source === "hdmi3"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source3">HDMI3</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source4" name="input_source_displayport" className="custom-control-input"
                         checked={this.state.data.input_source === "displayport"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source4">DISPLAYPORT</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="input_source5" name="input_source_web" className="custom-control-input"
                         checked={this.state.data.input_source === "web"}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="input_source5">Web</label>
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button type='submit'
                      className='btn btn-primary'
                      submit={this.handleSubmit}
                      main={I18n.t('key_Apply')}
              />
            </div>

          </div>
        )}

      </form>
    )
  }
}

class RangePickerForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        enable: props.enable,
        start_time: props.start_time,
        end_time: props.end_time,
      },
    };
  }

  handleEnableChange = (e) => {
    this.setState({data: {...this.state.data, enable: e.target.checked}}, this.handleChange)
  }

  handleDateChange = (name, val) => {
    this.setState({data: {...this.state.data, [name]: val.toDate()}}, this.handleChange)
  }

  handleChange = () => {
    let event = new Event(this.props.name);
    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: this.props.name, value: this.state.data},
    });
    this.props.onChange(event)
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="input-group">
          <div className="input-group-prepend" style={{width: "50px"}}>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" name={this.props.name} id={this.props.name}
                     checked={this.state.data.enable}
                     onChange={this.handleEnableChange}/>
              <label className="custom-control-label" htmlFor={this.props.name}>{this.props.label}</label>
            </div>
          </div>

          <TimePicker format={timeFormat}
                      name={`time-picket-start-${this.props.name}`}
                      id={`time-picket-start-${this.props.name}`}
                      size="small"
                      bordered={false}
                      defaultValue={defaultDate}
                      hideDisabledOptions={true}
                      allowClear={false}
                      showNow={false}
                      disabled={!this.state.data.enable}
                      value={moment(this.state.data.start_time)}
                      onChange={val => this.handleDateChange("start_time", val)}/>

          <TimePicker format={timeFormat}
                      name={`time-picket-end-${this.props.name}`}
                      id={`time-picket-end-${this.props.name}`}
                      size="small"
                      bordered={false}
                      defaultValue={defaultDate}
                      hideDisabledOptions={true}
                      showNow={false}
                      allowClear={false}
                      disabled={!this.state.data.enable}
                      value={moment(this.state.data.end_time)}
                      onChange={val => this.handleDateChange("end_time", val)}/>
        </div>
      </div>
    )
  }
}

RangePickerForm.propTypes = {
  name: PropTypes.string,
  enable: PropTypes.bool,
  start: PropTypes.object,
  end: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

DeviceFormTvControlUpdate.propTypes = {
  error: PropTypes.object,
  device: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};

export default DeviceFormTvControlUpdate;
