import React from "react";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {I18n} from "../utils/i18n";
import moment from "moment";
import {ModalDialog} from "./index";

const dateFormat = 'DD-MM-YYYY';

class FFmpegCommandCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSelect = (e, item) => {
    e.stopPropagation();
    this.props.onSelect(item);
  };

  handleRemove = (e, item) => {
    e.stopPropagation();
    this.props.onRemove(item);
  };

  render() {
    const {items} = this.props;
    return (
      <div>
        <table className="table table-hover table-bordered">
          <thead>
          <tr>
            <th scope="col" className="text-left"
                style={{width: "100px"}}>{I18n.t('key_FFmpegCommandCardList_TableHeaderName')}</th>
            <th scope="col" className="text-left"
                style={{width: "250px"}}>{I18n.t('key_FFmpegCommandCardList_TableHeaderCommand')}</th>
            <th scope="col" className="text-center" style={{width: "10px"}}/>
          </tr>
          </thead>
          <tbody>

          {
            (!!Object.keys(items).length) && items.sortDateAsc().map((item, index) => {
              return (
                <tr key={index} className="cursor-pointer">
                  <td style={{verticalAlign: "middle"}} onClick={e => this.handleSelect(e, item)}>
                    {item.name}
                  </td>
                  <td style={{verticalAlign: "middle"}}>
                    {item.command}
                  </td>
                  <td className="text-center" style={{verticalAlign: "middle"}} onClick={e => e.stopPropagation()}>
                    <ModalDialog name={`remove${index}`}
                                 header={<h3>{I18n.t('key_FFmpegCommandCardList_ModalRemoveFFmpegCommand')}</h3>}
                                 btnOpen={<button className="btn btn-danger btn-sm"
                                                  data-toggle="modal" data-target={`#remove${index}`}>
                                   {I18n.t('key_Remove')}
                                 </button>}
                                 btnOpenName={I18n.t('key_Remove')}
                                 btnOkClassName="btn btn-danger"
                                 btnCloseName={I18n.t('key_Close')}
                                 btnCloseClassName="btn btn-primary"
                                 btnOkName={I18n.t('key_Remove')}
                                 onOk={(e) => this.handleRemove(e, item)}
                                 content={<div>{I18n.t('key_FFmpegCommandCardList_ModalRemoveFFmpegCommandApprove', {name: item.name})}</div>}
                    />
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
}

FFmpegCommandCardList.defaultProps = {
  items: {},
};

FFmpegCommandCardList.propTypes = {
  items: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(FFmpegCommandCardList));
