import {Partner} from "../../api";
import {MEDIA_FILE_LIST_FETCH_FAILURE, MEDIA_FILE_LIST_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import mediaFilesSchema from "../../schemas/media";

const SuccessAction = (payload) => ({
  type: MEDIA_FILE_LIST_FETCH_SUCCESS,
  payload: normalize(payload, [mediaFilesSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_FILE_LIST_FETCH_FAILURE,
  payload
});

export default (owner, opts) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.MediaList(owner, opts)
      .then(response => {
        dispatch(SuccessAction(response.items));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};