

import {ACCOUNT_CURRENT_SET_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: ACCOUNT_CURRENT_SET_SUCCESS,
  payload
});

export default (account) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(SuccessAction(account));
    resolve(resolve)
  })
};
