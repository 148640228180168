import React from "react"
import {connect} from "react-redux"
import {Session} from "../actions"
import {Redirect} from 'react-router-dom';
import {AccountPartialSignIn, Layout} from "./Auth"
import {getSessionToken} from "../selectors";
import {I18n} from "../utils/i18n";

class PageSignIn extends React.Component {

  handlerSessionCreate = (login, password) => {
    return this.props.dispatch(Session.Create(login, password))
      .then((res) => {
        this.props.dispatch(Session.Set((!!res && !!res.token) ? res.token : null))
      })
  };

  handleToRegistration = () => this.props.history.push(`/signup`);

  render() {

    if (this.props.token) return <Redirect to="/"/>;

    return (
      <section className="lb-auth-page container-fluid vh-100">
        <Layout actionBtnName={I18n.t('key_PageSignIn_Registration')} onAction={this.handleToRegistration}
                content={<AccountPartialSignIn submit={this.handlerSessionCreate}/>}/>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  token: getSessionToken(state)
});

export default connect(mapStateToProps)(PageSignIn)
