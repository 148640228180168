import {combineReducers} from 'redux';
import reducer from "../reducers";
import {i18nReducer} from '../utils/i18n';

const rootReducer = combineReducers({
  data: reducer,
  i18n: i18nReducer,
});

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer
      ? reducer(state, action.payload)
      : state;
  };
}

export default rootReducer
