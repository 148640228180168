import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom'
import {connect} from "react-redux";
import {getAccount, getSessionToken} from "./selectors";
import {Preloader} from "./components";
import {Account, Session} from "./actions"
import * as layout from "./layouts";
import {Header} from "./layouts";


class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentWillUpdate(nextProps) {
    if (nextProps.token && nextProps.token !== this.props.token) {
      this.fetchAccount()
    }
  }

  componentDidMount() {
    this.fetchAccount()
      .then(() => {
        this.setState({pending: false})
      });
  }

  fetchAccount = () => {
    return this.props.dispatch(Account.Fetch())
      .then((a) => {
        this.props.dispatch(Account.AccountCurrentSet(a));
      })
      .catch(() => {
        this.props.dispatch(Session.Remove());
      });
  };

  render() {

    if (!this.props.token) return <Redirect to="/signin"/>;
    if (this.state.pending) {
      return (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Preloader/>
        </div>
      );
    }

    return (
      <div>

        <Header/>

        <div className="content-wrapper">
          <Switch>

            <Route exact path={this.props.match.url} component={layout.PageDeviceList}/>

            {/*******************************************/}
            {/***************** account *****************/}
            {/*******************************************/}

            <Route exact path="/account" component={layout.PageAccountSettings}/>

            {/*******************************************/}
            {/****************** devices *****************/}
            {/*******************************************/}

            <Route exact path="/devices/new" component={layout.PageDeviceAddNew}/>
            <Route exact path="/devices" component={layout.PageDeviceList}/>
            <Route exact path="/d/:device" component={layout.PageDeviceInfo}/>

            {/*******************************************/}
            {/****************** partners ***************/}
            {/*******************************************/}

            <Route exact path="/partners/new" component={layout.PagePartnerCreate}/>
            <Route exact path="/partners" component={layout.PagePartnerList}/>
            <Route exact path="/p/:partner" component={layout.PagePartnerInfo}/>
            <Route exact path="/p/:partner/mp" component={layout.PagePartnerMpList}/>
            <Route exact path="/p/:partner/mp/:mp" component={layout.PagePartnerMpInfo}/>
            <Route exact path="/p/:partner/media" component={layout.PagePartnerMediaList}/>
            <Route exact path="/p/:partner/media/:media/modify" component={layout.PagePartnerMediaModify}/>
            <Route exact path="/p/:partner/media/:media" component={layout.PagePartnerMediaInfo}/>

            {/*******************************************/}
            {/****************** customers **************/}
            {/*******************************************/}

            <Route exact path="/customers/new" component={layout.PageCustomerCreate}/>
            <Route exact path="/customers" component={layout.PageCustomerList}/>
            <Route exact path="/c/:customer" component={layout.PageCustomerInfo}/>
            <Route exact path="/c/:customer/ad" component={layout.PageCustomerAdList}/>
            <Route exact path="/c/:customer/ad/:ad" component={layout.PageCustomerAdInfo}/>
            <Route exact path="/c/:customer/media" component={layout.PageCustomerMediaList}/>
            <Route exact path="/c/:customer/media/:media" component={layout.PageCustomerMediaInfo}/>
            <Route exact path="/c/:customer/media/:media/modify" component={layout.PageCustomerMediaModify}/>
            <Route exact path="/c/:customer/balance" component={layout.PageCustomerTransactionInfo}/>

            {/*******************************************/}
            {/***************** settings *****************/}
            {/*******************************************/}

            <Route exact path="/settings" component={layout.PageSettings}/>

            {/*******************************************/}
            {/***************** Redirect ****************/}
            {/*******************************************/}

            <Redirect from='*' to='/404'/>

          </Switch>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    token: getSessionToken(state)
  }
};

export default connect(mapStateToProps)(Auth);
