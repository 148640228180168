import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {Preloader} from "../components";
import {DevicePartialEvents, DevicePartialSchedule, DevicePartialSettings,} from "./Device";
import {Device} from "../actions";
import {getDeviceByID} from "../selectors";
import {I18n} from "../utils/i18n";

import 'antd/dist/antd.min.css';
import qs from "query-string";
import {serialize} from "../utils/helpers";

class PageDeviceInfo extends React.Component {
  constructor(props) {
    super(props);
    let query = qs.parse(this.props.history.location.search);

    this.state = {
      tab: query.tab || "info",
    };
  }

  componentDidMount() {
    const {device} = this.props.match.params;
    this.props.dispatch(Device.Fetch(device))
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/devices`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleSelectTab = (e, tab) => {
    e.preventDefault();
    let query = qs.parse(this.props.history.location.search);
    query.tab = tab;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
    this.setState({tab: tab})
  }

  render() {
    const {device} = this.props;

    if (this.state.pending || !device || !device.id) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    return (
      <section className="container content">

        <div className="d-flex bd-highlight">
          <div className="flex-grow-1">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
              <li className="breadcrumb-item"><NavLink to={"/devices"}>{I18n.t('key_Devices')}</NavLink></li>
              <li className="breadcrumb-item active">
                <span>{device.meta.serial}</span>
                <small>
                  <span
                    className={`mx-2 badge badge-sm text-uppercase ${!!device.status.online ? "badge-success" : "badge-danger"}`}>
                    {device.status.online ? I18n.t('key_Online') : I18n.t('key_Offline')}
                  </span>
                </small>
              </li>
            </ol>
          </div>
          <div>
            {
              (device.status.online) && (
                  <div className="d-flex">
                    <div className="p-1" data-toggle="tooltip" data-placement="right" title="Chromium">
                      <i className={`fab fa-chrome ${device.status.browser_active ? "text-success" : "text-muted"}`}/>
                    </div>
                    <div className="p-1" data-toggle="tooltip" data-placement="right" title="Websocket">
                      <i
                        className={`fas fa-retweet ${device.status.websocket_active ? "text-success" : "text-muted"}`}/>
                    </div>
                    <div className="p-1" data-toggle="tooltip" data-placement="right" title="TV online">
                      <i className={`fas fa-plug ${device.status.tv_online ? "text-success" : "text-muted"}`}/>
                    </div>
                    <div className="p-1" data-toggle="tooltip" data-placement="right" title="Screen on">
                      <i className={`fas fa-tv ${device.status.tv_screen_on ? "text-success" : "text-muted"}`}/>
                    </div>
                    <div className="p-1" data-toggle="tooltip" data-placement="right" title="HDMI active">
                      <i className={`far fa-dot-circle ${device.status.tv_hdmi_active ? "text-success" : "text-muted"}`}/>
                    </div>
                  </div>
                )
            }
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <div className="nav flex-column nav-pills">
              <NavLink to="#" className={`nav-link ${this.state.tab === "info" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "info")}>
                {I18n.t('key_PageDeviceInfo_TabInfo')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "schedule" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "schedule")}>
                {I18n.t('key_PageDeviceInfo_TabSchedule')}
              </NavLink>
              <NavLink to="#" className={`nav-link ${this.state.tab === "settings" ? "active" : ""}`}
                       onClick={e => this.handleSelectTab(e, "settings")}>
                {I18n.t('key_PageDeviceInfo_TabSettings')}
              </NavLink>
            </div>
          </div>

          <div className="col-9">
            {this.state.tab === "info" && <DevicePartialEvents/>}
            {this.state.tab === "schedule" && <DevicePartialSchedule/>}
            {this.state.tab === "settings" && <DevicePartialSettings/>}
          </div>

        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    device: getDeviceByID(state, props.match.params.device),
  }
};

export default withRouter(connect(mapStateToProps)(PageDeviceInfo));

