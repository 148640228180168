import React from 'react';
import {Tree} from 'antd';
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";

const generateList = data => {
  let dataList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    dataList.push({key: node.key, title: node.title});
    if (node.children) {
      dataList = dataList.concat(generateList(node.children));
    }
  }
  return dataList
};


const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

class TagTreeCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: (!!props.data.length) ? [props.data[0].key] : [],
      searchValue: '',
      autoExpandParent: true,
      setAutoExpandParent: true,
      keys: props.selected || [],
      data: props.data || [],
    };

  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onChange = e => {
    const {value} = e.target;
    const expandedKeys = generateList(this.state.data)
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, this.state.data);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };

  onSelect = (value) => {
    this.setState({keys: value});
    this.handleEvent(value)
  };

  handleEvent = (value) => {
    let event = new Event(this.props.name);
    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: this.props.name, value: value},
    });
    this.props.onChange(event)
  }

  render() {
    const {searchValue, expandedKeys, autoExpandParent, setAutoExpandParent, keys} = this.state;
    const loop = data =>
      data.map(item => {
        const index = item.title.indexOf(searchValue);
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          );
        if (item.children) {
          return {
            title: title,
            checkable: item.checkable,
            selectable: item.selectable,
            key: item.key,
            children: loop(item.children),
          };
        }

        return {
          title,
          key: item.key,
          checkable: item.checkable,
          selectable: item.selectable,
        };
      });


    return (
      <div>
        <div className="form-group mb-2">
          <input type="text"
                 className="form-control"
                 id="searchInput"
                 placeholder={I18n.t('key_Search')}
                 name="search"
                 onChange={this.onChange}
                 value={this.state.searchValue}/>
        </div>

        <div>
          <Tree
            multiple
            checkable
            onExpand={this.onExpand}
            onCheck={this.onSelect}
            onSelect={this.onSelect}
            expandedKeys={expandedKeys}
            setAutoExpandParent={setAutoExpandParent}
            autoExpandParent={autoExpandParent}
            selectedKeys={keys}
            checkedKeys={keys}
            treeData={loop(this.state.data)}
          />
        </div>
      </div>
    );
  }
}

TagTreeCard.defaultProps = {
  data: [],
  selected: [],
};


TagTreeCard.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
};

export default TagTreeCard
