import React from "react";
import {connect} from "react-redux";

class ImageViewer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>=)))</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps)(ImageViewer);

