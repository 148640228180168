import {Partner} from "../../api";
import {MEDIA_PLAN_FETCH_FAILURE, MEDIA_PLAN_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/mediaplan";

const SuccessAction = (payload) => ({
  type: MEDIA_PLAN_FETCH_SUCCESS,
  payload: normalize([payload], [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_PLAN_FETCH_FAILURE,
  payload
});

export default (owner, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Partner.MpFetch(owner, id)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};