import BaseComponent from './base';

export default {
  _localeKey: 'en',
  _translationsObject: {},
  _getTranslations: null,
  _getLocale: null,
  _handleMissingTranslation: text => text.split('.').pop(),

  get _translations() {
    return this._getTranslations ? this._getTranslations() : this._translationsObject;
  },

  set _translations(translations) {
    this._translationsObject = translations;
  },

  get _locale() {
    return this._getLocale ? this._getLocale() : this._localeKey;
  },

  set _locale(locale) {
    this._localeKey = locale;
  },

  setLocale(locale, rerenderComponents = true) {
    this._locale = locale;
    if (rerenderComponents) {
      this.forceComponentsUpdate();
    }
  },

  setTranslations(translations, rerenderComponents = true) {
    this._translations = translations;
    if (rerenderComponents) {
      this.forceComponentsUpdate();
    }
  },

  setTranslationsGetter(fn) {
    if (typeof fn !== 'function') {
      throw new Error('Translations getter must be a function');
    }
    this._getTranslations = fn;
  },

  setLocaleGetter(fn) {
    if (typeof fn !== 'function') {
      throw new Error('Locale getter must be a function');
    }
    this._getLocale = fn;
  },

  t(key, replacements = {}) {
    return this._translate(key, replacements);
  },

  l(value, options) {
    return this._localize(value, options);
  },

  _replace(translation, replacements) {
    if (typeof translation === 'string') {
      let result = translation;
      Object.keys(replacements).forEach((replacement) => {
        result = result.split(`%{${replacement}}`).join(replacements[replacement]);
      });
      return result;
    }
    if (typeof translation === 'object') {
      const result = {};
      Object.keys(translation).forEach((translationKey) => {
        result[translationKey] = this._replace(translation[translationKey], replacements);
      });
      return result;
    }
    return null;
  },

  _translate(key, replacements = {}) {
    let translation = '';
    try {
      const translationLocale = this._translations[this._locale] ?
        this._locale :
        this._locale.split('-')[0];
      translation = this._fetchTranslation(
        this._translations,
        `${translationLocale}.${key}`,
        replacements.count,
      );
    } catch (err) {
      return this._handleMissingTranslation(key, replacements);
    }
    return this._replace(translation, replacements);
  },

  _localize(value, options = {}) {
    if (options.dateFormat) {
      // TODO: make date formatting string
      // return <formatting date string>
    }
    if (typeof value === 'number') {
      return new Intl.NumberFormat(this._locale, options).format(value);
    }
    return value;
  },

  _fetchTranslation(translations, key, count = null) {
    const _index = key.indexOf('.');

    if (typeof translations === 'undefined') {
      throw new Error('not found');
    }

    if (_index > -1) {
      return this._fetchTranslation(
        translations[key.substring(0, _index)],
        key.substr(_index + 1),
        count,
      );
    }

    if (count !== null) {
      if (translations[`${key}_${count}`]) return translations[`${key}_${count}`];
      if (count !== 1 && translations[`${key}_plural`]) return translations[`${key}_plural`];
    }

    if (translations[key]) return translations[key];

    throw new Error('not found');
  },

  forceComponentsUpdate() {
    BaseComponent.rerenderAll();
  },
};