import {Device} from '../../api';

import {DEVICE_REMOVE_FAILURE, DEVICE_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: DEVICE_REMOVE_SUCCESS,
  payload
});

const FailureAction = (payload) => ({
  type: DEVICE_REMOVE_FAILURE,
  payload
});

export default (device) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Device.Remove(device.id)
      .then(() => {
        dispatch(SuccessAction(device));
        resolve(device)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};