import React from "react";
import {connect} from "react-redux";
import {Preloader} from "../components";
import {PartnerMpCardInfo} from "../containers";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {Partner} from "../actions";
import {getMediaPlanByID, getPartnerByID} from "../selectors";

class PagePartnerMpInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(Partner.Fetch(this.props.match.params.partner)),
      this.props.dispatch(Partner.MpFetch(this.props.match.params.partner, this.props.match.params.mp))
    ])
      .then(() => this.setState({pending: false}))
      .catch((e) => {
        if (e.code === 404) return this.props.history.push(`/p/${this.props.match.params.partner}/mp`);
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleUpdate = (data) => {
    return this.props.dispatch(Partner.AdUpdate(this.props.match.params.partner, this.props.match.params.mp, data))
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {partner, media_plans} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item"><NavLink to={"/partners"}>{I18n.t('key_Partners')}</NavLink></li>
                <li className="breadcrumb-item">
                  <NavLink to={`/p/${partner.username}`}>{partner.email}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/p/${partner.username}/mp`}>{I18n.t('key_MediaPlans')}</NavLink>
                </li>
                <li className="breadcrumb-item active">{media_plans.meta.name}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-4">
            <PartnerMpCardInfo item={media_plans} partner={partner}/>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    partner: getPartnerByID(state, props.match.params.partner),
    media_plans: getMediaPlanByID(state, props.match.params.mp),
  }
};

export default connect(mapStateToProps)(PagePartnerMpInfo);

