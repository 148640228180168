import {Vpn} from "../../api";
import {VPN_SERVER_LIST_FETCH_FAILURE, VPN_SERVER_LIST_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import vpnServerSchema from "../../schemas/vpn_server";


const SuccessAction = (payload) => ({
  type: VPN_SERVER_LIST_FETCH_SUCCESS,
  payload: normalize(payload, [vpnServerSchema])
});

const FailureAction = (payload) => ({
  type: VPN_SERVER_LIST_FETCH_FAILURE,
  payload
});

export default () => (dispatch) => {
  return new Promise((resolve, reject) => {
    Vpn.ServerList()
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};