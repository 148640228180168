import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Auth from "./auth";

import "./theme/style.css";

import * as layouts from "./layouts";

const App = () => {
  return (
    <main>
      <Switch>
        <Route exact path='/404' component={layouts.PageNotFound}/>
        <Route exact path="/signin" component={layouts.PageSignIn}/>
        {/*<Route exact path="/signup" component={layouts.PageSignUp}/>*/}
        <Route exact path="/password/recovery" component={layouts.PageRecovery}/>
        <Route exact path="/password/recovery/:token" component={layouts.PageRecovery}/>
        <Route path='/' component={Auth}/>

        <Redirect from='*' to='/404'/>
      </Switch>
    </main>
  )
};

export default App;
