import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import moment from "moment";
import {Resource} from "../utils/helpers";
import {NavLink} from "react-router-dom";
import {ModalDialog} from "../containers";

const dateFormat = 'DD-MM-YYYY HH:mm';

const CustomerMediaCardList = ({items, customer, onRemove}) => {
  return (
    <table className="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "120px",
          width: "120px",
        }}>{I18n.t('key_CustomerMediaCardList_Status')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "120px",
          width: "120px",
        }}>{I18n.t('key_CustomerMediaCardList_State')}</th>
        <th scope="col" style={{
          textAlign: "center",
          minWidth: "150px"
        }}>{I18n.t('key_CustomerMediaCardList_Name')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "100px",
          width: "100px",
        }}>{I18n.t('key_CustomerMediaCardList_Size')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "100px",
          width: "100px",
        }}>{I18n.t('key_CustomerMediaCardList_Created')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "10px",
          width: "10px",
        }}/>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "100px",
          width: "100px",
        }}>{I18n.t('key_CustomerMediaCardList_Action')}</th>
      </tr>
      </thead>
      <tbody>
      {
        items.map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="align-middle" align="center" valign="middle">
                {(item.status.state === "error") &&
                  <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaError')}</span>}
                {(item.status.state === "ready") &&
                  <span style={{width: "70px"}} className="badge badge-success">{I18n.t('key_StatusMediaReady')}</span>}
                {(item.status.state === "uploading") &&
                  <span style={{width: "70px"}}
                        className="badge badge-primary">{I18n.t('key_StatusMediaUploading')}</span>}
                {(item.status.state === "preparing") &&
                  <span style={{width: "70px"}}
                        className="badge badge-primary">{I18n.t('key_StatusMediaPreparing')}</span>}
                {(item.status.state === "deleting") &&
                  <span style={{width: "70px"}}
                        className="badge badge-danger">{I18n.t('key_StatusMediaDeleting')}</span>}
                {(item.status.state === "unknown") &&
                  <span style={{width: "70px"}}
                        className="badge badge-warning">{I18n.t('key_StatusMediaUnknown')}</span>}
              </td>
              <td className="align-middle" align="center" valign="middle">
                {(item.status.status === "created") &&
                  <span style={{width: "70px"}}
                        className="badge badge-primary">{I18n.t('key_StatusMediaCreated')}</span>}
                {(item.status.status === "accepted") &&
                  <span style={{width: "70px"}}
                        className="badge badge-warning">{I18n.t('key_StatusMediaAccepted')}</span>}
                {(item.status.status === "rejected") &&
                  <span style={{width: "70px"}}
                        className="badge badge-danger">{I18n.t('key_StatusMediaRejected')}</span>}
              </td>
              <td className="align-middle" align="left" valign="middle">
                <NavLink to={`/c/${customer.username}/media/${item.meta.id}`}>{item.meta.name}</NavLink>
              </td>
              <td className="align-middle" align="left"
                  valign="middle">{Resource.MemoryBytesToHumanSize(item.meta.size, 2)}</td>
              <td className="align-middle" align="center" valign="middle">{moment(item.created).format(dateFormat)}</td>
              <td className="align-middle" align="center" valign="middle">
                {item.status.state === "ready" && (
                  <NavLink to={`/c/${customer.username}/media/${item.meta.id}/modify`}>
                    <i className="fa fa-edit"/>
                  </NavLink>
                )}
              </td>
              <td className="align-middle" align="left" valign="middle">
                <ModalDialog name={`remove${index}`}
                             header={<h3>{I18n.t('key_CustomerMediaCardList_ModalRemoveMedia')}</h3>}
                             btnOpen={<button className="btn btn-danger btn-sm"
                                              data-toggle="modal" data-target={`#remove${index}`}
                                              disabled={item.status.state === "deleting"}>
                               {I18n.t('key_Remove')}
                             </button>}
                             btnOpenName={I18n.t('key_Remove')}
                             btnOkClassName="btn btn-danger"
                             btnCloseName={I18n.t('key_Close')}
                             btnCloseClassName="btn btn-primary"
                             btnOkName={I18n.t('key_Remove')}
                             onOk={() => onRemove(item)}
                             content={
                               <div>{I18n.t('key_CustomerMediaCardList_ModalRemoveMediaApprove', {name: item.meta.name})}</div>}
                />
              </td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

CustomerMediaCardList.defaultProps = {
  items: [],
};

CustomerMediaCardList.propTypes = {
  customer: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  items: PropTypes.array,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(CustomerMediaCardList);
