import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../../utils/i18n";
import {capitalizeFirstLetter} from "../../utils/helpers";
import {Button} from "../index";

const defaultTime = "5";

class SystemTriggerFormUpdate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rules: props.data,
      error: {
        form: ""
      },
      pending: false,
    };
  }

  handleNewRule = (e) => {
    let rules = this.state.rules || [];
    rules.push({data: {}, conditions: []})
    this.setState({rules: rules, error: {form: ""}})
  }

  handleCloneRule(e, index) {
    let rules = this.state.rules;
    const rule = JSON.parse(JSON.stringify(rules[index]));
    delete rule.id;
    rules.push(rule);
    this.setState({rules: rules, error: {form: ""}});
  }

  handleDisableRule(e, index) {
    let rules = this.state.rules;
    rules[index].disabled = true;
    this.setState({rules: rules, error: {form: ""}});
  }

  handleEnableRule(e, index) {
    let rules = this.state.rules;
    rules[index].disabled = false;
    this.setState({rules: rules, error: {form: ""}});
  }

  handleRemoveRule(e, index) {
    let rules = this.state.rules;
    rules.splice(index, 1);
    this.setState({rules: rules, error: {form: ""}});
  }

  handleChangeName(e, index) {
    let rules = this.state.rules;
    rules[index].name = e.target.value;
    this.setState({rules: rules, error: {form: ""}});
  }

  handleChangeAction(e, index, action) {
    let rules = this.state.rules;
    rules[index].action = action;
    rules[index].entity = "";
    rules[index].data = {};
    rules[index].conditions = [{operation: "" || "", value: defaultTime}];
    this.setState({rules: rules, error: {form: ""}});
  }

  handleChangeEntity(e, index, entity) {
    let rules = this.state.rules;
    rules[index].entity = entity;
    rules[index].data = {};
    this.setState({rules: rules, error: {form: ""}});
  }

  handleChangeData(e, index, action) {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (action === "notify" && name === "email") {
      let rules = this.state.rules;
      rules[index].data = rules[index].data || {};
      rules[index].data.email = value;
      this.setState({rules: rules, error: {form: ""}});
    }
  }

  handleNewCondition(e, index, operation) {
    let rules = this.state.rules;
    rules[index].conditions = (rules[index].conditions || []);
    rules[index].conditions.push({operation: operation || "", value: defaultTime})
    this.setState({rules: rules, error: {form: ""}});
  }

  handleChangeEventCondition = (e, r_index, c_index, condition) => {
    let rules = this.state.rules;
    rules[r_index].conditions[c_index].condition = condition;
    this.setState({rules: rules, error: {form: ""}});
  };

  handleChangeEvent = (e, r_index, c_index, event) => {
    let rules = this.state.rules;
    rules[r_index].conditions[c_index].event = event;
    rules[r_index].conditions[c_index].status = "";
    rules[r_index].conditions[c_index].condition = "";
    rules[r_index].conditions[c_index].value = defaultTime;
    this.setState({rules: rules, error: {form: ""}});
  };

  handleChangeStatusCondition = (e, r_index, c_index, status) => {
    let rules = this.state.rules;
    rules[r_index].conditions[c_index].status = status;
    this.setState({rules: rules, error: {form: ""}});
  };

  handleChangeEventConditionValue = (e, r_index, c_index) => {
    let rules = this.state.rules.slice();
    rules[r_index].conditions[c_index].value = e.target.value;
    this.setState({rules: rules, error: {form: ""}});
  };

  handleRemoveCondition = (e, r_index, c_index) => {
    let rules = this.state.rules;
    rules[r_index].conditions.splice(c_index, 1);
    this.setState({rules: rules, error: {form: ""}});
  };

  handleChangeOperation = (e, r_index, c_index, operation) => {
    let rules = this.state.rules;
    rules[r_index].conditions[c_index].operation = operation;
    this.setState({rules: rules, error: {form: ""}});
  }

  prepareConditions = (conditions) => {
    let result = [];
    if (!conditions) return result;
    if (conditions.length === 0) return result;
    conditions[0].operation = '';
    return conditions
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = JSON.parse(JSON.stringify(this.state.rules));
    let errors = {};

    for (let key in errors) {
      if (errors[key] !== null && errors[key] !== "") {
        this.setState({error: Object.assign(this.state.error, errors), pending: false});
        return
      }
    }

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = () => {
    this.setState({pending: false, data: {name: ""}});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad name parameter") e.form = I18n.t('key_errorMsg_BadTriggerNameParameter');
    if (errors.message === "Bad action parameter") e.form = I18n.t('key_errorMsg_BadTriggerActionParameter');
    if (errors.message === "Bad entity parameter") e.form = I18n.t('key_errorMsg_BadTriggerEntityParameter');
    if (errors.message === "Bad data.email parameter") e.form = I18n.t('key_errorMsg_BadTriggerValueEmailParameter');
    if (errors.message === "Bad conditions.length parameter") e.form = I18n.t('key_errorMsg_BadTriggerConditionsLengthParameter');
    if (errors.message === "Bad conditions.status parameter") e.form = I18n.t('key_errorMsg_BadTriggerConditionsStatusParameter');
    if (errors.message === "Bad conditions.event parameter") e.form = I18n.t('key_errorMsg_BadTriggerConditionsEventParameter');
    if (errors.message === "Bad conditions.value parameter") e.form = I18n.t('key_errorMsg_BadTriggerConditionsValueParameter');
    if (errors.message === "Bad conditions.condition parameter") e.form = I18n.t('key_errorMsg_BadTriggerConditionsConditionParameter');
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
    )
  };

  renderStatusCondition = (r_index, c_index, condition) => {
    return (
      <div className="input-group-prepend">
        <button className="btn btn-sm btn-outline-secondary dropdown-toggle"
                style={{width: "110px"}}
                type="button"
                data-toggle="dropdown">
          {
            (!condition.status)
              ? I18n.t("key_SystemTriggerFormUpdate_Status")
              : I18n.t(`key_SystemTriggerFormUpdate_Status${capitalizeFirstLetter(condition.status)}`)
          }
        </button>
        {
          (condition.event === "device") ? (
            <div className="dropdown-menu">
              <div className="dropdown-item cursor-pointer"
                   onClick={e => this.handleChangeStatusCondition(e, r_index, c_index, "online")}>
                {I18n.t(`key_SystemTriggerFormUpdate_StatusOnline`)}
              </div>
              <div className="dropdown-item cursor-pointer"
                   onClick={e => this.handleChangeStatusCondition(e, r_index, c_index, "offline")}>
                {I18n.t(`key_SystemTriggerFormUpdate_StatusOffline`)}
              </div>
            </div>
          ) : (
            <div className="dropdown-menu">
              <div className="dropdown-item cursor-pointer"
                   onClick={e => this.handleChangeStatusCondition(e, r_index, c_index, "active")}>
                {I18n.t(`key_SystemTriggerFormUpdate_StatusActive`)}
              </div>
              <div className="dropdown-item cursor-pointer"
                   onClick={e => this.handleChangeStatusCondition(e, r_index, c_index, "inactive")}>
                {I18n.t(`key_SystemTriggerFormUpdate_StatusInactive`)}
              </div>
            </div>
          )
        }
      </div>
    )
  }

  renderCondition = (action, r_index, c_index, condition) => {
    return (
      <div className="input-group input-group-sm mb-2">
        <div className="input-group-prepend">
          {
            !condition.operation
              ? (
                <span className="input-group-text text-right"
                      style={{width: "60px", display: "block"}}
                      id="basic-addon2">
                    {I18n.t(`key_SystemTriggerFormUpdate_Operator${capitalizeFirstLetter(condition.operation)}`)}
                </span>
              )
              : (
                <div className="dropdown">
                  <button className="btn btn-sm btn-outline-secondary dropdown-toggle text-right" type="button"
                          style={{height: "31px", width: "60px", backgroundColor: "#e9ecef", color: "#495057"}}
                          data-toggle="dropdown">
                    <span>{I18n.t(`key_SystemTriggerFormUpdate_Operator${capitalizeFirstLetter(condition.operation)}`)}</span>
                  </button>
                  <div className="dropdown-menu">
                    <div className="dropdown-item cursor-pointer"
                         onClick={e => this.handleChangeOperation(e, r_index, c_index, "and")}>
                      {I18n.t(`key_SystemTriggerFormUpdate_OperatorAnd`)}
                    </div>
                    <div className="dropdown-item cursor-pointer"
                         onClick={e => this.handleChangeOperation(e, r_index, c_index, "or")}>
                      {I18n.t(`key_SystemTriggerFormUpdate_OperatorOr`)}
                    </div>
                  </div>
                </div>
              )
          }
        </div>
        <div className="input-group-prepend">
          <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                  style={{width: "110px"}}
                  data-toggle="dropdown">
            {I18n.t(`key_SystemTriggerFormUpdate_Event${capitalizeFirstLetter(condition.event)}`)}
          </button>
          {
            action === 'notify' && (
              <div className="dropdown-menu">
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "browser")}>{I18n.t(`key_SystemTriggerFormUpdate_EventBrowser`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "tv")}>{I18n.t(`key_SystemTriggerFormUpdate_EventTv`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "hdmi")}>{I18n.t(`key_SystemTriggerFormUpdate_EventHdmi`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "websocket")}>{I18n.t(`key_SystemTriggerFormUpdate_EventWebsocket`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "screen")}>{I18n.t(`key_SystemTriggerFormUpdate_EventScreen`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "device")}>{I18n.t(`key_SystemTriggerFormUpdate_EventDevice`)}</div>
              </div>
            )
          }
          {
            action === "restart" && (
              <div className="dropdown-menu">
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "browser")}>{I18n.t(`key_SystemTriggerFormUpdate_EventBrowser`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "tv")}>{I18n.t(`key_SystemTriggerFormUpdate_EventTv`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "hdmi")}>{I18n.t(`key_SystemTriggerFormUpdate_EventHdmi`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "screen")}>{I18n.t(`key_SystemTriggerFormUpdate_EventScreen`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "websocket")}>{I18n.t(`key_SystemTriggerFormUpdate_EventWebsocket`)}</div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEvent(e, r_index, c_index, "modem")}>{I18n.t(`key_SystemTriggerFormUpdate_EventModem`)}</div>
              </div>
            )
          }
        </div>
        {(!!condition.event) && this.renderStatusCondition(r_index, c_index, condition)}
        {
          (!!condition.status) && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon2">
                {I18n.t(`key_SystemTriggerFormUpdate_ConditionIf`)}
              </span>
            </div>
          )
        }
        {
          (!!condition.status) && (
            <div className="input-group-prepend">
              <button className="btn btn-sm btn-outline-secondary dropdown-toggle"
                      style={{width: "80px"}}
                      type="button"
                      data-toggle="dropdown">
                {
                  (!condition.condition)
                    ? I18n.t("key_SystemTriggerFormUpdate_Condition")
                    : I18n.t(`key_SystemTriggerFormUpdate_Condition${condition.condition.toUpperCase()}`)
                }
              </button>
              <div className="dropdown-menu">
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEventCondition(e, r_index, c_index, "gt")}>{I18n.t(`key_SystemTriggerFormUpdate_ConditionGT`)}</div>
                {/*<div className="dropdown-item cursor-pointer"*/}
                {/*     onClick={e => this.handleChangeEventCondition(e, r_index, c_index, "eq")}>{I18n.t(`key_SystemTriggerFormUpdate_ConditionEQ`)}</div>*/}
                {/*<div className="dropdown-item cursor-pointer"*/}
                {/*     onClick={e => this.handleChangeEventCondition(e, r_index, c_index, "lt")}>{I18n.t(`key_SystemTriggerFormUpdate_ConditionLT`)}</div>*/}
              </div>
            </div>
          )
        }
        {
          (!!condition.status) && (
            <div className="input-group-prepend">
              <input className="form-control form-control-sm"
                     name="value" type="number"
                     value={condition.value || ""}
                     onChange={e => this.handleChangeEventConditionValue(e, r_index, c_index)}
                     placeholder={I18n.t(`key_SystemTriggerFormUpdate_Value`)}/>
            </div>
          )
        }
        {
          (!!condition.status) && (
            <div className="input-group-prepend">
              <span className="input-group-text"
                    id="basic-addon2">{I18n.t(`key_SystemTriggerFormUpdate_TimeInMinute`)}</span>
            </div>
          )
        }
        <div className="input-group-prepend">
          <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                  data-toggle="dropdown">
            <i className="fas fa-ellipsis-h"/>
          </button>
          <div className="dropdown-menu">
            <div className="dropdown-item cursor-pointer text-danger"
                 onClick={e => this.handleRemoveCondition(e, r_index, c_index)}>{I18n.t(`key_Remove`)}</div>
          </div>
        </div>
      </div>
    )
  }

  renderCommand = (index, item) => {
    return (
      <div className="input-group-prepend">
        {
          (item.action === "notify") && (
            <div className="input-group-prepend">
              <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                      data-toggle="dropdown">
                {
                  (!item.entity)
                    ? I18n.t("key_SystemTriggerFormUpdate_Provider")
                    : I18n.t(`key_SystemTriggerFormUpdate_ProvideTo${capitalizeFirstLetter(item.entity)}`)
                }
              </button>
              <div className="dropdown-menu">
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEntity(e, index, "mail")}>
                  {I18n.t(`key_SystemTriggerFormUpdate_ProvideToMail`)}
                </div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEntity(e, index, "telegram")}>
                  {I18n.t(`key_SystemTriggerFormUpdate_ProvideToTelegram`)}
                </div>
              </div>
            </div>
          )
        }
        {
          (item.action === "restart") && (
            <div className="input-group-prepend">
              <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                      data-toggle="dropdown">
                {
                  (!item.entity)
                    ? I18n.t("key_SystemTriggerFormUpdate_Entity")
                    : I18n.t(`key_SystemTriggerFormUpdate_Entity${capitalizeFirstLetter(item.entity)}`)
                }
              </button>
              <div className="dropdown-menu">
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEntity(e, index, "browser")}>
                  {I18n.t(`key_SystemTriggerFormUpdate_EntityBrowser`)}
                </div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEntity(e, index, "modem")}>
                  {I18n.t(`key_SystemTriggerFormUpdate_EntityModem`)}
                </div>
                <div className="dropdown-item cursor-pointer"
                     onClick={e => this.handleChangeEntity(e, index, "device")}>
                  {I18n.t(`key_SystemTriggerFormUpdate_EntityDevice`)}
                </div>
              </div>
            </div>
          )
        }
        {
          (item.action === "notify") && (item.entity === "mail") && (
            <div className="input-group-prepend" style={{width: "250px"}}>
              <input className="form-control form-control-sm"
                     name="email" type="email"
                     value={item.data.email || ""}
                     onChange={e => this.handleChangeData(e, index, item.action)}
                     placeholder={I18n.t(`key_SystemTriggerFormUpdate_NotifyEmail`)}/>
            </div>
          )
        }
      </div>
    )
  }

  render() {
    return (
      <div>
        <div>
          <ol>
            {
              this.state.rules.map((rule, r_index) => {
                return (
                  <li key={r_index} className="mb-3">
                    <div className="ml-2">
                      <div className="input-group input-group-sm mb-3" style={{width: "500px"}}>
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className={`fa fa-circle ${(!rule.disabled) ? "text-success" : "text-secondary"}`}/>
                          </div>
                        </div>
                        <input type="text" className="form-control"
                               name="name"
                               value={rule.name || ""}
                               onChange={e => this.handleChangeName(e, r_index)}
                               placeholder={I18n.t(`key_SystemTriggerFormUpdate_TriggerName`)}/>
                        <div className="input-group-prepend">
                          <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                                  data-toggle="dropdown">
                            <i className="fas fa-ellipsis-h"/>
                          </button>
                          <div className="dropdown-menu">
                            <div className="dropdown-item cursor-pointer"
                                 onClick={e => this.handleCloneRule(e, r_index)}>{I18n.t(`key_Duplicate`)}</div>
                            {
                              (rule.disabled)
                                ? <div className="dropdown-item cursor-pointer"
                                       onClick={e => this.handleEnableRule(e, r_index)}>{I18n.t(`key_Enable`)}</div>
                                : <div className="dropdown-item cursor-pointer"
                                       onClick={e => this.handleDisableRule(e, r_index)}>{I18n.t(`key_Disable`)}</div>
                            }
                            <div className="dropdown-divider"/>
                            <div className="dropdown-item cursor-pointer text-danger"
                                 onClick={e => this.handleRemoveRule(e, r_index)}>{I18n.t(`key_Remove`)}</div>
                          </div>
                        </div>
                      </div>

                      <div className="input-group input-group-sm mb-2">
                        <div className="input-group-prepend">
                          <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                                  data-toggle="dropdown">
                            {
                              (!rule.action)
                                ? I18n.t("key_SystemTriggerFormUpdate_Action")
                                : I18n.t(`key_SystemTriggerFormUpdate_Action${capitalizeFirstLetter(rule.action)}`)
                            }
                          </button>
                          <div className="dropdown-menu">
                            <div className="dropdown-item cursor-pointer"
                                 onClick={e => this.handleChangeAction(e, r_index, "notify")}>{I18n.t(`key_Notify`)}</div>
                            <div className="dropdown-item cursor-pointer"
                                 onClick={e => this.handleChangeAction(e, r_index, "restart")}>{I18n.t(`key_Restart`)}</div>
                          </div>
                        </div>
                        {this.renderCommand(r_index, rule)}
                      </div>

                      {
                        (!!rule.entity) && (
                          <div className="d-flex ml-2">
                            <div className="pr-2">
                              <small>{I18n.t(`key_SystemTriggerFormUpdate_Condition`)}:</small>
                            </div>
                            <div>
                              {
                                (!!rule.conditions.length) && this.prepareConditions(rule.conditions).map((condition, c_index) => {
                                  return (
                                    <div key={c_index}>
                                      {this.renderCondition(rule.action, r_index, c_index, condition)}
                                    </div>
                                  )
                                })
                              }
                              <div>
                                {
                                  (!rule.conditions.length)
                                    ? (
                                      <button className="btn btn-sm btn-outline-secondary" type="button"
                                              onClick={e => this.handleNewCondition(e, r_index)}>
                                        {I18n.t(`key_SystemTriggerFormUpdate_AddCondition`)}
                                      </button>
                                    )
                                    : (
                                      <div className="dropdown">
                                        <button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown">
                                          Добавить условие
                                        </button>
                                        <div className="dropdown-menu">
                                          <div className="dropdown-item cursor-pointer"
                                               onClick={e => this.handleNewCondition(e, r_index, "and")}>
                                            {I18n.t(`key_SystemTriggerFormUpdate_OperatorAnd`)}
                                          </div>
                                          <div className="dropdown-item cursor-pointer"
                                               onClick={e => this.handleNewCondition(e, r_index, "or")}>
                                            {I18n.t(`key_SystemTriggerFormUpdate_OperatorOr`)}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        ((r_index + 1) !== this.state.rules.length) && (
                          <hr/>
                        )
                      }
                    </div>
                  </li>
                )
              })
            }
          </ol>

          <div className={this.state.rules.length ? "mt-4" : ""}>
            <button className="btn btn-sm btn-outline-secondary" type="button"
                    onClick={this.handleNewRule}>
              {I18n.t(`key_SystemTriggerFormUpdate_AddRule`)}
            </button>
          </div>
        </div>
        <div className="mt-5 d-flex">
          <div>
            <Button type='submit'
                    className='btn btn-primary'
                    disabled={this.handleDisabled()}
                    submit={this.handleSubmit}
                    main={I18n.t('key_Apply')}
            />
          </div>
          <div>{this.state.error.form &&
          <h4 className="text-danger px-2 pt-2 align-middle">{this.state.error.form}</h4>}</div>
        </div>
      </div>
    )
  }
}

SystemTriggerFormUpdate
  .propTypes = {
  name: PropTypes.string,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default SystemTriggerFormUpdate;
