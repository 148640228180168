import {schema} from 'normalizr';

const vpnServerSchema = new schema.Entity('vpn_servers', {}, {
  idAttribute: data => data.id,
  processStrategy: (data) => {
    data.updated = new Date(data.updated * 1000);
    data.created = new Date(data.created * 1000);
    return data;
  }
});

export default vpnServerSchema;
